import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Dropdown } from "react-bootstrap";
import EvalHistModal from "../non_ex_emp_evaluation/modals/EvalHistModal";

function Special_evaluation({
  serviceNo,
  selectedYear,
  selectedPeriod,
  specialEvalData,
  loggedUserSN,
  onDataUpdate,
}) {
  const loggedUserName = localStorage.getItem("logged_user_name");

  const [evalData, setEvalData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal); // Toggle modal visibility
  };

  const [selectedHistoryStatus, setSelectedHistoryStatus] = useState(null);
  const [isDeptalHead, setIsDepHead] = useState(null);
  const [isDivHead, setIsDivHead] = useState(null);
  const [prevFormValuesNonEx, setPrevFormValuesNonEx] = useState();


  const [isNoSelected, setIsNoSelected] = useState(false);

  const [specialFormValues, setSpecialFormValues] = useState({
    specAddSalary: "",
    numSpecIncre: "",
    justific: "",
    gcw: "",
    costSav: "",
    is: "",
    innov: "",
    oc: "",
    uf: "",
    cusSatis: "",
    other: "",
    justiComment: "",

    recBy: "",

    recDepHead: "",
    recDepHeadCom: "",

    approvDivHead: "",

    // specIncrRecDpH: "",
    // specIncrApprovDvH: "",
    // specIncrRecDpHSN: "",
    // specIncrApprovDvHSN: "",

    
    approvDivHeadCom: "",
  });

  useEffect(() => {
    axios
      .get(
        `Evaluation/GetSpecialEvaluationDetails?serviceNo=${serviceNo}&year=${selectedYear}&UserType=NonEx&periodType=${selectedPeriod}&historyStatus=`
      )
      .then((response) => {
        const data = response.data.ResultSet;
  
        setSpecialFormValues({
          justific: data.Achievements,
          otherComment: data.AchievementsRemarks,
          recBy: data.CommentsBy,
          recDepHeadCom: data.RecommenedBy,
          approvDivHead: data.Adh_sia_check ? "Y" : data.Adh_sina_check ? "N" : "",
          approvDivHeadCom: data.ApprovedBy,
          recDepHead : data.Rdh_sir_check ? "Y" : data.Rdh_sinr_check ? "N" : "",

          specAddSalary: data.Sa_si_y_check ? "Y" : "N",
          numSpecIncre: data.Sa_ni_1_check ? "1" : data.Sa_ni_2_check ? "2" : "",
          gcw: data.Yjmfu_gctw_check ? "Y" : "N",
          costSav: data.Yjmfu_cs_check ? "Y" : "N",
          is: data.Yjmfu_is_check ? "Y" : "N",
          innov: data.Yjmfu_i_check ? "Y" : "N",
          oc: data.Yjmfu_oc_check ? "Y" : "N",
          uf: data.Yjmfu_uf_check ? "Y" : "N",
          cusSatis: data.Yjmfu_csn_check ? "Y" : "N",
          other: data.Yjmfu_o_check ? "Y" : "N",
          specIncrRecDpH: data.Rdh_sir_check ? "Y" : data.Rdh_sinr_check ? "N" : "",
          specIncrApprovDvH: data.Adh_sia_check ? "Y" : data.Adh_sina_check ? "N" : "",
        });
        
        
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [serviceNo, selectedYear, selectedPeriod]);

  useEffect(() => {
    if (JSON.stringify(specialFormValues) !== JSON.stringify(prevFormValuesNonEx)) {
      onDataUpdate(specialFormValues);
      setPrevFormValuesNonEx(specialFormValues);
    }
  }, [specialFormValues, onDataUpdate, prevFormValuesNonEx]);

  // useEffect(() => {
  //   if (serviceNo && selectedYear) {
  //     setSpecialFormValues({
  //       specAddSalary: "",
  //       numSpecIncre: "",
  //       justific: "",
  //       gcw: "",
  //       costSav: "",
  //       is: "",
  //       innov: "",
  //       oc: "",
  //       uf: "",
  //       cusSatis: "",
  //       other: "",
  //       justiComment: "",

  //       recBy: loggedUserSN,

  //       recDepHead: "",

  //       approvDivHead: "",
  //       recDepHeadCom: isDeptalHead ? loggedUserSN : "",
  //       approvDivHeadCom: "",
  //     });
  //   }
  // }, [serviceNo, selectedYear]);

  // const handleSpecialFormChange = (event) => {
  //   const { name, checked, value } = event.target;

  //   if (name === "specAddSalary") {
  //     if (value === "N") {
  //       // User selected "No," clear all other fields
  //       setSpecialFormValues({
  //         specAddSalary: "N",
  //         numSpecIncre: "",
  //         justific: "",
  //         gcw: "",
  //         costSav: "",
  //         is: "",
  //         innov: "",
  //         oc: "",
  //         uf: "",
  //         cusSatis: "",
  //         other: "",
  //         justiComment: "",
  //         recBy: loggedUserSN,
  //         recDepHead: "",
  //         approvDivHead: "",
  //         recDepHeadCom: "",
  //         approvDivHeadCom: "",
  //       });
  //       setIsNoSelected(true);
  //     }
  //   }

  //   if (
  //     name === "gcw" ||
  //     name === "costSav" ||
  //     name === "is" ||
  //     name === "innov" ||
  //     name === "uf" ||
  //     name === "oc" ||
  //     name === "cusSatis" ||
  //     name === "other"
  //   ) {
  //     setSpecialFormValues((prevValues) => ({
  //       ...prevValues,
  //       [name]: checked ? "Y" : "",
  //     }));
  //   } else if (name === "recDepHead") {
  //     setSpecialFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  //     setIsDepHead(checked);
  //     if (checked) {
  //       setSpecialFormValues((prevValues) => ({
  //         ...prevValues,
  //         recDepHeadCom: loggedUserSN,
  //       }));
  //     }
  //     else {
  //       setSpecialFormValues((prevValues) => ({
  //         ...prevValues,
  //         recDepHeadCom: "",
  //       }));
  //     }
  //     console.log("isDeptalHead:", isDeptalHead); // Add this line
  //   } else if (name === "approvDivHead") {
  //     setSpecialFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  //     setIsDivHead(checked);
  //     if (checked) {
  //       setSpecialFormValues((prevValues) => ({
  //         ...prevValues,
  //         approvDivHeadCom: loggedUserSN,
  //       }));
  //     }
  //     else {
  //       setSpecialFormValues((prevValues) => ({
  //         ...prevValues,
  //         approvDivHeadCom: "",
  //       }));
  //     }
  //   } else {
  //     setSpecialFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  //   }

  //   onDataUpdate({
  //     ...specialFormValues,
  //     [name]: value,

  //   });
  // };

  const handleSpecialFormChange = (event) => {
    const { name, checked, value, type } = event.target;

    if (name === "specAddSalary") {
      if (value === "N") {
        // User selected "No," clear all other fields
        setSpecialFormValues({
          specAddSalary: "N",
          numSpecIncre: 0,
          justific: "",
          gcw: "",
          costSav: "",
          is: "",
          innov: "",
          oc: "",
          uf: "",
          cusSatis: "",
          other: "",
          justiComment: "",
          recBy: loggedUserSN,
          recDepHead: "",
          approvDivHead: "",
          recDepHeadCom: "",
          approvDivHeadCom: "",
        });
        setIsNoSelected(true);
      }
    }

    if (
      name === "gcw" ||
      name === "costSav" ||
      name === "is" ||
      name === "innov" ||
      name === "uf" ||
      name === "oc" ||
      name === "cusSatis" ||
      name === "other"
    ) {
      setSpecialFormValues((prevValues) => ({
        ...prevValues,
        [name]: checked ? "Y" : "",
      }));
    } else if (name === "recDepHead") {
      setSpecialFormValues((prevValues) => ({ ...prevValues, [name]: value }));
      setIsDepHead(checked);
      if (checked) {
        setSpecialFormValues((prevValues) => ({
          ...prevValues,
          recDepHeadCom: loggedUserSN,
        }));
      } else {
        setSpecialFormValues((prevValues) => ({
          ...prevValues,
          recDepHeadCom: "",
        }));
      }
      console.log("isDeptalHead:", isDeptalHead); // Add this line
    } else if (name === "approvDivHead") {
      setSpecialFormValues((prevValues) => ({ ...prevValues, [name]: value }));
      setIsDivHead(checked);
      if (checked) {
        setSpecialFormValues((prevValues) => ({
          ...prevValues,
          approvDivHeadCom: loggedUserSN,
        }));
      } else {
        setSpecialFormValues((prevValues) => ({
          ...prevValues,
          approvDivHeadCom: "",
        }));
      }
    } else {
      setSpecialFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    }

    onDataUpdate({
      ...specialFormValues,
      [name]: type === "checkbox" ? (checked ? "Y" : "") : value,
    });
  };

  const handleHistoryStatusClick = (status) => {
    if (serviceNo === null && selectedYear === null) {
      alert("Please select an employee first");
    } else {
      setSelectedHistoryStatus(status);
    }
  };

  localStorage.setItem("SpecialIncrement", specialFormValues?.numSpecIncre);

  return (
    <Container fluid>
      <Card>
        <Card.Header className="text-center text-white h5 bg-black bg-primary">
          SPECIAL EVALUATION
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12}>
              <Card.Text style={{ fontWeight: "bold" }}>
                Evaluation History:
                <Button
                  variant="primary"
                  size="sm"
                  className="ml-2 pl-3 pr-3"
                  onClick={toggleModal}
                >
                  Click here
                </Button>
              </Card.Text>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={6} md={9}>
              <Card.Text>
                <span
                  className="text-danger font-weight-bold"
                  style={{ fontSize: 20 }}
                >
                  *{" "}
                </span>
                Do you recommend the above employee to be granted with "Special
                Additional" salary increments with regard to any exceptional
                performance / characteristic demonstrated by him/her?
              </Card.Text>
            </Col>
            <Col xs={6} md={3}>
              <Form style={{ marginLeft: "40%" }} className="mt-2">
                <Form.Check
                  inline
                  label="Yes"
                  name="specAddSalary"
                  id="specAddSalary1"
                  type="radio"
                  value="Y"
                  checked={specialFormValues.specAddSalary === "Y"}
                  // onChange={handleSpecialFormChange}
                  onChange={(e) => {
                    handleSpecialFormChange(e);
                    setIsNoSelected(false);
                  }}
                />
                <Form.Check
                  inline
                  label="No"
                  name="specAddSalary"
                  id="specAddSalary2"
                  type="radio"
                  value="N"
                  checked={specialFormValues.specAddSalary === "N"}
                  // onChange={handleSpecialFormChange}
                  onChange={(e) => {
                    handleSpecialFormChange(e);
                    setIsNoSelected(true);
                  }}
                />
              </Form>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={6} md={9}>
              <Card.Text>
                If yes, Number of special additional increments recommended
                (Please tick)
              </Card.Text>
            </Col>
            <Col xs={6} md={3}>
              <Form style={{ marginLeft: "40%" }}>
                {/* {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3"> */}
                <Form.Check
                  inline
                  label="1"
                  name="numSpecIncre"
                  id="numSpecIncre1"
                  type="radio"
                  value="1"
                  checked={specialFormValues.numSpecIncre === "1"}
                  onChange={handleSpecialFormChange}
                  disabled={isNoSelected}
                />
                <Form.Check
                  inline
                  label="2"
                  name="numSpecIncre"
                  id="numSpecIncre2"
                  type="radio"
                  value="2"
                  checked={specialFormValues.numSpecIncre === "2"}
                  onChange={handleSpecialFormChange}
                  disabled={isNoSelected}
                />
                {/* </div>
                ))} */}
              </Form>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Card.Title>Justification</Card.Title>
              <Card.Text>(Please justify your recommendation)</Card.Text>
              <Form.Group
                className="mb-3 "
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  as="textarea"
                  value={specialFormValues.justific}
                  name="justific"
                  rows={3}
                  onChange={handleSpecialFormChange}
                  disabled={isNoSelected}
                />
              </Form.Group>
            </Col>
          </Row>

          <Card className="p-3">
            <Card.Title>You justification mainly falls under</Card.Title>
            <Card.Body>
              <Row>
                <Col>
                  <Form>
                    <Form.Check
                      type="checkbox"
                      label="Great Commitment work"
                      className="mb-3"
                      id="check1"
                      name="gcw"
                      checked={specialFormValues.gcw == "Y"}
                      onChange={handleSpecialFormChange}
                      disabled={isNoSelected}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Cost saving"
                      id="check2"
                      className="mb-3"
                      name="costSav"
                      checked={specialFormValues.costSav == "Y"}
                      onChange={handleSpecialFormChange}
                      disabled={isNoSelected}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Important Suggestion"
                      className="mb-3"
                      id="check3"
                      name="is"
                      checked={specialFormValues.is == "Y"}
                      onChange={handleSpecialFormChange}
                      disabled={isNoSelected}
                    />
                    <Form.Check
                      type="checkbox"
                      id="check4"
                      label="Innovation"
                      name="innov"
                      checked={specialFormValues.innov == "Y"}
                      onChange={handleSpecialFormChange}
                      disabled={isNoSelected}
                    />
                  </Form>
                </Col>
                <Col>
                  <Form>
                    <Form.Check
                      type="checkbox"
                      id="check5"
                      label="Outstanding contribution"
                      className="mb-3"
                      name="oc"
                      checked={specialFormValues.oc == "Y"}
                      onChange={handleSpecialFormChange}
                      disabled={isNoSelected}
                    />
                    <Form.Check
                      type="checkbox"
                      id="check6"
                      label="Unique feat"
                      className="mb-3"
                      name="uf"
                      checked={specialFormValues.uf == "Y"}
                      onChange={handleSpecialFormChange}
                      disabled={isNoSelected}
                    />
                    <Form.Check
                      type="checkbox"
                      id="check7"
                      label="Customer satisfaction"
                      className="mb-3"
                      name="cusSatis"
                      checked={specialFormValues.cusSatis == "Y"}
                      onChange={handleSpecialFormChange}
                      disabled={isNoSelected}
                    />
                    <Form.Check
                      type="checkbox"
                      id="check8"
                      label="Other(Please specify)"
                      name="other"
                      checked={specialFormValues.other == "Y"}
                      onChange={handleSpecialFormChange}
                      disabled={isNoSelected}
                    />
                    {/* </div> */}
                    {/* ))} */}
                  </Form>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea2"
                  >
                    <Form.Control
                      as="textarea"
                      rows={5}
                      name="justiComment"
                      value={specialFormValues.justiComment}
                      onChange={handleSpecialFormChange}
                      disabled={!specialFormValues.other}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {/* recomendation */}
          <Card className="p-2 mt-3">
            <Form.Group as={Row} style={{ marginTop: "2%" }}>
              <Form.Label column sm={2}>
                <strong> Recommended By : </strong>
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  type="text"
                  name="recBy"
                  // value={`${loggedUserSN}`}
                  placeholder={`${loggedUserSN} - ${loggedUserName}`}
                  onChange={handleSpecialFormChange}
                  readOnly
                />
              </Col>
            </Form.Group>

            {/* Note */}

            <Row style={{ marginTop: "2%", marginLeft: "10%" }}>
              <Col>
                <Form>
                  <Form.Label className="me-2">
                    <strong>Recommendation of Departmental Head</strong>
                  </Form.Label>
                </Form>
              </Col>
              <Col>
                <Form>
                  {/* {["radio"].map((type) => (
                    <div key={`default-${type}`}> */}
                  <Form.Check
                    type="radio"
                    name="recDepHead"
                    label="Special Increments Recommended"
                    className="mb-3"
                    value="Y"
                    disabled={isNoSelected}
                    checked={specialFormValues.recDepHead === "Y"}
                    onChange={handleSpecialFormChange}
                    id="sir1"
                  />
                  <Form.Check
                    type="radio"
                    name="recDepHead"
                    value="N"
                    label="Special Increments Not Recommended"
                    checked={specialFormValues.recDepHead === ""}
                    onChange={handleSpecialFormChange}
                    disabled={isNoSelected}
                    id="sir2"
                  />
                  {/* </div>
                  ))} */}
                </Form>
              </Col>
              <Col>
                <Form.Group
                  className="mb-3 "
                  controlId="exampleForm.ControlTextarea3"
                >
                  <Form.Control
                    as="textarea"
                    rows={1}
                    name="recDepHeadCom"
                    // placeholder={isDeptalHead ? `${loggedUserSN} - ${loggedUserName}` : ""}
                    value={isDeptalHead ? loggedUserSN : ""}
                    disabled={isDeptalHead}
                    onChange={handleSpecialFormChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* other one */}
            <Row style={{ marginTop: "2%", marginLeft: "10%" }}>
              <Col>
                <Form>
                  <Form.Label className="me-2">
                    <strong>Approval of Division Head</strong>
                  </Form.Label>
                </Form>
              </Col>
              <Col>
                <Form>
                  {/* {["radio"].map((type) => (
                    <div key={`default-${type}`}> */}
                  <Form.Check
                    type="radio"
                    name="approvDivHead"
                    id="not_approved1"
                    label="Special Increments Approved"
                    className="mb-3"
                    value="Y"
                    onChange={handleSpecialFormChange}
                    disabled={isNoSelected}
                    checked={specialFormValues?.approvDivHead === "Y"}
                  />
                  <Form.Check
                    type="radio"
                    name="approvDivHead"
                    id="not_approved2"
                    value="N"
                    label="Special Increments Not Approved"
                    onChange={handleSpecialFormChange}
                    disabled={isNoSelected}
                    checked={specialFormValues?.approvDivHead === ""}
                  />
                  {/* </div>
                  ))} */}
                </Form>
              </Col>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea4"
                >
                  <Form.Control
                    as="textarea"
                    rows={1}
                    name="approvDivHeadCom"
                    value={isDivHead ? loggedUserSN : ""}
                    disabled={isDivHead}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Card>
        </Card.Body>
      </Card>
      <EvalHistModal
        showModal={showModal}
        toggleModal={toggleModal}
        serviceNo={serviceNo}
        selectedYear={selectedYear}
        selectedPeriod={selectedPeriod}
      />
    </Container>
  );
}

export default Special_evaluation;
