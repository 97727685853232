import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  Button,
  Table,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import axios from "axios";
import "./frm_e.css";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Table1 from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Frm_e({
  serviceNo,
  workCategory,
  selectedYear,
  selectedPeriod,
  onDataUpdate,
  currentEvalStatus
}) {
  const [marksList, setMarksList] = useState([]);
  const [marksSections, setMarksSections] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  // const [totalMarks, setTotalMarks] = useState(0);
  const [selectedMarksArray, setSelectedMarksArray] = useState([]);


  // new
  const [prevSelectedMarksArray, setPrevSelectedMarksArray] = useState([]);
const [prevTotalMarks, setPrevTotalMarks] = useState(0);
// new

  const [prevMarks, setPrevMarks] = useState([]);

  const achievementOrientation = marksList.slice(0, 6);
  const peopleOrientation = marksList.slice(6, 11);
  const managerialOrientation = marksList.slice(11, 17);
  const adaptiveOrientation = marksList.slice(17, 20);
  
  const blockbyEvalStatus = currentEvalStatus === "Authorized";

  const calculateSectionTotal = (section) => {
    return section.reduce((total, item) => {
      const evalutionCode = item.EvalutionCode;
      const selectedMark = selectedValues[evalutionCode] || 0;
      return total + parseInt(selectedMark);
    }, 0);
  };

  const achievementTotal = calculateSectionTotal(achievementOrientation);
  const peopleTotal = calculateSectionTotal(peopleOrientation);
  const managerialTotal = calculateSectionTotal(managerialOrientation);
  const adaptiveTotal = calculateSectionTotal(adaptiveOrientation);

  const totalMarks =
    achievementTotal + peopleTotal + managerialTotal + adaptiveTotal;

    console.log("tmarks - " + totalMarks)

    // useEffect(() => {
    //   const fetchData = async () => {
    //     try {
    //       const response = await axios.get(
    //         `Evaluation/GetEvaluationMarks?serviceNo=${serviceNo}&year=${selectedYear}&periodType=${selectedPeriod}&UserType=Ex`
    //       );
    
    //       if (response.status === 200) {
    //         setMarksList(response.data.ResultSet.MarksList);
    //         setMarksSections(response.data.ResultSet);
    //       } else {
    //         console.error("Unexpected response status:", response.status);
    //       }
    //     } catch (error) {
    //       console.error("Error fetching data:", error);
    //     }
    //   };
    
    //   fetchData();
    // }, [serviceNo, selectedYear, selectedPeriod]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `Evaluation/GetEvaluationMarks?serviceNo=${serviceNo}&year=${selectedYear}&periodType=${selectedPeriod}&UserType=Ex`
          );
    
          if (response.status === 200) {
            const marksListData = response.data.ResultSet.MarksList;
    
            // Initialize selectedValues and selectedMarksArray based on MarksListData
            const initialSelectedValues = {};
            const initialSelectedMarksArray = marksListData.map((item) => ({
              EvalutionCode: item.EvalutionCode,
              EvalutionMarks: item.EvalutionMarks,
            }));
    
            marksListData.forEach((item) => {
              initialSelectedValues[item.EvalutionCode] = item.EvalutionMarks;
            });
    
            // If MarksArray is initially null, set it to initialSelectedMarksArray
            if (response.data.MarksArray === null) {
              response.data.MarksArray = JSON.stringify(initialSelectedMarksArray);
            }
    
            setMarksList(marksListData);
            setMarksSections(response.data.ResultSet);
            setSelectedValues(initialSelectedValues);
            setSelectedMarksArray(initialSelectedMarksArray);
          } else {
            console.error("Unexpected response status:", response.status);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    
      fetchData();
    }, [serviceNo, selectedYear, selectedPeriod]);
    
    useEffect(() => {
      // Check if MarksArray and related values have changed
      if (
        JSON.stringify(selectedMarksArray) !== JSON.stringify(prevSelectedMarksArray) ||
        totalMarks !== prevTotalMarks
      ) {
        // Call onDataUpdate with the updated data
        onDataUpdate({
          MarksArray: JSON.stringify(selectedMarksArray),
          grandTotalMarks: totalMarks,
        });
    
        // Update prevSelectedMarksArray and prevTotalMarks
        setPrevSelectedMarksArray(selectedMarksArray);
        setPrevTotalMarks(totalMarks);
      }
    }, [selectedMarksArray, totalMarks, onDataUpdate, prevSelectedMarksArray, prevTotalMarks]);

  const handleButtonClick = (evalutionCode, mark) => {
    setSelectedValues({
      ...selectedValues,
      [evalutionCode]: mark,
    });

    setSelectedMarksArray((prevMarks) => {
      const updatedMarksArray = prevMarks.filter(
        (item) => item.EvalutionCode !== evalutionCode
      );
      updatedMarksArray.push({
        EvalutionCode: evalutionCode,
        EvalutionMarks: mark,
      });

      onDataUpdate({
        MarksArray: JSON.stringify(updatedMarksArray),
        grandTotalMarks: totalMarks,
      });

      return updatedMarksArray;
    });
  };

  // const calculateTotalMarks = () => {
  //   let total = 0;

  //   marksList.forEach((item) => {
  //     if (selectedValues[item.EvalutionCode] !== undefined) {
  //       total += parseInt(selectedValues[item.EvalutionCode], 10);
  //     }
  //   });

  //   setTotalMarks(total);
  // };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const performanceMarksDesc = [
    {
      rating: "Exceptional Performances",
      range: "Above 90",
      description:
        "Demonstrates the highest standards of performance excellence. Consistently exceeds all position requirements and expectations. Performances on objectives exceed quantity, quality, cost, and time standards.",
    },
    {
      rating: "Meets Performance Standards",
      range: "89 - 80",
      description:
        "Meets position requirements and expectations and demonstrates satisfactory performances on objectives.",
    },
    {
      rating: "Needs Improvement",
      range: "79 - 61",
      description:
        "Barely meets requirements in critical aspects of the job and has numerous performance problems. Critical to job performance.",
    },
    {
      rating: "Unsatisfactory",
      range: "60 - 51",
      description:
        "Does not meet minimum requirements in critical aspects of the job and has numerous performance problems. Corrective action needed.",
    },
    {
      rating: "Totally Unacceptable",
      range: "50 and below",
      description:
        "Does not fit into the organizational setting, either development or punitive action is required.",
    },
  ];

  return (
    <>
      <Container fluid style={{ fontFamily: "Segoe UI" }}>
        <Typography
          variant="inherit"
          padding={2}
          bgcolor={"ButtonFace"}
          fontSize={17}
          style={{
            borderRadius: 18,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        >
          <div className="row">
            <div className="col-8 text-left pt-1 font-weight-bold">
              <span
                className="text-danger font-weight-bold"
                style={{ fontSize: 20 }}
              >
                *{" "}
              </span>
              (E). EVALUATION - PERFORMANCE CRITERIA (To be evaluated by the
              Section)
            </div>
            <div className="col-4 text-right mt-2">
              <Button
                className="btn btn-sm btn-primary"
                onClick={handleClickOpen}
              >
                Increment Allocation
              </Button>
            </div>
          </div>
        </Typography>
        <Card
          className="text-black mb-4"
          style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
        >
          {/* A. ACHIEVEMENT ORIENTATION ===================================== */}

          <Card.Header>
            <div className="row">
              <div className="col text-left pt-1 font-weight-bold">
                A. ACHIEVEMENT ORIENTATION
              </div>
            </div>
          </Card.Header>

          {achievementOrientation.map((item) => (
            <div key={item.EvalutionCode}>
              <p className=" p-3">{item.EvalutionTitle}</p>
              <p className="pl-3">{item.EvalutionDescription}</p>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {item.HeaderList.map((header) => (
                      <th key={header.Marks}>{header.HeaderDescription}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {item.HeaderList.map((header) => (
                      <td
                        className={`text-center ${
                          selectedValues[item.EvalutionCode] === header.Marks ||
                          (!selectedValues[item.EvalutionCode] &&
                            item.EvalutionMarks === header.Marks)
                            ? "selected"
                            : ""
                        }`}
                        key={header.Marks}
                      >
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() =>
                            handleButtonClick(item.EvalutionCode, header.Marks)
                          }
                          disabled={blockbyEvalStatus}
                        >
                          {header.Marks}
                        </Button>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>
              <p className="p-3 text-primary text-right">
                Selected Value :
                {selectedValues[item.EvalutionCode] !== undefined ? (
                  <span>{selectedValues[item.EvalutionCode]}</span>
                ) : item.EvalutionMarks !== "" ? (
                  <span className="text-danger">{item.EvalutionMarks}</span>
                ) : (
                  <span className="text-danger">No value selected.</span>
                )}
              </p>
            </div>
          ))}

          {/* B. PEOPLE ORIENTATION ======================================== */}
          <Card.Header>
            <div className="row">
              <div className="col text-left pt-1 font-weight-bold">
                B. PEOPLE ORIENTATION
              </div>
            </div>
          </Card.Header>

          {peopleOrientation.map((item) => (
            <div key={item.EvalutionCode}>
              <p className="mt-4 p-3">{item.EvalutionTitle}</p>
              <p className="pl-3">{item.EvalutionDescription}</p>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {item.HeaderList.map((header) => (
                      <th key={header.Marks}>{header.HeaderDescription}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {item.HeaderList.map((header) => (
                      <td
                        className={`text-center ${
                          selectedValues[item.EvalutionCode] === header.Marks ||
                          (!selectedValues[item.EvalutionCode] &&
                            item.EvalutionMarks === header.Marks)
                            ? "selected"
                            : ""
                        }`}
                        key={header.Marks}
                      >
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() =>
                            handleButtonClick(item.EvalutionCode, header.Marks)
                          }
                          disabled={blockbyEvalStatus}
                        >
                          {header.Marks}
                        </Button>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>
              <p className="p-3 text-primary text-right">
                Selected Value :
                {selectedValues[item.EvalutionCode] !== undefined ? (
                  <span>{selectedValues[item.EvalutionCode]}</span>
                ) : item.EvalutionMarks !== "" ? (
                  <span className="text-danger">{item.EvalutionMarks}</span>
                ) : (
                  <span className="text-danger">No value selected.</span>
                )}
              </p>
            </div>
          ))}

          {/* C. MANAGERIAL ORIENTATION ==================================== */}
          <Card.Header>
            <div className="row">
              <div className="col text-left pt-1 font-weight-bold">
                C. MANAGERIAL ORIENTATION
              </div>
            </div>
          </Card.Header>

          {managerialOrientation.map((item) => (
            <div key={item.EvalutionCode}>
              <p className="mt-4 p-3">{item.EvalutionTitle}</p>
              <p className="pl-3">{item.EvalutionDescription}</p>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {item.HeaderList.map((header) => (
                      <th key={header.Marks}>{header.HeaderDescription}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {item.HeaderList.map((header) => (
                      <td
                        className={`text-center ${
                          selectedValues[item.EvalutionCode] === header.Marks ||
                          (!selectedValues[item.EvalutionCode] &&
                            item.EvalutionMarks === header.Marks)
                            ? "selected"
                            : ""
                        }`}
                        key={header.Marks}
                      >
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() =>
                            handleButtonClick(item.EvalutionCode, header.Marks)
                          }
                          disabled={blockbyEvalStatus}
                        >
                          {header.Marks}
                        </Button>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>
              <p className="p-3 text-primary text-right">
                Selected Value :
                {selectedValues[item.EvalutionCode] !== undefined ? (
                  <span>{selectedValues[item.EvalutionCode]}</span>
                ) : item.EvalutionMarks !== "" ? (
                  <span className="text-danger">{item.EvalutionMarks}</span>
                ) : (
                  <span className="text-danger">No value selected.</span>
                )}
              </p>
            </div>
          ))}

          {/* D. ADAPTIVE ORIENTATION ====================================== */}
          <Card.Header>
            <div className="row">
              <div className="col text-left pt-1 font-weight-bold">
                D. ADAPTIVE ORIENTATION
              </div>
            </div>
          </Card.Header>

          {adaptiveOrientation.map((item) => (
            <div key={item.EvalutionCode}>
              <p className="mt-4 p-3">{item.EvalutionTitle}</p>
              <p className="pl-3">{item.EvalutionDescription}</p>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {item.HeaderList.map((header) => (
                      <th key={header.Marks}>{header.HeaderDescription}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {item.HeaderList.map((header) => (
                      <td
                        className={`text-center ${
                          selectedValues[item.EvalutionCode] === header.Marks ||
                          (!selectedValues[item.EvalutionCode] &&
                            item.EvalutionMarks === header.Marks)
                            ? "selected"
                            : ""
                        }`}
                        key={header.Marks}
                      >
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() =>
                            handleButtonClick(item.EvalutionCode, header.Marks)
                          }
                          disabled={blockbyEvalStatus}
                        >
                          {header.Marks}
                        </Button>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>
              <p className="p-3 text-primary text-right">
                Selected Value :
                {selectedValues[item.EvalutionCode] !== undefined ? (
                  <span>{selectedValues[item.EvalutionCode]}</span>
                ) : item.EvalutionMarks !== "" ? (
                  <span className="text-danger">{item.EvalutionMarks}</span>
                ) : (
                  <span className="text-danger">No value selected.</span>
                )}
              </p>
            </div>
          ))}

          {/* Marks */}
          <Card className="text-black m-3 bg-light">
            <Card.Body>
              <Row>
                <Col md="3">
                  <Form.Label className="font-weight-bold">
                    Achievements Orientation
                  </Form.Label>
                  <Form.Control
                    className="bg-white"
                    type="number"
                    value={typeof achievementTotal === "" || achievementTotal === 0
                    ? marksSections.TotalMarksA
                    : achievementTotal}
                    disabled
                  />
                </Col>
                <Col md="3">
                  <Form.Label className="font-weight-bold">
                    People Orientation
                  </Form.Label>
                  <Form.Control
                    className="bg-white"
                    type="number"
                    value={typeof peopleTotal === "" || peopleTotal === 0
                    ? marksSections.TotalMarksB
                    : peopleTotal}
                    disabled
                  />
                </Col>
                <Col md="3">
                  <Form.Label className="font-weight-bold">
                    Managerial Orientation
                  </Form.Label>
                  <Form.Control
                    className="bg-white"
                    type="number"
                    value={typeof managerialTotal === "" || managerialTotal === 0
                    ? marksSections.TotalMarksC
                    : managerialTotal}
                    disabled
                  />
                </Col>
                <Col md="3">
                  <Form.Label className="font-weight-bold">
                    Adaptive Orientation
                  </Form.Label>
                  <Form.Control
                    className="bg-white"
                    type="number"
                    value={typeof adaptiveTotal === "" || adaptiveTotal === 0
                    ? marksSections.TotalMarksD
                    : adaptiveTotal}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                {/* Total marks ==================================== */}
                <Col md="12" className="pt-4">
                  <h5 className="text-primary text-right font-weight-bold">
                    Total Marks (Out of 100):{" "}
                    {typeof totalMarks === "" || totalMarks === 0
                      ? marksSections.TotalMarks
                      : totalMarks}
                  </h5>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Card>

        {/* dialog - evaluation marks details */}
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="md"
          style={{ font: "Segoe UI" }}
        >
          <DialogTitle color="primary">
            {"OVERALL PERFORMANCE RATING (E) - EXECUTIVE"}
          </DialogTitle>
          <DialogTitle align="right" variant="h6">
            {"Out of 100 marks"}
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  width: "100%",
                },
              }}
            >
              <Paper elevation={0} sx={{ borderRadius: 4 }}>
                <TableContainer>
                  <Table1>
                    <TableHead></TableHead>
                    <TableBody>
                      {performanceMarksDesc.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell
                            sx={{
                              textTransform: "uppercase",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            {row.range}
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            {row.rating}
                          </TableCell>
                          <TableCell>{row.description}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table1>
                </TableContainer>
              </Paper>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}

export default Frm_e;
