import React from "react";
import Sidebar from "../components/sidebar/Sidebar";
import axios from "axios";
import classNames from "classnames";
import NavBar from "../components/navbar/Navbar";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import ModalComponent from "./modals/EmpDataModal";
import Frm_a from "./Frm_a";
import Frm_b from "./Frm_b";
import Frm_c from "./Frm_c";
import Frm_d from "./Frm_d";
import RecForGrade_frm from "./RecForGrade_frm";
import Frm_e from "./Frm_e";
import TrainingAttend from "./TrainingAttend";
import Special_evaluation from "./Special_evaluation";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import "./excontent.css";
const Swal = require("sweetalert2");

const styles = {
  customSelect: {
    padding: "5px",
    textAlign: "center",
    border: "0.5px solid #ccc",
    borderRadius: "6px",
    backgroundColor: "white",
  },
};

const now = new Date();
const nowdate = `${now.getFullYear()}-${(now.getMonth() + 1)
  .toString()
  .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}`;

class ExContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      years: [],
      selectedYear: null,
      isModalLoading: false,
      selectedServiceNo: null,
      selectedEmpName: null,
      workCategory: null,
      workCategoryCode: null,
      evaluationStatus: null,
      EvaluationStatus: null,
      selectedPeriod: null,
      selectedEvalStatus: null,
      formDataSave: {
        recForGradeData: null,
        specialEvaluationData: null,
        incrementData: null,
        frmEData: null,
      },
    };
  }

  // Callback function to update formDataSave
  onDataUpdate = (updatedData, componentName) => {
    this.setState((prevState) => ({
      formDataSave: {
        ...prevState.formDataSave,
        [componentName]: updatedData,
      },
    }));
    console.log(updatedData);
  };

  componentDidMount() {
    this.fetchYears();
  }

  handleFileUpload = (event) => {
    const files = event.target.files;
  };

  fetchYears = async () => {
    try {
      const apiYear = localStorage.getItem("default_year");
      const years = Array.from({ length: 6 }, (_, index) => apiYear - index);
      this.setState({ years });
    } catch (error) {
      console.error("Error fetching years:", error);
    }
  };

  handleOpenModal = () => {

    if (this.state.selectedPeriod === null) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 1800,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
  
      Toast.fire({
        icon: "error",
        title: "Please choose a Period!",
      });
      return; // Exit the function, since we can't proceed without a selected period
    }

    this.setState({ isModalLoading: true });

    setTimeout(() => {
      this.setState({ showModal: true, isModalLoading: false });
    }, 2000);
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleYearChange = (event) => {
    const selectedYear = event.target.value;
    this.setState({ selectedYear });
  };

  handleModalSelect = async (serviceNo, name, evaluationStatus) => {
    this.setState({ selectedServiceNo: serviceNo });
    this.setState({ selectedEmpName: name });

    try {
      const response = await axios.get(
        `Evaluation/GetPersonalData?UserType=Ex&serviceNo=${serviceNo}`
      );

      const workCategory = response.data.ResultSet.WorkCategory;
      const workCategoryCode = response.data.ResultSet.WorkCat;
      const divCode = response.data.ResultSet.DivisionCode;
      const depCode = response.data.ResultSet.DepartmentCode;
      const locCode = response.data.ResultSet.LocationCode;
      const grade = response.data.ResultSet.PresentGrade;
      const empCategory = response.data.ResultSet.Category;
      const PresentGradeCode = response.data.ResultSet.PresentGradeCode;
      const catCode = response.data.ResultSet.Cat;

      this.setState({ workCategoryCode });
      this.setState({ workCategory });
      this.setState({ evaluationStatus });
      this.setState({ divCode });
      this.setState({ depCode });
      this.setState({ locCode });
      this.setState({ grade });
      this.setState({ empCategory });
      this.setState({ PresentGradeCode });
      this.setState({ catCode });
      console.log(`Selected service number: ${workCategory}`);

      this.handleCloseModal();
    } catch (error) {
      console.error("Error fetching personal data:", error);
    }
  };

  handlePeriodChange = (event) => {
    // const selectedPeriod = event.target.value === "Mid year" ? 1 : 2;
    const selectedPeriod = event.target.value;
    this.setState({ selectedPeriod });

    console.log("Selected Period: " + selectedPeriod);
  };

  handleEvaluationStatusChange = (event) => {
    const selectedEvalStatus = event.target.value;
    this.setState({ selectedEvalStatus });

    console.log("Selected evaluation status: " + selectedEvalStatus);
  };

  render() {
    const loggedUserSN = localStorage.getItem("loggedUser");

    const {
      years,
      showModal,
      selectedYear,
      isModalLoading,
      selectedServiceNo,
      selectedEmpName,
      workCategory,
      evaluationStatus,
      selectedPeriod,
      workCategoryCode,
      selectedEvalStatus,
      divCode,
      depCode,
      locCode,
      grade,
      PresentGradeCode,
      catCode,
      empCategory,
    } = this.state;

    // const empImage = await axios.get(
    //   `ExEvaluation/GetPersonalData?serviceNo=${serviceNo}`
    // );

    // const workCategory = response.data.ResultSet.WorkCategory;

    const selectedEmployee = selectedServiceNo + " - " + selectedEmpName;

    const empImage = selectedServiceNo
      ? `https://esystems.cdl.lk/backend-Test/PerformanceEvaluation/Evaluation/GetUserImg?serviceNo=${selectedServiceNo}`
      : // ? `http://localhost:57587/Evaluation/GetUserImg?serviceNo=${selectedServiceNo}`
        require("../images/executive.png");

    const defaultYear = years.length > 0 ? years[0] : "";

    if (!selectedYear && years.length > 0) {
      const apiYear = years[0];
      this.setState({ selectedYear: apiYear });
    }

    const handleReset = () => {
      Swal.fire({
        title: "Are you sure to reset the data?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "green",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Reset.",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    };

    const saveEvaluateDataEx = async () => {
      const SaveEvaluationLogDetails = {
        ServiceNo: selectedServiceNo,
        Year: selectedYear,
        Period: selectedPeriod,
        SpecialCommentsBy: this.state.formDataSave.recForGradeData?.deph !== null
        ? loggedUserSN
        : "",
        EvalutionStatus: selectedEvalStatus,
        Category: workCategoryCode,
      };

      const SaveEmployeeEvaluationDetails = {
        
        ServiceNo: selectedServiceNo,
        Year: selectedYear,
        Period: selectedPeriod,
        PromotionRecommened:
          this.state.formDataSave.recForGradeData?.praDivh || null,
        RecommenedIncrements:
          this.state.formDataSave.recForGradeData?.recIncrements || 0,
        CatCode: catCode,
        WorkCategory: workCategoryCode,
        Grade: PresentGradeCode,
        SpecialIncrements:
          this.state.formDataSave.specialEvaluationData?.numSpecIncre || 0,
        CommitmentWork:
          this.state.formDataSave.specialEvaluationData?.gcw || null,
        CostSaving:
          this.state.formDataSave.specialEvaluationData?.costSav || null,
        ImportantSuggestion:
          this.state.formDataSave.specialEvaluationData?.is || null,
        Innovation:
          this.state.formDataSave.specialEvaluationData?.innov || null,
        OutstandingContribution:
          this.state.formDataSave.specialEvaluationData?.oc || null,
        UniqueFeat: this.state.formDataSave.specialEvaluationData?.uf || null,
        CustomerSatisfaction:
          this.state.formDataSave.specialEvaluationData?.cusSatis || null,
        Others: this.state.formDataSave.specialEvaluationData?.other || null,
        DivisionCode: divCode,
        DepartmentCode: depCode,
        LocationCode: locCode,
        Achievements:
          this.state.formDataSave.specialEvaluationData?.justific || null,
        // SpecialCommentsBy:
        //   this.state.formDataSave.recForGradeData?.deph !== null
        //     ? loggedUserSN
        //     : "",
            SpecialCommentsBy: loggedUserSN,
        // RecommenedBy: this.state.formDataSave.specialEvaluationData?.recDepHead !== null
        // ? loggedUserSN
        // : "",
        RecommenedBy : loggedUserSN,
        SpiRecommenedBy: loggedUserSN,
        SpiApprovedBy: loggedUserSN,
        SpiCommentsBy: loggedUserSN,
        SpecialComments:
          this.state.formDataSave.recForGradeData?.commentsDeph || null,
        Recommendations:
          this.state.formDataSave.recForGradeData?.commentsDivh || null,
        // DphpromotionRecommened:
        //   this.state.formDataSave.recForGradeData?.deph || null,
        
        SpcommentStatus:
          this.state.formDataSave.specialEvaluationData?.specAddSalary || null,
        // CommentsBy: this.state.formDataSave.specialEvaluationData?.recBy !== null
        // ? loggedUserSN
        // : "",
        CommentsBy : loggedUserSN,
        EvalutionStatus: selectedEvalStatus,
        RevisionNo: null,
        TrainingRequirment:
          this.state.formDataSave.incrementData?.anyOtherTraining || null,
        TrainingRecommenedBy: this.state.formDataSave.incrementData?.divHApproval !== null
            ? loggedUserSN
            : "",
        ComunicationStatus:
          this.state.formDataSave.recForGradeData?.discWithEmp || null,
        AchievementsRemarks:
          this.state.formDataSave.specialEvaluationData?.otherComment || null,
        // ObservationApprovedBy: this.state.formDataSave.recForGradeData?.praMD !== null
        // ? loggedUserSN
        // : "",
        ObservationApprovedBy:loggedUserSN,
        
        ObservationRemarks:
          this.state.formDataSave.recForGradeData?.commentsMD || null,
        // DihpromotionRecommened:
        //   this.state.formDataSave.recForGradeData?.praDivh || null,
        DphpromotionRecommened: "Y",
        DihpromotionRecommened: "Y",
        MdpromotionRecommened: "Y",
        // MdpromotionRecommened:
        //   this.state.formDataSave.recForGradeData?.praMD || null,
        
        SpiCommentsStatus:
          this.state.formDataSave.specialEvaluationData?.specIncrRecDpH || null,
        SpiApprovedStatus:
          this.state.formDataSave.specialEvaluationData?.specIncrApprovDvH ||
          null,
      };

      const SaveEvaluationDetails = {
        ServiceNo: selectedServiceNo,
        LoggedUser: loggedUserSN,
        Year: selectedYear,
        Period: selectedPeriod,
        MarksArray: this.state.formDataSave.frmEData?.MarksArray || null,
        EvalutionCategory: workCategoryCode,
        EvalutionStatus: selectedEvalStatus,
      };

      const SaveTrainingNeeds = {
        ServiceNo: selectedServiceNo,
        Year: selectedYear,
        Period: selectedPeriod,
        LocationCode: locCode,
        DepartmentCode: depCode,
        DivisionCode: divCode,
        CourseCodeList: this.state.formDataSave.incrementData?.courses || null,
      };

      if (!selectedEvalStatus) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Evaluation Status is required!.",
        });
        return;
      } else if (selectedEvalStatus == evaluationStatus) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please select the next Evaluation status!.",
        });
        return;
      } else if (!selectedPeriod) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please select the Evaluation Period!.",
        });
        return;
      } else if (
        !SaveEvaluationLogDetails.ServiceNo
        // !SaveEvaluationLogDetails.Year ||
        // !SaveEvaluationLogDetails.Period ||
        // // !SaveEvaluationLogDetails.SpecialCommentsBy ||
        // !SaveEvaluationLogDetails.EvalutionStatus ||
        // !SaveEvaluationLogDetails.Category ||
        // !SaveEvaluationDetails.MarksArray
        // !SaveEmployeeEvaluationDetails.ComunicationStatus ||
        // !SaveEmployeeEvaluationDetails.SpcommentStatus
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Required values are empty! Please try again. Required Values are highlited with * mark.",
        });
        return;
      }

      // console.log("Selected divCode number", divCode);

      // Save starts
      Swal.fire({
        title: "Are you sure to save data?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Save data!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await axios.post(
              "Evaluation/SaveEvaluationLogDetails?UserType=Ex",
              SaveEvaluationLogDetails
            );

            const response1 = await axios.post(
              "Evaluation/SaveEmployeeEvaluationDetails?UserType=Ex",
              SaveEmployeeEvaluationDetails
            );

            const response2 = await axios.post(
              "Evaluation/SaveTrainingNeeds?UserType=Ex",
              SaveTrainingNeeds
            );

            const response3 = await axios.post(
              "Evaluation/SaveEvaluationDetails?UserType=Ex",
              SaveEvaluationDetails
            );

            console.log("Save 1 Dataaaaaaa", SaveEvaluationLogDetails);
            console.log("Save 2 Data", SaveEmployeeEvaluationDetails);
            console.log("Save 3 Data", SaveTrainingNeeds);
            console.log("Save 4 Data", SaveEvaluationDetails);

            if (
              response.status === 200 &&
              response1.status === 200 &&
              response2.status === 200 &&
              response3.status === 200
            ) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Evaluation Completed Successfully!",
                showConfirmButton: false,
                timer: 1500,
              });
              localStorage.removeItem("RecommendedIncrements");
              localStorage.removeItem("SpecialIncrement");
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to save evaluation data.",
              });
            }
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Error saving evaluation data",
            });
          }
        }
      });

      console.log("Save 1 Data", SaveEvaluationLogDetails);
      console.log("Save 2 Data", SaveEmployeeEvaluationDetails);
      console.log("Save 3 Data", SaveTrainingNeeds);
      console.log("Save 4 Data", SaveEvaluationDetails);
    };

    return (
      <>
        <Sidebar toggle={this.props.toggle} isOpen={this.props.isOpen} />
        <Container
          fluid
          className={classNames("content", { "is-open": this.props.isOpen })}
        >
          <NavBar toggle={this.props.toggle} />
          <Row>
            <Col xs="12" sm="12" md="4" lg="4" className="pr-0">
              {/* <p className="pt-2" style={{fontWeight: "bold", color:"dodgerblue", fontSize:"20px"}}>EXECUTIVE EMPLOYEE EVALUATION</p> */}
              <Card
                className="shadow p-0 mt-2 mb-4 text-dark text-start"
                style={{ borderRadius: 18, border: "none" }}
              >
                <Card.Body>
                  <form className="row g-3">
                    {(selectedServiceNo !== null ||
                      selectedEmpName !== null) && (
                      <div className="col-12 ">
                        <h6 className="mb-3 text-primary font-weight-bold">
                          Evaluating Employee: {selectedEmployee}
                        </h6>
                      </div>
                    )}
                    {(evaluationStatus !== null ||
                      selectedEmpName !== null) && (
                      <div className="col-12 pb-2">
                        <h6 className=" text-primary font-weight-bold">
                          Current Evaluation Status -{" "}
                          <span className="text-success">
                            {evaluationStatus}
                          </span>
                        </h6>
                      </div>
                    )}
                    <div className="col-8 p-0">
                      <div className="col-12">
                        <label
                          htmlFor="inputEmail4"
                          className="form-label required font-weight-bold"
                        >
                          Select an Employee:
                        </label>
                        <Button
                          type=""
                          className="btn btn-sm btn-primary ml-2"
                          onClick={this.handleOpenModal}
                        >
                          Click here
                        </Button>
                      </div>

                      <div className="col-12 mt-2">
                        <label
                          htmlFor="inputState"
                          className="form-label required"
                        >
                          Evaluation Status:
                        </label>
                        <select
                          id="inputState"
                          className="form-select ml-2"
                          style={styles.customSelect}
                          onChange={this.handleEvaluationStatusChange}
                          disabled = {evaluationStatus === "Accepted by HRD"}
                        >
                          {/* <option value="" disabled selected>
                            Select an option
                          </option>
                          <option disabled={evaluationStatus ? "Preparation Complete" : false} value="E1">Incomplete</option>
                          <option disabled={evaluationStatus ? "Checking Complete" : false} value="E2">Preparation Complete</option>
                          <option disabled={evaluationStatus ? "Authorized" : false} value="E3">Checking Complete</option>
                          <option disabled={evaluationStatus ? "Accepted by HRD" : false} value="E4">Authorized</option>
                          <option value="E5">Accepted by HRD</option>
                          <option value="E6">Returned by HRD</option> */}
                          <option value="" disabled>
                            Select an option
                          </option>
                          <option disabled={evaluationStatus === "In Complete" || evaluationStatus === "Preparation Complete" || evaluationStatus === "Checking Complete" || evaluationStatus === "Authorized" || evaluationStatus === "Accepted by HRD" || evaluationStatus === "Returned by HRD"} value="E1">
                            Incomplete
                          </option>
                          <option disabled={evaluationStatus === "Preparation Complete" || evaluationStatus === "Checking Complete" || evaluationStatus === "Authorized" || evaluationStatus === "Accepted by HRD" || evaluationStatus === "Returned by HRD"} value="E2">
                            Preparation Complete
                          </option>
                          <option disabled={evaluationStatus === "Checking Complete" || evaluationStatus === "Authorized" || evaluationStatus === "Accepted by HRD" || evaluationStatus === "Returned by HRD"} value="E3">
                            Checking Complete
                          </option>
                          <option disabled={evaluationStatus === "Authorized" || evaluationStatus === "Accepted by HRD" || evaluationStatus === "Returned by HRD"} value="E4">
                            Authorized
                          </option>
                          <option disabled={evaluationStatus === "Accepted by HRD" || evaluationStatus === "Returned by HRD"} value="E5">
                            Accepted by HRD
                          </option>
                          <option value="E6">Returned by HRD</option>
                        </select>
                      </div>

                      <div className="col-12 mt-2">
                        <label
                          htmlFor="inputState"
                          className="form-label required"
                        >
                          Category:
                        </label>
                        <label>{workCategory}</label>
                      </div>
                    </div>
                    <div className="col-4 p-0">
                      <div className="col-12 pt-2 text-center">
                        <img
                          className="fade-in"
                          src={empImage}
                          style={{ width: "70%" }}
                          alt="User"
                        />
                      </div>
                    </div>

                    <div className="col-5 flex-start">
                      <label
                        htmlFor="inputState"
                        className="form-label required"
                      >
                        Period:
                      </label>
                      <select
                        id="inputState"
                        className="form-select ml-2"
                        style={styles.customSelect}
                        onChange={this.handlePeriodChange}
                        // value={selectedPeriod === 1 ? "Mid year" : "Year end"}
                      >
                        <option value="" disabled selected>
                          Choose
                        </option>
                        <option value="1">Mid year</option>
                        <option value="2">Year end</option>
                      </select>
                    </div>

                    <div className="col-5 flex-start pl-0 ml-0">
                      <label
                        htmlFor="inputState"
                        className="form-label required"
                      >
                        Year:
                      </label>
                      <select
                        id="inputState"
                        className="form-select ml-2"
                        style={styles.customSelect}
                        onChange={this.handleYearChange}
                      >
                        {years.map((year, index) => (
                          <option key={index} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-2"></div>
                    <div className="col-12 pt-2">
                      <div className="uploader-container">
                        <span className="uploader-label">
                          PCR Attachments :{" "}
                        </span>
                        <input
                          type="file"
                          className="uploader-input"
                          multiple
                          onChange={this.handleFileUpload}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row mt-2 d-flex text-right">
                        <div className="col-12">
                          <Button
                            type=""
                            className="btn  btn-success"
                            onClick={saveEvaluateDataEx}
                          >
                            Save Data
                          </Button>
                          <Button
                            type=""
                            className="btn  btn-danger ml-2"
                            onClick={handleReset}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </Card.Body>
              </Card>

              <Frm_a serviceNo={selectedServiceNo} />
            </Col>
            <Col xs="12" sm="12" md="8" lg="8" className="pl-0">
              <div
                className="overflow-auto h-100"
                style={{ maxHeight: "calc(100vh - 100px)" }}
              >
                <Frm_b
                  serviceNo={selectedServiceNo}
                  selectedYear={this.state.selectedYear}
                />
                <Frm_c
                  serviceNo={selectedServiceNo}
                  selectedYear={this.state.selectedYear}
                />
                <Frm_d
                  serviceNo={selectedServiceNo}
                  selectedYear={this.state.selectedYear}
                />
                <Frm_e
                  serviceNo={selectedServiceNo}
                  workCategory={this.state.workCategory}
                  selectedYear={this.state.selectedYear}
                  selectedPeriod={this.state.selectedPeriod}
                  currentEvalStatus={this.state.evaluationStatus}
                  onDataUpdate={(data) => this.onDataUpdate(data, "frmEData")}
                />
                <RecForGrade_frm
                serviceNo={selectedServiceNo}
                selectedYear={this.state.selectedYear}
                  currentEvalStatus={this.state.evaluationStatus}
                selectedPeriod={this.state.selectedPeriod}
                  loggedUserSN={loggedUserSN}
                  onDataUpdate={(data) =>
                    this.onDataUpdate(data, "recForGradeData")
                  }
                />
                <Special_evaluation
                  serviceNo={selectedServiceNo}
                  selectedYear={this.state.selectedYear}
                  selectedPeriod={this.state.selectedPeriod}
                  currentEvalStatus={this.state.evaluationStatus}
                  loggedUserSN={loggedUserSN}
                  onDataUpdate={(data) =>
                    this.onDataUpdate(data, "specialEvaluationData")
                  }
                />
                <TrainingAttend
                  serviceNo={selectedServiceNo}
                  selectedYear={this.state.selectedYear}
                  selectedPeriod={this.state.selectedPeriod}
                  workCategory={this.state.workCategory}
                  currentEvalStatus={this.state.evaluationStatus}
                  workCategoryCode={this.state.workCategoryCode}
                  loggedUserSN={loggedUserSN}
                  onDataUpdate={(data) =>
                    this.onDataUpdate(data, "incrementData")
                  }
                />
              </div>
            </Col>
          </Row>
          {isModalLoading && (
            <Backdrop
              open={true}
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
            >
              <CircularProgress color="inherit" size={50} />
            </Backdrop>
          )}

          {showModal && (
            <ModalComponent
              onClose={this.handleCloseModal}
              selectedYear={this.state.selectedYear}
              onSelect={this.handleModalSelect}
            />
          )}
        </Container>
      </>
    );
  }
}

export default React.memo(ExContent);
