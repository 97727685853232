import React, { useState, useEffect } from "react";
import { Card, Container, Form, Col, Table, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

function Frm_b({ serviceNo, selectedYear }) {
  const [attendanceData, setAttendanceData] = useState(null);
  const [loading, setLoading] = useState(true); // New state to track loading

  const now = new Date();
  const nowdate = `${now.getFullYear()}-${(now.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}`;

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `Evaluation/GetAttendanceSummaryData?UserType=NonEx&serviceNo=${serviceNo}&year=${selectedYear}`
      );

      if (response.status === 200) {
        setAttendanceData(response.data.ResultSet);
        setLoading(false);
      } else {
        console.error(`Request failed with status code ${response.status}`);
        setLoading(false);
        // Retry the request after a delay
        setTimeout(() => fetchData(), 1000); // Retry after 1 second (adjust as needed)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      // Retry the request after a delay
      setTimeout(() => fetchData(), 1000); // Retry after 1 second (adjust as needed)
    }
  };

  useEffect(() => {
    if (serviceNo && selectedYear) {
      fetchData();
    }
  }, [serviceNo, selectedYear]);

  console.log("attendanceData:", attendanceData);

  return (
    <Container fluid>
      <Card className="text-black mt-2 mb-4">
        <Card.Header>
          (B). ATTENDANCE SUMMARY (FROM : {selectedYear}-01-01 TO {nowdate})
        </Card.Header>
        <Card.Body>
          {loading ? (
            <div style={{ color: "darkgray" }}>Please select an employee</div>
          ) : attendanceData ? (
            // <Spinner animation="border" size={5} role="status" />
            <div
              className="row"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div className="col">
                <Table
                  striped
                  bordered
                  hover
                  style={{
                    maxWidth: "100%",
                    // marginRight: "5%",
                    border: "1px solid #ccc",
                  }}
                >
                  <thead>
                    <tr className="text-center">
                      {/* <th></th> */}
                      <th>Description</th>
                      <th>Total</th>
                      <th>Taken</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {attendanceData?.AttendanceSummaryDataGrid1?.length > 0 ? (
                      attendanceData?.AttendanceSummaryDataGrid1?.map(
                        (item, index) => (
                          <tr key={index}>
                            {/* <td>{index + 1}</td> */}
                            <td>{item?.Description}</td>
                            <td className="text-right">{item?.Total}</td>
                            <td className="text-right">{item?.Taken}</td>
                            <td className="text-right">{item?.Balance}</td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td className="text-center" colSpan="5">
                          No data loaded.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>

              <div className="col">
                <Table
                  striped
                  bordered
                  hover
                  style={{
                    // width: "25%",
                    maxWidth: "100%",
                    border: "1px solid #ccc",
                  }}
                >
                  <thead>
                    <tr className="text-center">
                      {/* <th></th> */}
                      <th>Leave Type</th>
                      <th>Days</th>
                    </tr>
                  </thead>
                  <tbody>
                    {attendanceData?.AttendanceSummaryDataGrid2?.length > 0 ? (
                      attendanceData?.AttendanceSummaryDataGrid2?.map(
                        (item, index) => (
                          <tr key={index}>
                            {/* <td>{index + 1}</td> */}
                            <td>{item?.LeaveType}</td>
                            <td className="text-right">{item?.Days}</td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td className="text-center" colSpan="3">
                          No data loaded.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="col">
                <Form className=" justify-content-between pl-4">
                  <Form.Group as={Col} md="12" className=" align-items-center">
                    <Form.Label className="me-2">
                      Short leave Taken: {attendanceData?.Short_leave}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="12" className=" align-items-center">
                    <Form.Label className="me-2">
                      Late Occasions: {attendanceData?.Late_occassions}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="12" className=" align-items-center">
                    <Form.Label className="me-2">
                      Extra Hours: {attendanceData?.Extra_Hours_Performed}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="12" className=" align-items-center">
                    <Form.Label className="me-2">
                      Years in present grade:{" "}
                      {/* {attendanceData?.Years_in_present_grade} */}
                    </Form.Label>
                  </Form.Group>
                </Form>
              </div>
            </div>
          ) : (
            // <div>No data loaded.</div>
            <Spinner animation="border" size={"sm"} role="status" />
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}

// export default Frm_b;
export default React.memo(Frm_b);
