import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
const Swal = require("sweetalert2");

function RecForGrade_frm({
  serviceNo,
  selectedYear,
  selectedPeriod,
  loggedUserSN,
  onDataUpdate,
  currentEvalStatus
}) {
  const [isEngex, setiseEngex] = useState(null);
  const [isDeph, setisDeph] = useState(null);
  const [isdivh, setisdivh] = useState(null);
  const [ispraDivh, setispraDivh] = useState(null);
  const [ismd, setismd] = useState(null);
  const [ispraMD, setispraMD] = useState(null);

  const [prevFormValues, setPrevFormValues] = useState();
  const blockbyEvalStatus = currentEvalStatus === "Authorized";
  console.log("eval :", currentEvalStatus);

  const [formValues, setFormValues] = useState({
    discWithEmp: "",
    recIncrements: "",
    commentsDeph: "",
    engex: "",
    deph: "",
    commentsDivh: "",
    divh: "",
    praDivh: "",
    evalBy: "",
    checkedBy: "",
    approvedBy: "",
    commentsMD: "",
    md: "",
    praMD: "",
  });

  useEffect(() => {
    axios
      .get(
        `Evaluation/GetRecommendationDetails?serviceNo=${serviceNo}&year=${selectedYear}&UserType=Ex&periodType=${selectedPeriod}`
      )
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.ResultSet;

          setFormValues({
            discWithEmp: data?.ComunicationStatus === "Y" ? "Y" : "N",
            recIncrements: data.RecommenedIncrements,
            commentsDeph: data.SpecialComments,
            engex: data.DphpromRecommened === "Y" ? "Y" : "",
            deph: data.SpecialCommentsBy,
            commentsDivh: data.Recommendations,
            divh: data.DihpromotionRecommened === "Y" ? "Y" : "",
            praDivh: data.PromotionRecommened === "Y" ? "Y" : "",
            evalBy:
              data.Status === "E2" || data.Status === "E1"
                ? data.StatusUpdatedBy
                : "",
            checkedBy: data.Status === "E3" ? data.StatusUpdatedBy : "",
            approvedBy: data.Status === "E4" ? data.StatusUpdatedBy : "",
            commentsMD: data.ObservationRemarks,
            md: data.MdpromotionRecommened === "Y" ? "Y" : "",
            praMD: data.ObservationApprovedBy,
          });
        } else {
          console.error("Unexpected response status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [serviceNo, selectedYear, selectedPeriod]);

  useEffect(() => {
    if (JSON.stringify(formValues) !== JSON.stringify(prevFormValues)) {
      onDataUpdate(formValues);
      setPrevFormValues(formValues);
    }
  }, [formValues, onDataUpdate, prevFormValues]);

  const handleFormChange = (event) => {
    const { name, checked, value } = event.target;

    if (name === "engex") {
      setFormValues((prevValues) => ({
        ...prevValues,
        engex: checked ? "Y" : "",
      }));
      setiseEngex(checked);
    } else if (name === "deph") {
      setFormValues((prevValues) => ({
        ...prevValues,
        deph: checked ? "Y" : "",
      }));
      setisDeph(checked);
    } else if (name === "divh") {
      setFormValues((prevValues) => ({
        ...prevValues,
        divh: checked ? "Y" : "",
      }));
      setisdivh(checked);
    } else if (name === "praDivh") {
      setFormValues((prevValues) => ({
        ...prevValues,
        praDivh: checked ? "Y" : "",
      }));
      setispraDivh(checked);
    } else if (name === "praMD") {
      setFormValues((prevValues) => ({
        ...prevValues,
        praMD: checked ? "Y" : "",
      }));
      setispraMD(checked);
    } else if (name === "md") {
      setFormValues((prevValues) => ({
        ...prevValues,
        md: checked ? "Y" : "",
      }));
      setismd(checked);
    } else if (name === "group1") {
      setFormValues((prevValues) => ({ ...prevValues, discWithEmp: value }));
    } else if (name === "recIncrements") {
      if (parseInt(value, 10) > 5) {
        const Toast = Swal.mixin({
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "warning",
          title: "The value should be less than or equal to 5",
        });
      } else {
        setFormValues({ ...formValues, [name]: value });
        localStorage.setItem("RecommendedIncrements", value);
      }
    } else {
      setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    }
  };

  return (
    <Container fluid>
      <Card className="text-black mt-4 mb-4 bg-white">
        <Card body>
          <Row className="mb-3 mt-3">
            <Col md="4">
              <span
                className="text-danger font-weight-bold"
                style={{ fontSize: 20 }}
              >
                *{" "}
              </span>
              <Form.Label>Evaluation discussed with the Employee: </Form.Label>
            </Col>
            <Col md="2" className="mt-2">
              <Form.Check
                inline
                label="Yes"
                name="group1"
                type="radio"
                value="Y"
                checked={formValues.discWithEmp === "Y"}
                onChange={handleFormChange}
                disabled={blockbyEvalStatus}
              />
              <Form.Check
                inline
                label="No"
                name="group1"
                type="radio"
                value="N"
                checked={formValues.discWithEmp === "N"}
                onChange={handleFormChange}
                disabled={blockbyEvalStatus}
              />
            </Col>
          </Row>
          <Row className="mb-3 mt-3">
            <Col md="2" className="pl-4">
              <Form.Label>Recommended Increments :</Form.Label>
              <Form.Control
                type="number"
                name="recIncrements"
                value={formValues.recIncrements}
                onChange={handleFormChange}
                disabled={blockbyEvalStatus}
              />
            </Col>
          </Row>
          <h4 color="initial" className="mt-4">
            Commendation and Recommendation
          </h4>

          {/* Special Comments by Department Head ============ */}
          <Card className="text-black mt-2 mb-4 bg-light">
            <Card.Header>Special Comments by Department Head</Card.Header>
            <Card.Body>
              <Row className="mb-3">
                <Col>
                  <Form.Group
                    className="mb-1 "
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className="mr-2">Comment</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      style={{ width: "100%" }}
                      name="commentsDeph"
                      value={formValues.commentsDeph}
                      onChange={handleFormChange}
                      disabled={blockbyEvalStatus}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Form.Check
                    className="mb-2"
                    type="checkbox"
                    label="Departmental Head"
                    name="deph"
                    checked={formValues.deph == "Y"}
                    onChange={handleFormChange}
                    disabled={blockbyEvalStatus}
                  />
                  <Form.Control
                    type="text"
                    name="deph"
                    value={isDeph ? loggedUserSN : ""}
                    disabled={formValues.deph || blockbyEvalStatus}
                  />
                </Col>
                <Col md="4">
                  <Form.Check
                    className="mb-2"
                    type="checkbox"
                    label="Promotion Recommended"
                    name="engex"
                    id="engex"
                    checked={formValues.engex}
                    onChange={handleFormChange}
                    disabled={blockbyEvalStatus}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {/* Recommendation by Division Head ============ */}
          <Card className="text-black mt-4 mb-4 bg-light">
            <Card.Header>Recommendation by Division Head</Card.Header>
            <Card.Body>
              <Row className="mb-3">
                <Col>
                  <Form.Group
                    className="mb-1 "
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className="mr-2">Comment</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      style={{ width: "100%" }}
                      name="commentsDivh"
                      value={formValues.commentsDivh}
                      onChange={handleFormChange}
                      disabled={blockbyEvalStatus}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="4">
                  <Form.Check
                    className="mb-2"
                    type="checkbox"
                    label="Division Head"
                    name="divh"
                    checked={formValues.divh == "Y"}
                    onChange={handleFormChange}
                    disabled={blockbyEvalStatus}
                  />
                  <Form.Control
                    type="text"
                    name="divh"
                    value={isdivh ? loggedUserSN : ""}
                    disabled={isdivh || blockbyEvalStatus}
                  />
                </Col>
                <Col md="4">
                  <Form.Check
                    className="mb-2"
                    type="checkbox"
                    label="Promotion recommended and approved"
                    name="praDivh"
                    checked={formValues.praDivh == "Y"}
                    onChange={handleFormChange}
                    disabled={blockbyEvalStatus}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {/*  */}
          <Row className="mb-3">
            <Col md="4">
              <Form.Label className="mr-2">Evaluated by</Form.Label>
              <Form.Control
                type="text"
                name="evalBy"
                value={loggedUserSN}
                // value={formValues.evalBy}
                onChange={handleFormChange}
                disabled
              />
            </Col>
            <Col md="4">
              <Form.Label>Checked by</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                // value={formValues.evalBy}
                onChange={handleFormChange}
                disabled
              />
            </Col>
            <Col md="4">
              <Form.Label>Approved by</Form.Label>
              <Form.Control type="text" name="username" disabled />
            </Col>
          </Row>

          {/* Observation and approval of the Managing director ============ */}
          <Card className="text-black mt-4 mb-4 bg-light">
            <Card.Header>
              Observation and approval of the Managing director
            </Card.Header>
            <Card.Body>
              <Row className="mb-3">
                <Col>
                  <Form.Group
                    className="mb-1 "
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className="mr-2">Comment</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      style={{ width: "100%" }}
                      name="commentsMD"
                      value={formValues.commentsMD}
                      onChange={handleFormChange}
                      disabled={blockbyEvalStatus}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="3">
                  <Form.Check
                    className="mb-2"
                    type="checkbox"
                    label="Managing Director"
                    name="md"
                    checked={formValues.md}
                    onChange={handleFormChange}
                    disabled={blockbyEvalStatus}
                  />

                  <Form.Control
                    type="text"
                    name="md"
                    value={ismd ? loggedUserSN : ""}
                    disabled={ismd || blockbyEvalStatus}
                  />
                </Col>
                <Col md="4">
                  <Form.Check
                    className="mb-2"
                    type="checkbox"
                    label="Promotion recommended and approved"
                    name="praMD"
                    checked={formValues.praMD}
                    onChange={handleFormChange}
                    disabled={blockbyEvalStatus}
                  />
                  <Form.Control
                    type="text"
                    name="praMD"
                    value={ispraMD ? loggedUserSN : ""}
                    disabled={ispraMD || blockbyEvalStatus}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Card>
      </Card>
    </Container>
  );
}

export default RecForGrade_frm;
