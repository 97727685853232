import React, { useState, useEffect } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import axios from "axios";
import classNames from "classnames";
import NavBar from "../components/navbar/Navbar";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import ModalComponent from "./modals/EmpDataModal";
import Frm_a from "./Frm_a";
import Frm_b from "./Frm_b";
import Frm_c from "./Frm_c";
import Frm_d from "./Frm_d";
import Frm_e from "./Frm_e";
import RecForGrade_frm from "./RecForGrade_frm";
import Special_evaluation from "./Special_evaluation";
import Increments_frm from "./Increments_frm";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import "./nonexcontent.css";

const styles = {
  customSelect: {
    padding: "5px",
    textAlign: "center",
    border: "0.5px solid #ccc",
    borderRadius: "6px",
    backgroundColor: "white",
  },
};
const Swal = require("sweetalert2");

const now = new Date();
const nowdate = `${now.getFullYear()}-${(now.getMonth() + 1)
  .toString()
  .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}`;

class NonExContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      years: [],
      selectedYear: null,
      isModalLoading: false,
      selectedServiceNo: null,
      selectedEmpName: null,
      evaluationStatus: null,
      workCategory: null,
      workCategoryCode: null,
      EvaluationStatus: null,
      selectedPeriod: 2,
      selectedEvalStatus: null,
      formDataSave: {
        recForGradeData: null,
        specialEvaluationData: null,
        incrementData: null,
        frmEData: null,
      },
    };
  }

  // Callback function to update formDataSave
  onDataUpdate = (updatedData, componentName) => {
    this.setState((prevState) => ({
      formDataSave: {
        ...prevState.formDataSave,
        [componentName]: updatedData,
      },
    }));
    console.log(updatedData);
  };

  componentDidMount() {
    this.fetchYears();
  }

  handleFileUpload = (event) => {
    const files = event.target.files;
  };

  fetchYears = async () => {
    try {
      const apiYear = localStorage.getItem("default_year");
      const years = Array.from({ length: 6 }, (_, index) => apiYear - index);
      this.setState({ years });
    } catch (error) {
      console.error("Error fetching years:", error);
    }
  };

  handleOpenModal = () => {
    this.setState({ isModalLoading: true });

    setTimeout(() => {
      this.setState({ showModal: true, isModalLoading: false });
    }, 2000);
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleYearChange = (event) => {
    const selectedYear = event.target.value;
    this.setState({ selectedYear });
  };

  handleModalSelect = async (serviceNo, name, evaluationStatus) => {
    this.setState({ selectedServiceNo: serviceNo });
    this.setState({ selectedEmpName: name });
    // this.setState({ evaluationStatus: evaluationStatus });

    // Clear the data in the formDataSave object
    this.setState({
      formDataSave: {
        recForGradeData: null,
        specialEvaluationData: null,
        incrementData: null,
        frmEData: null,
      },
    });

    try {
      const response = await axios.get(
        `Evaluation/GetPersonalData?UserType=NonEx&serviceNo=${serviceNo}`
      );

      const workCategory = response.data.ResultSet.WorkCategory;
      const workCategoryCode = response.data.ResultSet.WorkCat;
      const divCode = response.data.ResultSet.DivisionCode;
      const depCode = response.data.ResultSet.DepartmentCode;
      const locCode = response.data.ResultSet.LocationCode;
      const grade = response.data.ResultSet.PresentGrade;
      const empCategory = response.data.ResultSet.Category;
      const PresentGradeCode = response.data.ResultSet.PresentGradeCode;
      const catCode = response.data.ResultSet.Cat;

      this.setState({ workCategoryCode });
      this.setState({ workCategory });
      this.setState({ evaluationStatus });
      this.setState({ divCode });
      this.setState({ depCode });
      this.setState({ locCode });
      this.setState({ grade });
      this.setState({ empCategory });
      this.setState({ PresentGradeCode });
      this.setState({ catCode });

      // Close the modal (if needed)
      this.handleCloseModal();
    } catch (error) {
      console.error("Error fetching personal data:", error);
    }
  };

  handlePeriodChange = (event) => {
    const selectedPeriod = event.target.value === "Mid year" ? 1 : 2;
    this.setState({ selectedPeriod });
  };

  handleEvaluationStatusChange = (event) => {
    const selectedEvalStatus = event.target.value;
    this.setState({ selectedEvalStatus });

    console.log("Selected evaluation status: " + selectedEvalStatus);
  };

  render() {
    const loggedUserSN = localStorage.getItem("loggedUser");

    const {
      years,
      showModal,
      selectedYear,
      isModalLoading,
      selectedServiceNo,
      selectedEmpName,
      workCategory,
      workCategoryCode,
      evaluationStatus,
      selectedEvalStatus,
      selectedPeriod,
      divCode,
      depCode,
      locCode,
      grade,
      empCategory,
      PresentGradeCode,
      catCode,
    } = this.state;

    // console.log("Selected divCode number", divCode);

    const selectedEmployee = selectedServiceNo + " - " + selectedEmpName;

    const empImage = selectedServiceNo
      ? `https://esystems.cdl.lk/backend-Test/PerformanceEvaluation/Evaluation/GetUserImg?serviceNo=${selectedServiceNo}`
      // ? `http://localhost:57587/Evaluation/GetUserImg?serviceNo=${selectedServiceNo}`
      : require("../images/user.png");

    const defaultYear = years.length > 0 ? years[0] : "";

    if (!selectedYear && years.length > 0) {
      const apiYear = years[0];
      this.setState({ selectedYear: apiYear });
    }

    const handleReset = () => {
      Swal.fire({
        title: "Are you sure to reset the data?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "green",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Reset.",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    };

    const saveEvaluateDataNonEx = async () => {
      const SaveEvaluationLogDetails = {
        ServiceNo: selectedServiceNo,
        Year: selectedYear,
        Period: selectedPeriod,
        SpecialCommentsBy: this.state.formDataSave.recForGradeData?.deph !== null
        ? loggedUserSN
        : "",
        EvalutionStatus: selectedEvalStatus,
        Category: workCategoryCode,
      };

      const SaveEmployeeEvaluationDetails = {
        EvalutionStatus: selectedEvalStatus,
        Year: selectedYear,
        Period: selectedPeriod,
        SpecialCommentsBy: this.state.formDataSave.recForGradeData?.deph !== null
        ? loggedUserSN
        : "",
        Category: empCategory,
        ServiceNo: selectedServiceNo,
        Remarks: this.state.formDataSave.recForGradeData?.commentsEngex || null,
        SpecialComments:
          this.state.formDataSave.recForGradeData?.specialCommentsDepHead ||
          null,
        TrainingRecommenedBy: this.state.formDataSave.incrementData?.divHApproval !== null
        ? loggedUserSN
        : "",
        SpiCommentsBy: loggedUserSN,
        SpiApprovedBy: loggedUserSN,
        SpiRecommenedBy: loggedUserSN,
        
        RecommenedBy: this.state.formDataSave.specialEvaluationData?.recDepHeadCom !== null
        ? loggedUserSN
        : "",
        CommentsBy: this.state.formDataSave.specialEvaluationData?.recBy !== null
        ? loggedUserSN
        : "",
        LocationCode: locCode,
        DepartmentCode: depCode,
        DivisionCode: divCode,
        Grade: grade, 
        RevisionNo: 0,
        WorkCategory: workCategoryCode,
        CatCode: workCategoryCode,
        HradIncrements:
          this.state.formDataSave.specialEvaluationData?.numSpecIncre || 0,
        TrainingRequirment:
          this.state.formDataSave.incrementData?.anyOtherTraining || null,
        RetirementIncrements:
          this.state.formDataSave.incrementData?.retirIncre || 0,
        HradRemarks: this.state.formDataSave.incrementData?.remarks || null,
        HradDeductedIncrements:
          this.state.formDataSave.incrementData?.incDeducted || 0,
        RecommenedIncrements:
          this.state.formDataSave.incrementData?.bySec || null,
        SpcommentStatus:
          this.state.formDataSave.specialEvaluationData?.specAddSalary || null,
        SpiApprovedStatus:
          this.state.formDataSave.specialEvaluationData?.approvDivHead || null,
        SpiCommentsStatus:
          this.state.formDataSave.specialEvaluationData?.recDepHead || null,
        ComunicationStatus:
          this.state.formDataSave.recForGradeData?.discWithEmp || null,
        Recommendations:
          this.state.formDataSave.recForGradeData?.recommendationsDivHead ||
          null,
        AchievementsRemarks:
          this.state.formDataSave.specialEvaluationData?.justiComment || null,
        Achievements:
          this.state.formDataSave.specialEvaluationData?.justific || null,
        Others: this.state.formDataSave.specialEvaluationData?.other || null,
        CustomerSatisfaction:
          this.state.formDataSave.specialEvaluationData?.cusSatis || null,
        UniqueFeat: this.state.formDataSave.specialEvaluationData?.uf || null,
        OutstandingContribution:
          this.state.formDataSave.specialEvaluationData?.oc || null,
        Innovation:
          this.state.formDataSave.specialEvaluationData?.innov || null,
        ImportantSuggestion:
          this.state.formDataSave.specialEvaluationData?.is || null,
        CostSaving:
          this.state.formDataSave.specialEvaluationData?.costSav || null,
        CommitmentWork:
          this.state.formDataSave.specialEvaluationData?.gcw || null,
        SpecialIncrements:
          this.state.formDataSave.specialEvaluationData?.numSpecIncre || 0,
        DphpromotionRecommened:
          this.state.formDataSave.recForGradeData?.deph || null,
        EngpromotionRecommened:
          this.state.formDataSave.recForGradeData?.engex || null,
        P_promotion_recommened:
          this.state.formDataSave.recForGradeData?.divh || null,
      };

      const SaveEvaluationDetails = {
        ServiceNo: selectedServiceNo,
        LoggedUser: loggedUserSN,
        Year: selectedYear,
        Period: selectedPeriod,
        MarksArray:
          JSON.stringify(this.state.formDataSave.frmEData?.evaluationMarks) ||
          null,
        EvalutionCategory: workCategoryCode,
        EvalutionStatus: selectedEvalStatus,
      };

      const SaveTrainingNeeds = {
        ServiceNo: selectedServiceNo,
        Year: selectedYear,
        Period: selectedPeriod,
        LocationCode: locCode,
        DepartmentCode: depCode,
        DivisionCode: divCode,
        CourseCodeList: this.state.formDataSave.incrementData?.courses || null,
      };

      if (!selectedEvalStatus) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Evaluation Status is required!.",
        });
        return;
      } else if (selectedEvalStatus == evaluationStatus) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please select the next Evaluation status!.",
        });
        return;
      } else if (!selectedPeriod) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please select the Evaluation Period!.",
        });
        return;
      } else if (
        !SaveEvaluationLogDetails.ServiceNo ||
        !SaveEvaluationLogDetails.Year ||
        !SaveEvaluationLogDetails.Period ||
        !SaveEvaluationLogDetails.SpecialCommentsBy ||
        !SaveEvaluationLogDetails.EvalutionStatus ||
        !SaveEvaluationLogDetails.Category ||
        !SaveEvaluationDetails.MarksArray ||
        !SaveEmployeeEvaluationDetails.ComunicationStatus ||
        !SaveEmployeeEvaluationDetails.SpcommentStatus
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Required values are empty! Please try again. Required Values are highlited with * mark.",
        });
        return;
      }

      // Save starts
      Swal.fire({
        title: "Are you sure to save data?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Save data!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await axios.post(
              "Evaluation/SaveEvaluationLogDetails?UserType=NonEx",
              SaveEvaluationLogDetails
            );

            const response1 = await axios.post(
              "Evaluation/SaveEmployeeEvaluationDetails?UserType=NonEx",
              SaveEmployeeEvaluationDetails
            );

            const response2 = await axios.post(
              "Evaluation/SaveTrainingNeeds?UserType=NonEx",
              SaveTrainingNeeds
            );

            const response3 = await axios.post(
              "Evaluation/SaveEvaluationDetails?UserType=NonEx",
              SaveEvaluationDetails
            );

            console.log("Save 1 Dataaaaaaa", SaveEvaluationLogDetails);
            console.log("Save 2 Data", SaveEmployeeEvaluationDetails);
            console.log("Save 3 Data", SaveTrainingNeeds);
            console.log("Save 4 Data", SaveEvaluationDetails);

            if (
              response.status === 200 &&
              response1.status === 200 &&
              response2.status === 200 &&
              response3.status === 200
            ) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Evaluation Completed Successfully!',
                showConfirmButton: false,
                timer: 1500
              })
              localStorage.removeItem("SpecialIncrement");
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to save evaluation data.",
              });
            }
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Error saving evaluation data",
            });
          }
        }
      });

      console.log("Save 1 Data", SaveEvaluationLogDetails);
      console.log("Save 2 Data", SaveEmployeeEvaluationDetails);
      console.log("Save 3 Data", SaveTrainingNeeds);
      console.log("Save 4 Data", SaveEvaluationDetails);
    };

    return (
      <>
        <Sidebar toggle={this.props.toggle} isOpen={this.props.isOpen} />
        <Container
          fluid
          className={classNames("content", { "is-open": this.props.isOpen })}
        >
          <NavBar toggle={this.props.toggle} />
          <Row>
            <Col sm={4} className="pr-0">
              <Card className="shadow p-0 mt-2 mb-1 bg-white text-dark text-start ">
                <Card.Body>
                  <form className="row g-3">

                    {(selectedServiceNo !== null ||
                      selectedEmpName !== null) && (
                      <div className="col-12 ">
                        <h6 className=" text-primary font-weight-bold">
                          Evaluating Employee:{" "}
                          <span className="text-success">
                            {selectedEmployee}
                          </span>
                        </h6>
                      </div>
                    )}

                    {(evaluationStatus !== null ||
                      selectedEmpName !== null) && (
                      <div className="col-12 pb-2">
                        <h6 className=" text-primary font-weight-bold">
                          Current Evaluation Status -{" "}
                          <span className="text-success">
                            {evaluationStatus}
                          </span>
                        </h6>
                      </div>
                    )}

                    <div className="col-8 p-0">
                      <div className="col-12">
                        <label
                          htmlFor="inputEmail4"
                          className="form-label required font-weight-bold"
                        >
                          Select an Employee:
                        </label>
                        <Button
                          type=""
                          className="btn btn-sm btn-primary ml-2"
                          onClick={this.handleOpenModal}
                        >
                          Click here
                        </Button>
                      </div>

                      <div className="col-12 mt-2">
                        <label
                          htmlFor="inputState"
                          className="form-label required"
                        >
                          Evaluation Status:
                        </label>
                        {/* <select
                          id="inputState"
                          className="form-select ml-2"
                          style={styles.customSelect}
                          onChange={this.handleEvaluationStatusChange}
                        >
                          <option value="" disabled selected>
                            Select an option
                          </option>
                          <option value="E1">Incomplete</option>
                          <option value="E2">Preparation Complete</option>
                          <option value="E3">Checking Complete</option>
                          <option value="E4">Authorized</option>
                          <option value="E5">Accepted by HRD</option>
                          <option value="E6">Returned by HRD</option>
                        </select> */}
                        <select
                          id="inputState"
                          className="form-select ml-2"
                          style={styles.customSelect}
                          onChange={this.handleEvaluationStatusChange}
                          disabled = {evaluationStatus === "Accepted by HRD"}
                        >
                          <option value="" disabled selected>
                            Select an option
                          </option>
                          <option disabled={evaluationStatus === "In Complete" || evaluationStatus === "Preparation Complete" || evaluationStatus === "Checking Complete" || evaluationStatus === "Authorized" || evaluationStatus === "Accepted by HRD" || evaluationStatus === "Returned by HRD"} value="E1">
                            Incomplete
                          </option>
                          <option disabled={evaluationStatus === "Preparation Complete" || evaluationStatus === "Checking Complete" || evaluationStatus === "Authorized" || evaluationStatus === "Accepted by HRD" || evaluationStatus === "Returned by HRD"} value="E2">
                            Preparation Complete
                          </option>
                          <option disabled={evaluationStatus === "Checking Complete" || evaluationStatus === "Authorized" || evaluationStatus === "Accepted by HRD" || evaluationStatus === "Returned by HRD"} value="E3">
                            Checking Complete
                          </option>
                          <option disabled={evaluationStatus === "Authorized" || evaluationStatus === "Accepted by HRD" || evaluationStatus === "Returned by HRD"} value="E4">
                            Authorized
                          </option>
                          <option disabled={evaluationStatus === "Accepted by HRD" || evaluationStatus === "Returned by HRD"} value="E5">
                            Accepted by HRD
                          </option>
                          <option value="E6">Returned by HRD</option>
                        </select>
                      </div>
                      <div className="col-12 mt-2">
                        <label
                          htmlFor="inputState"
                          className="form-label required"
                        >
                          Category:
                        </label>
                        <label htmlFor="inputState" className="form-label">
                          {workCategory}
                        </label>
                      </div>
                    </div>
                    <div className="col-4 p-0">
                      <div className="col-12 pt-2 text-center">
                        <img
                          className="fade-in"
                          src={empImage}
                          style={{ width: "70%" }}
                          alt="User"
                        />
                      </div>
                    </div>

                    <div className="col-5 flex-start">
                      <label
                        htmlFor="inputState"
                        className="form-label required"
                      >
                        Period:
                      </label>
                      <select
                        id="inputState"
                        className="form-select ml-2"
                        style={styles.customSelect}
                        onChange={this.handlePeriodChange}
                        value={selectedPeriod === 1 ? "Mid year" : "Year end"}
                      >
                        <option disabled>Mid year</option>
                        <option>Year end</option>
                      </select>
                    </div>

                    <div className="col-5 flex-start pl-0 ml-0">
                      <label
                        htmlFor="inputState"
                        className="form-label required"
                      >
                        Year:
                      </label>
                      <select
                        id="inputState"
                        className="form-select ml-2"
                        style={styles.customSelect}
                        onChange={this.handleYearChange}
                      >
                        {years.map((year, index) => (
                          <option key={index} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-2"></div>
                    <div className="col-12 pt-2">
                      <div className="uploader-container">
                        <span className="uploader-label">
                          PCR Attachments :{" "}
                        </span>
                        <input
                          type="file"
                          className="uploader-input"
                          multiple
                          onChange={this.handleFileUpload}
                        />
                      </div>
                    </div>

                    <div className="col-12 pt-2">
                      <div className="row  d-flex text-right">
                        <div className="col-12">
                          <Button
                            type=""
                            className="btn  btn-success "
                            onClick={saveEvaluateDataNonEx}
                          >
                            Save Data
                          </Button>
                          <Button
                            type=""
                            className="btn  btn-danger  ml-2"
                            onClick={handleReset}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </Card.Body>
              </Card>

              <Frm_a serviceNo={selectedServiceNo} />
            </Col>
            <Col sm={8} className="pl-0">
              <div
                className="overflow-auto h-100"
                style={{ maxHeight: "calc(100vh - 100px)" }}
              >
                {/* <Frm_a serviceNo={selectedServiceNo} /> */}
                <Frm_b
                  serviceNo={selectedServiceNo}
                  selectedYear={this.state.selectedYear}
                />
                <Frm_c
                  serviceNo={selectedServiceNo}
                  selectedYear={this.state.selectedYear}
                />
                <Frm_d
                  serviceNo={selectedServiceNo}
                  selectedYear={this.state.selectedYear}
                />
                <Frm_e
                  serviceNo={selectedServiceNo}
                  workCategory={this.state.workCategory}
                  selectedYear={this.state.selectedYear}
                  selectedPeriod={this.state.selectedPeriod}
                  selectedEvalStatus={this.state.selectedEvalStatus}
                  currentEvalStatus={this.state.evaluationStatus}
                  onDataUpdate={(data) => this.onDataUpdate(data, "frmEData")}
                />
                {/* <Frm_f /> */}
                <RecForGrade_frm
                  serviceNo={selectedServiceNo}
                  selectedYear={this.state.selectedYear}
                  selectedPeriod={this.state.selectedPeriod}
                  loggedUserSN={loggedUserSN}
                  currentEvalStatus={this.state.evaluationStatus}
                  onDataUpdate={(data) =>
                    this.onDataUpdate(data, "recForGradeData")
                  }
                />
                <Special_evaluation
                  serviceNo={selectedServiceNo}
                  selectedYear={this.state.selectedYear}
                  selectedPeriod={this.state.selectedPeriod}
                  loggedUserSN={loggedUserSN}
                  onDataUpdate={(data) =>
                    this.onDataUpdate(data, "specialEvaluationData")
                  }
                />
                <Increments_frm
                  serviceNo={selectedServiceNo}
                  selectedYear={this.state.selectedYear}
                  selectedPeriod={this.state.selectedPeriod}
                  workCategory={this.state.workCategory}
                  selectedEvalStatus={this.state.selectedEvalStatus}
                  workCategoryCode={this.state.workCategoryCode}
                  currentEvalStatus={this.state.evaluationStatus}
                  onDataUpdate={(data) =>
                    this.onDataUpdate(data, "incrementData")
                  }
                />
              </div>
            </Col>
          </Row>
          {isModalLoading && (
            <Backdrop
              open={true}
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
            >
              <CircularProgress color="inherit" size={50} />
            </Backdrop>
          )}

          {showModal && (
            <ModalComponent
              onClose={this.handleCloseModal}
              selectedYear={this.state.selectedYear}
              onSelect={this.handleModalSelect}
            />
          )}
        </Container>
      </>
    );
  }
}

export default React.memo(NonExContent);
