import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import { MenuItem, Select } from "@mui/material";
import "@mui/system";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import "./modal.css";

const ModalComponent = ({ onClose, selectedYear, onSelect }) => {
  const [divisions, setDivisions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [estatus, setStatus] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedDivision, setSelectedDivision] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [gridData, setGridData] = useState([]);
  const [Incre1Data, setIncre1] = useState([]);
  const [Incre2Data, setIncre2] = useState([]);
  const [Incre3Data, setIncre3] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [locationsLoaded, setLocationsLoaded] = useState(false);
  // const serviceNumber = "0002014";
  const [selectedServiceNo, setSelectedServiceNo] = useState("");
  const [loading, setLoading] = useState({});

  const serviceNumber = localStorage.getItem("loggedUser");


  useEffect(() => {
    try {
      axios
        .get(
          // `https://esystems.cdl.lk/backend-Test/PerformanceEvaluation/Evaluation/getStatus?serviceNo=${serviceNumber}`
          `Evaluation/getStatus?UserType=NonEx&serviceNo=${serviceNumber}`
        )
        .then((response2) => {
          setStatus(
            response2.data.ResultSet.map((item) => ({
              Key: item.Key,
              Value: item.Value,
            }))
          );
          axios
            .get(
              `Evaluation/getDivisions?UserType=NonEx&serviceNo=${serviceNumber}`
            )
            .then((response3) => {
              setDivisions(response3.data.ResultSet);
              setDataLoaded(true);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  // useEffect(() => {
  //   if (selectedDivision && !locationsLoaded && dataLoaded) {
  //     try {
  //       const divisionKey = selectedDivision.split(" ")[0]; // Extract the first word as the key
  //       axios
  //         .get(
  //           `GetLocationsbyDiv?serviceNo=${serviceNumber}&selectedIndex=${divisionKey}`
  //         )
  //         .then((response) => {
  //           setLocations(response.data.ResultSet);
  //         })
  //         .catch((error) => {
  //           console.error("Error fetching locations by division:", error);
  //         });
  //     } catch (error) {
  //       console.error("Error fetching locations:", error);
  //     }
  //   }
  // }, [selectedDivision, locationsLoaded, dataLoaded]);

  useEffect(() => {
    if (!selectedDivision && dataLoaded) {
      try {
        axios
          .get(
            `Evaluation/getLocations?UserType=NonEx&serviceNo=${serviceNumber}`
          )
          .then((response) => {
            setLocations(response.data.ResultSet);
          })
          .catch((error) => {
            console.error("Error fetching locations:", error);
          });
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    } else if (selectedDivision && dataLoaded) {
      try {
        const divisionKey = selectedDivision.split(" ")[0]; // Extract the first word as the key
        axios
          .get(
            `Evaluation/GetLocationsbyDiv?UserType=NonEx&serviceNo=${serviceNumber}&selectedIndex=${divisionKey}`
          )
          .then((response) => {
            setLocations(response.data.ResultSet);
          })
          .catch((error) => {
            console.error("Error fetching locations by division:", error);
          });
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    }
  }, [selectedDivision, dataLoaded]);

  // useEffect(() => {
  //   if (selectedDivision && dataLoaded) {
  //     try {
  //       const divisionKey = selectedDivision.split(" ")[0]; // Extract the first word as the key
  //       axios
  //         .get(
  //           `GetLocationsbyDiv?serviceNo=${serviceNumber}&selectedIndex=${divisionKey}`
  //         )
  //         .then((response) => {
  //           setLocations(response.data.ResultSet);
  //         })
  //         .catch((error) => {
  //           console.error("Error fetching locations by division:", error);
  //         });
  //     } catch (error) {
  //       console.error("Error fetching locations:", error);
  //     }
  //   }
  // }, [selectedDivision, dataLoaded]);

  // useEffect(() => {
  //   if (selectedDivision === "") {
  //     setLocations([]);
  //   } else if (selectedDivision && dataLoaded) {
  //     try {
  //       const divisionKey = selectedDivision.split(" ")[0];
  //       axios
  //         .get(
  //           `GetLocationsbyDiv?serviceNo=${serviceNumber}&selectedIndex=${divisionKey}`
  //         )
  //         .then((response) => {
  //           setLocations(response.data.ResultSet);
  //         })
  //         .catch((error) => {
  //           console.error("Error fetching locations by division:", error);
  //         });
  //     } catch (error) {
  //       console.error("Error fetching locations:", error);
  //     }
  //   }
  // }, [selectedDivision, dataLoaded]);

  useEffect(() => {
    setSelectedLocation("");
  }, [selectedDivision]);

  const handleLoadData = async () => {
    try {
      let response;

      let divisionKey = selectedDivision;
      let locationKey = selectedLocation.split(" ")[0];
      let statusKey = selectedStatus;
      let empName = selectedName;

      // console.log(divisionKey);
      // console.log(locationKey);
      // console.log(statusKey);
      // console.log(empName);
      // if (!divisionKey) divisionKey = "defaultDivision";
      // if (!locationKey) locationKey = "defaultLocation";
      // if (!statusKey) statusKey = "defaultStatus";

      if (!locationKey) {
        alert("Please select a location");
        return;
      }

      // if(empName !== "" && !locationKey || !divisionKey || !statusKey) {
      //   alert("Please select All other 3 values to continue!");
      //   return;
      // }

      if (statusKey !== "" && !locationKey && !divisionKey) {
        alert("Please select a location or division to continue");
        return;
      }

      if (locationKey !== "" && statusKey !== "") {
        response = await axios.get(
          `Evaluation/GetUserGridDetails?UserType=NonEx&location=${locationKey}&status=${statusKey}&year=${selectedYear}&period=2`
        );
      }

      if (locationKey !== "" && divisionKey !== "" && !statusKey) {
        response = await axios.get(
          `Evaluation/GetUserGridDetails?UserType=NonEx&division=${divisionKey}&location=${locationKey}&year=${selectedYear}&period=2`
        );
      }

      if (locationKey !== "" && divisionKey !== "" && statusKey !== "") {
        response = await axios.get(
          `Evaluation/GetUserGridDetails?UserType=NonEx&division=${divisionKey}&location=${locationKey}&year=${selectedYear}&status=${statusKey}&period=2`
        );
      }

      if (
        locationKey !== "" &&
        divisionKey !== "" &&
        statusKey !== "" &&
        empName !== ""
      ) {
        response = await axios.get(
          `Evaluation/GetUserGridDetails?UserType=NonEx&division=${divisionKey}&location=${locationKey}&year=${selectedYear}&name=${empName}&status=${statusKey}&period=2`
        );
      }

      if (locationKey !== "" && !divisionKey && !statusKey) {
        response = await axios.get(
          `Evaluation/GetUserGridDetails?UserType=NonEx&location=${locationKey}&year=${selectedYear}&period=2`
        );
      }

      if (locationKey !== "" && empName !== "" && !divisionKey && !statusKey) {
        response = await axios.get(
          `Evaluation/GetUserGridDetails?UserType=NonEx&location=${locationKey}&year=${selectedYear}&period=2`
        );
      }

      // const response = await axios.get(
      //   `http://172.30.30.90/ev/Evaluation/GetUserGridDetails?division=${divisionKey}&location=${locationKey}&year=${selectedYear}&status=${statusKey}&period=2`
      // );

      const responseData = response.data;
      if (
        responseData &&
        responseData.ResultSet &&
        responseData.ResultSet.UserGridList
      ) {
        setGridData(responseData.ResultSet.UserGridList);
        setIncre1([responseData.ResultSet.TotIncr]);
        setIncre2([responseData.ResultSet.TotSpecIncr]);
        setIncre3([responseData.ResultSet.TotAllocIncr]);
      } else {
        setGridData([]);
        setIncre1([]);
        setIncre2([]);
        setIncre3([]);
        alert("There is no data to display. Please try again!");
      }
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  const handleSelect = (serviceNo, Name, EvaluationStatus) => {
    setLoading({ ...loading, [serviceNo]: true });
    setTimeout(() => {
      setSelectedServiceNo(serviceNo);

      console.log(`Selected service number: ${serviceNo}`);
      console.log(`Selected Name: ${Name}`);
      console.log(`Selected Status: ${EvaluationStatus}`);

      onSelect(serviceNo, Name, EvaluationStatus);
      onClose();

      setLoading({ ...loading, [serviceNo]: false });
    }, 2000);
  };

  const handleClear = () => {
    setSelectedDivision("");
    setSelectedLocation("");
    setSelectedName("");
    setSelectedStatus("");
    setIncre1([""]);
    setIncre2([""]);
    setIncre3([""]);
    setGridData([]);
    setLocationsLoaded(false);
  };

  return (
    <>
      <Modal show onHide={onClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "green" }}>
            {" "}
            <h5>Non Executive Employee Data</h5>{" "}
          </Modal.Title>
        </Modal.Header>
        <div
          style={{
            border: "1px solid #ccc",
            padding: "8px",
          }}
        >
          <Modal.Body>
            {/* <Modal.Title>Search</Modal.Title> */}
            <Form>
              <Row style={{ marginTop: "25px" }} className="mb-1">
                <Col>
                  <Form.Group as={Col} md="12" className="align-items-center">
                    <Form.Label className="me-2">Division</Form.Label>
                    <Select
                      sx={{
                        height: 35,
                        marginLeft: 2,
                        width: "80%",
                        fontSize: "12px",
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      value={selectedDivision}
                      onChange={(event) =>
                        setSelectedDivision(event.target.value)
                      }
                    >
                      {divisions.map((division, index) => (
                        <MenuItem
                          key={index}
                          value={division.Key}
                          style={{ fontSize: "12px" }}
                        >
                          {division.Value}
                        </MenuItem>
                      ))}
                    </Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Col} md="12" className="align-items-center">
                    <Form.Label className="me-2">Location</Form.Label>
                    <Select
                      sx={{
                        height: 35,
                        marginLeft: "28px",
                        width: "80%",
                        fontSize: "12px",
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      value={selectedLocation}
                      onChange={(event) =>
                        setSelectedLocation(event.target.value)
                      }
                    >
                      {locations.map((location, index) => (
                        <MenuItem
                          key={index}
                          value={location}
                          style={{ fontSize: "12px" }}
                        >
                          {location}
                        </MenuItem>
                      ))}
                    </Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row style={{ marginTop: "10px" }}>
                <Col>
                  <Form.Group as={Col} md="12" className="align-items-center">
                    <Form.Label className="me-2">Status</Form.Label>
                    <Select
                      sx={{
                        height: 35,
                        marginLeft: "28px",
                        width: "80%",
                        fontSize: "12px",
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      value={selectedStatus}
                      onChange={(event) =>
                        setSelectedStatus(event.target.value)
                      }
                    >
                      {estatus.map((status, index) => (
                        <MenuItem
                          key={index}
                          value={status.Key}
                          style={{ fontSize: "12px" }}
                        >
                          {status.Value}
                        </MenuItem>
                      ))}
                    </Select>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group
                    as={Col}
                    md="12"
                    className="align-items-center d-flex"
                  >
                    <Form.Label className="me-2">Name</Form.Label>
                    <Form.Control
                      style={{
                        height: 35,
                        marginLeft: "46px",
                        width: "80%",
                        fontSize: "12px",
                        borderRadius:"1px"
                      }}
                      type="text"
                      name="Name"
                      placeholder="Enter the Name"
                      value={selectedName}
                      onChange={(event) => setSelectedName(event.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="justify-content-end pt-3">
                {/* <Button className="btn btn-success mr-2">Load Data</Button> */}
                <Button
                  className="btn btn-sm btn-success mr-2"
                  onClick={handleLoadData}
                  style={{ fontSize: "14px" }}
                  // disabled={
                  //   !selectedDivision || !selectedLocation  || !selectedStatus}
                >
                  Load Data
                </Button>
                <Button
                  className="btn btn-sm btn-danger"
                  onClick={handleClear}
                  style={{ fontSize: "14px" }}
                >
                  Clear
                </Button>
              </Row>

              <Row>
                <Col className="pt-4">
                  <Card className="align-items-center">
                    <Card.Body>
                      <Row>
                        <Col md="12">
                          <div className="d-flex flex-wrap justify-content-between">
                            <Badge className="bg-danger p-2 text-light flex-grow-1 m-2">
                              Pending
                            </Badge>
                            <Badge className="bg-warning p-2 text-dark flex-grow-1 m-2">
                              Incomplete/Preparation complete
                            </Badge>
                            <Badge className="bg-info p-2 text-light flex-grow-1 m-2">
                              Checking complete
                            </Badge>
                            <Badge
                              className="p-2 text-dark  flex-grow-1 m-2"
                              style={{ backgroundColor: "#98FB98" }}
                            >
                              Authorized
                            </Badge>
                            <Badge className="bg-success p-2 text-light flex-grow-1 m-2">
                              Accept By HRD
                            </Badge>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </div>
        {/* <div
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            height: "350px",
            overflow: "auto",
          }}
        >
          <Table bordered responsive>
            <thead style={{ position: "sticky", top: 0, background: "white" }}>
              <tr>
                <th></th>
                <th>Service No</th>
                <th>Name</th>
                <th>Evaluation Status</th>
                <th>Marks</th>
                <th>Promotion</th>
                <th>Incr.</th>
                <th>Sp.Incr</th>
                <th>Ex.Hrs</th>
                <th>Annual</th>
                <th>Casual</th>
                <th>Sick</th>
                <th>Short Leave</th>
                <th>Late</th>
              </tr>
            </thead>
            <tbody style={{ overflow: "auto" }}>
              {gridData.map((user, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{user.ServiceNo}</td>
                  <td>{user.Name}</td>
                  <td>{user.EvaluationStatus}</td>
                  <td>{user.Marks}</td>
                  <td>{user.Promotion}</td>
                  <td>{user.Incr}</td>
                  <td>{user.SpIncr}</td>
                  <td>{user.ExHrs}</td>
                  <td>{user.Annual}</td>
                  <td>{user.Casual}</td>
                  <td>{user.Sick}</td>
                  <td>{user.ShortLeave}</td>
                  <td>{user.Late}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div> */}

        {/* New */}
        <div
          style={{
            borderBottom: "1px solid #ccc",
            // padding: "10px",
            height: "350px",
            overflow: "auto",
          }}
        >
          <table className="table table-bordered rounded" bordered responsive>
            <thead>
              <tr
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "darkcyan",
                  color: "white",
                  textAlign: "center",
                }}
              >
                {/* <th></th> */}
                <th>SERVICE NO</th>
                <th>NAME</th>
                <th>EVALUATION STATUS</th>
                <th>MARKS</th>
                <th>PROMOTION</th>
                <th>INCRE.</th>
                <th>SP. INCRE</th>
                <th>EX. HRS</th>
                <th>ANNUAL</th>
                <th>CASUAL</th>
                <th>SICK</th>
                <th>SHORT LEAVE</th>
                <th>LATE</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody style={{ overflow: "auto", backgroundColor: "#dfe7f5" }}>
              {gridData.length === 0 ? (
                <tr>
                  <td colSpan="14" style={{ textAlign: "center" }}>
                    <h6 className="pt-2 text-danger">
                      Please load employee Data...
                    </h6>
                  </td>
                </tr>
              ) : (
                gridData.map((user, index) => (
                  <tr key={index}>
                    {/* <td>{index + 1}</td> */}
                    <td className="text-center">{user?.ServiceNo}</td>
                    <td className="text-left">{user?.Name}</td>
                    <td
                      className={
                        user?.EvaluationStatus === "Pending"
                          ? "bg-danger text-light text-center"
                          : user?.EvaluationStatus === "In Complete"
                          ? "bg-warning text-center text-light"
                          : user?.EvaluationStatus === "Preparation Complete"
                          ? "bg-warning text-center text-light"
                          : user?.EvaluationStatus === "Checking Complete"
                          ? "bg-info text-center text-light"
                          : user?.EvaluationStatus === "Authorized"
                          ? "text-center text-dark"
                          : user?.EvaluationStatus === "Accepted by HRD"
                          ? "bg-success text-center text-light"
                          : "text-center"
                      }
                      style={
                        user?.EvaluationStatus === "Authorized"
                          ? { backgroundColor: "#98FB98" }
                          : {}
                      }
                    >
                      {user?.EvaluationStatus}
                    </td>
                    <td className="text-right bg-warning">{user?.Marks}</td>
                    <td>{user?.Promotion}</td>
                    <td className="text-right">{user?.Incr}</td>
                    <td className="text-right text-danger">{user?.SpIncr}</td>
                    <td className="text-right">{user?.ExHrs}</td>
                    <td className="text-right">{user?.Annual}</td>
                    <td className="text-right">{user?.Casual}</td>
                    <td className="text-right">{user?.Sick}</td>
                    <td className="text-right">{user?.ShortLeave}</td>
                    <td className="text-right">{user?.Late}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() =>
                          handleSelect(
                            user?.ServiceNo,
                            user?.Name,
                            user?.EvaluationStatus
                          )
                        }
                        disabled={loading[user?.ServiceNo]}
                      >
                        {loading[user?.ServiceNo] ? (
                          <CircularProgress
                            size={18}
                            thickness={4}
                            color="inherit"
                            className="text-center"
                          />
                        ) : (
                          "Select"
                        )}
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {/* <p>Selected Service No: {selectedServiceNo}</p> */}
        </div>

        <Row className="pt-4">
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col>
            <Form.Group as={Col} md="auto" className="align-items-center ">
              <Form.Label className="me-2">Total Increments</Form.Label>
              <Form.Control
                sx={{ height: 35, marginLeft: 2, width: "10px" }}
                type="text"
                name="Name"
                value={Incre1Data[0]}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} md="auto" className="align-items-center ">
              <Form.Label className="me-2">Total Increments</Form.Label>
              <Form.Control
                sx={{ height: 35, marginLeft: 2, width: "10px" }}
                type="text"
                name="Name"
                value={Incre2Data[0]}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} md="auto" className="align-items-center">
              <Form.Label className="me-2">Allocated increments</Form.Label>
              <Form.Control
                sx={{ height: 35, marginLeft: 2, width: "10px" }}
                type="text"
                name="Name"
                value={Incre3Data[0]}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary">Save Changes</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ModalComponent;
