import { Card, Container, Table, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import axios from "axios";

function Frm_c({ serviceNo, selectedYear }) {
  const [commendationData, setcommendationData] = useState(null);

  const now = new Date();
  const nowdate = `${now.getFullYear()}-${(now.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}`;

  const [loading, setLoading] = useState(true); // New state to track loading

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://esystems.cdl.lk/backend-Test/PerformanceEvaluation/Evaluation/GetCommendationData?serviceNo=${serviceNo}&year=${selectedYear}`
  //     );
  //     setcommendationData(response.data.ResultSet);
  //     setLoading(false);
  //     // setTimeout(() => fetchData(), 1000);
  //   } catch (error) {
  //     console.error(error);
  //     setLoading(false);
  //     // setTimeout(() => fetchData(), 1000);
  //   }
  // };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `Evaluation/GetCommendationData?UserType=NonEx&serviceNo=${serviceNo}&year=${selectedYear}`
      );

      if (response.status === 200) {
        const responseData = response.data.ResultSet;
        if (responseData !== null) {
          setcommendationData(responseData);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        console.error(`Request failed with status code ${response.status}`);
        setLoading(false);
        setTimeout(() => fetchData(), 1000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setTimeout(() => fetchData(), 1000); // Retry after 1 second (adjust as needed)
    }
  };

  useEffect(() => {
    if (serviceNo && selectedYear) {
      fetchData();
    }
  }, [serviceNo, selectedYear]);

  // const fetchData = async () => {
  //   if (serviceNo !== null && selectedYear !== null) {
  //     try {
  //       const response = await axios.get(
  //         `https://esystems.cdl.lk/backend-Test/PerformanceEvaluation/Evaluation/GetCommendationData?serviceNo=${serviceNo}&year=${selectedYear}`
  //       );
  //       setcommendationData(response.data.ResultSet);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error(error);
  //       setLoading(false);
  //     }
  //   }
  // };

  // const fetchData = async () => {
  //   if (serviceNo && selectedYear) {
  //     try {
  //       const config = {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       };

  //       const response = await axios.get(
  //         `https://esystems.cdl.lk/backend/PerformanceEvaluation/Evaluation/GetCommendationData?serviceNo=${serviceNo}&year=${selectedYear}`,
  //         config
  //       );

  //       if (response.StatusCode === 200) {
  //         setcommendationData(response.data.ResultSet);
  //         setLoading(false);
  //       } else {
  //         // Handle other StatusCode codes here
  //         console.error(`Request failed with StatusCode`);
  //         setLoading(false);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       setLoading(false);
  //     }
  //   }
  // };

  // // Call fetchData when all three variables are not null
  // if (serviceNo !== null && selectedYear !== null) {
  //   fetchData();
  // }
  return (
    <Container fluid>
      <Card className="text-black mt-4 mb-4">
        <Card.Header  >
          (C). DISCIPLINARY ACTIONS / COMMANDATIONS (FROM : {selectedYear}-01-01
          TO {nowdate})
        </Card.Header>
        {/* {commendationData && ( */}
        {loading ? ( // Show loading spinner while data is being fetched
          <div style={{ color: "darkgray" }} className="p-4">
            Please select an employee
          </div>
        ) : (
          commendationData && (
            <Card.Body>
              <Card.Title>Commendations</Card.Title>

              <Table
                striped
                bordered
                hover
                // style={{
                //   border: "2px solid #ccc",
                // }}
              >
                <thead>
                  <tr className="text-center">
                    <th>#</th>
                    <th>Date</th>
                    <th>Incident</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {commendationData?.CommendationDataGrid1?.length > 0 ? (
                    commendationData?.CommendationDataGrid1?.map(
                      (item, index) => (
                        <tr className="text-center" key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.Date}</td>
                          <td>{item?.Incident}</td>
                          <td>{item?.Action}</td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td
                        style={{ color: "darkgray" }}
                        className="text-center"
                        colSpan="5"
                      >
                        No data found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <Card.Title>Offences</Card.Title>

              <Table
                striped
                bordered
                hover
                // style={{
                //   border: "2px solid #ccc",
                // }}
              >
                <thead>
                  <tr className="text-center">
                    <th>#</th>
                    <th>Date</th>
                    <th>Incident</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {commendationData?.CommendationDataGrid2?.length > 0 ? (
                    commendationData?.CommendationDataGrid2?.map(
                      (item, index) => (
                        <tr className="text-center" key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.Date}</td>
                          <td>{item?.Incident}</td>
                          <td>{item?.Action}</td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td
                        className="text-center"
                        style={{ color: "darkgray" }}
                        colSpan="5"
                      >
                        {/* <Spinner
                          animation="border"
                          size={"sm"}
                          role="status"
                          className="mr-2"
                        ></Spinner>
                        Loading... */}
                        No data found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          )
        )}
      </Card>
    </Container>
  );
}

export default Frm_c;
