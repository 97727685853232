import React, { useEffect, useState } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import axios from "axios";

const CoursesModal = ({
  isOpen,
  onClose,
  serviceNo,
  selectedCourses,
  onSelectedCoursesChange,
  workCategory,
  workCategoryCode,
}) => {
  const [courseData, setCourseData] = useState([]);

  console.log("fgddgfd", workCategoryCode)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          // Replace the URL with your actual endpoint
          `Evaluation/GetCourceGrade?UserType=Ex&workCategory=${workCategoryCode}&searchParam=`
        );

        const data = response.data;

        if (data.StatusCode === 200 && data.ResultSet) {
          setCourseData(data.ResultSet);
        }
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    };

    if (isOpen) {
      fetchData();
    }
  }, [isOpen, serviceNo]);

  // Function to handle row selection
  const handleRowSelect = (index) => {
    const courseName = courseData[index].CourseName;
    const isSelected = selectedCourses.includes(courseName);

    const updatedCourseNames = isSelected
      ? selectedCourses.filter((name) => name !== courseName)
      : [...selectedCourses, courseName];

    onSelectedCoursesChange(updatedCourseNames); // Pass the updated course names to the parent component
  };

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Courses</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
          {courseData.length > 0 ? ( // Conditionally render the table
            <Table bordered hover>
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  background: "#f9f9f9",
                }}
              >
                <tr>
                  <th className="text-center">Action</th>
                  <th className="text-center">Course Name</th>
                  <th className="text-center">Course Grade</th>
                </tr>
              </thead>
              <tbody>
                {courseData.map((course, index) => (
                  <tr
                    key={index}
                    onClick={() => handleRowSelect(index)}
                    style={{
                      backgroundColor: selectedCourses.includes(
                        course.CourseName
                      )
                        ? "lightblue"
                        : "inherit",
                    }}
                  >
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedCourses.includes(course.CourseName)}
                      />
                    </td>
                    <td>{course.CourseName}</td>
                    <td>{course.CourseGrade}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p className="text-danger">Please select an Employee</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CoursesModal;
