// ReactDOM.render(<App />, document.getElementById("root"));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import { AuthProvider } from "./auth/auth";
import { BrowserRouter } from "react-router-dom";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

// axios.defaults.baseURL = "http://172.30.30.90/";
// axios.defaults.baseURL = "http://172.30.30.90/PerformanceEvaluation/";
// axios.defaults.baseURL = "http://localhost:57587/";
axios.defaults.baseURL = "https://esystems.cdl.lk/backend-Test/PerformanceEvaluation/";

// root.render(<App />);
root.render(
   <AuthProvider>
      <App />
    </AuthProvider>
   
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
