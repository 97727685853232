import { Card, Container, Table, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import axios from "axios";

function Frm_c({ serviceNo, selectedYear }) {
  const [commendationData, setcommendationData] = useState(null);

  const now = new Date();
  const nowdate = `${now.getFullYear()}-${(now.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}`;

  const [loading, setLoading] = useState(true); // New state to track loading

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://esystems.cdl.lk/backend-Test/PerformanceEvaluation/Evaluation/GetCriticalIncident?serviceNo=${serviceNo}&year=${selectedYear}`
  //     );
  //     if (response.data.ResultSet !== null) {
  //       setcommendationData(response.data.ResultSet);
  //     }

  //     setLoading(false);
  //     setTimeout(() => fetchData(), 1000);
  //   } catch (error) {
  //     console.error(error);
  //     setLoading(false);
  //     setTimeout(() => fetchData(), 1000);
  //   }
  // };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `Evaluation/GetCriticalIncident?UserType=NonEx&serviceNo=${serviceNo}&year=${selectedYear}`
      );

      if (response.status === 200) {
        const responseData = response.data.ResultSet;
        if (responseData !== null) {
          setcommendationData(responseData);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        console.error(`Request failed with status code ${response.status}`);
        setLoading(false);
        setTimeout(() => fetchData(), 1000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setTimeout(() => fetchData(), 1000); // Retry after 1 second (adjust as needed)
    }
  };

  useEffect(() => {
    if (serviceNo && selectedYear) {
      fetchData();
    }
  }, [serviceNo, selectedYear]);
  return (
    <Container fluid>
      <Card className="text-black mt-4 mb-4">
        <Card.Header  >
          (D). CRITICAL INCIDENTS (FROM : {selectedYear}-01-01 TO {nowdate})
        </Card.Header>
        {/* {commendationData && ( */}
        {loading ? ( // Show loading spinner while data is being fetched
          <div style={{ color: "darkgray" }} className="p-4">
            Please select an employee
          </div>
        ) : (
          commendationData && (
            <Card.Body>
              <Card.Title>Commendations</Card.Title>

              <Table
                striped
                bordered
                hover
                // style={{
                //   border: "2px solid #ccc",
                // }}
              >
                <thead>
                  <tr className="text-center">
                    <th>#</th>
                    <th>Date</th>
                    <th>Evaluation</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {commendationData?.length > 0 ? (
                    commendationData.map((item, index) => (
                      <tr className="text-center" key={index}>
                        <td>{index + 1}</td>
                        <td>{item.Date}</td>
                        <td>{item.Incident}</td>
                        <td>{item.Action}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        style={{ color: "darkgray" }}
                        className="text-center"
                        colSpan="5"
                      >
                        {/* <Spinner
                          animation="border"
                          size={"sm"}
                          role="status"
                          className="mr-2"
                        ></Spinner>
                        Loading... */}
                        No data found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          )
        )}
      </Card>
    </Container>
  );
}

export default Frm_c;
