import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import axios from "axios";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import EvalHistModal from "../non_ex_emp_evaluation/modals/EvalHistModal";

function Special_evaluation({
  serviceNo,
  selectedYear,
  selectedPeriod,
  specialEvalData,
  loggedUserSN,
  onDataUpdate,
  currentEvalStatus,
}) {
  const [evalData, setEvalData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const [selectedHistoryStatus, setSelectedHistoryStatus] = useState(null);
  const [isDeptalHead, setIsDepHead] = useState(null);
  const [isDivHead, setIsDivHead] = useState(null);

  const [prevFormValuesEx, setPrevFormValuesEx] = useState();
  const blockbyEvalStatus = currentEvalStatus === "Authorized";

  const [specialFormValuesEx, setSpecialFormValuesEx] = useState({
    specAddSalary: "",
    numSpecIncre: "",
    justific: "",
    gcw: "",
    costSav: "",
    is: "",
    innov: "",
    oc: "",
    uf: "",
    cusSatis: "",
    other: "",
    otherComment: "",
    recBy: "",
    totalIncrements: "",

    specIncrRecDpH: "",
    specIncrApprovDvH: "",
    specIncrRecDpHSN: "",
    specIncrApprovDvHSN: "",

    recDepHead: "",
    approvDivHead: "",
  });

  useEffect(() => {
    axios
      .get(
        `Evaluation/GetSpecialEvaluationDetails?serviceNo=${serviceNo}&year=${selectedYear}&UserType=Ex&periodType=${selectedPeriod}&historyStatus=`
      )
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.ResultSet;

          setSpecialFormValuesEx({
            justific: data.Achievements,
            otherComment: data.AchievementsRemarks,
            recBy: data.CommentsBy,
            recDepHead: data.RecommenedBy,
            approvDivHead: data.ApprovedBy,

            specAddSalary: data.Sa_si_y_check ? "Y" : "N",
            numSpecIncre: data.Sa_ni_1_check
              ? "1"
              : data.Sa_ni_2_check
              ? "2"
              : "",
            gcw: data.Yjmfu_gctw_check ? "Y" : "N",
            costSav: data.Yjmfu_cs_check ? "Y" : "N",
            is: data.Yjmfu_is_check ? "Y" : "N",
            innov: data.Yjmfu_i_check ? "Y" : "N",
            oc: data.Yjmfu_oc_check ? "Y" : "N",
            uf: data.Yjmfu_uf_check ? "Y" : "N",
            cusSatis: data.Yjmfu_csn_check ? "Y" : "N",
            other: data.Yjmfu_o_check ? "Y" : "N",
            specIncrRecDpH: data.Rdh_sir_check
              ? "Y"
              : data.Rdh_sinr_check
              ? "N"
              : "",
            specIncrApprovDvH: data.Adh_sia_check
              ? "Y"
              : data.Adh_sina_check
              ? "N"
              : "",
              totalIncrements: data.TotalIncrements,
          });
        } else {
          console.error("Unexpected response status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [serviceNo, selectedYear, selectedPeriod]);

  useEffect(() => {
    if (
      JSON.stringify(specialFormValuesEx) !== JSON.stringify(prevFormValuesEx)
    ) {
      onDataUpdate(specialFormValuesEx);
      setPrevFormValuesEx(specialFormValuesEx);
    }
  }, [specialFormValuesEx, onDataUpdate, prevFormValuesEx]);

  const handleSpecialFormChange = (event) => {
    const { name, checked, value } = event.target;

    if (name === "gcw") {
      setSpecialFormValuesEx((prevValues) => ({
        ...prevValues,
        gcw: checked ? "Y" : "",
      }));
    } else if (name === "costSav") {
      setSpecialFormValuesEx((prevValues) => ({
        ...prevValues,
        costSav: checked ? "Y" : "",
      }));
    } else if (name === "is") {
      setSpecialFormValuesEx((prevValues) => ({
        ...prevValues,
        is: checked ? "Y" : "",
      }));
    } else if (name === "innov") {
      setSpecialFormValuesEx((prevValues) => ({
        ...prevValues,
        innov: checked ? "Y" : "",
      }));
    } else if (name === "uf") {
      setSpecialFormValuesEx((prevValues) => ({
        ...prevValues,
        uf: checked ? "Y" : "",
      }));
    } else if (name === "oc") {
      setSpecialFormValuesEx((prevValues) => ({
        ...prevValues,
        oc: checked ? "Y" : "",
      }));
    } else if (name === "cusSatis") {
      setSpecialFormValuesEx((prevValues) => ({
        ...prevValues,
        cusSatis: checked ? "Y" : "",
      }));
    } else if (name === "other") {
      setSpecialFormValuesEx((prevValues) => ({
        ...prevValues,
        other: checked ? "Y" : "",
      }));
    } else if (name === "specIncrRecDpH") {
      setSpecialFormValuesEx((prevValues) => ({
        ...prevValues,
        specIncrRecDpH: value,
      }));
      setIsDepHead(checked);
    } else if (name === "specIncrApprovDvH") {
      setSpecialFormValuesEx((prevValues) => ({
        ...prevValues,
        specIncrApprovDvH: value,
      }));
      setIsDivHead(checked);
    }
    // else if (name === "numSpecIncre" || name === "totalIncrements") {
    //   setSpecialFormValuesEx({ ...specialFormValuesEx, [name]: value });
    //   localStorage.setItem('SpecialIncrements', value);
    // }
    // else if (name === "totalIncrements") {
    //   setSpecialFormValuesEx({ ...specialFormValuesEx, [name]: value });

    //   const RecommendedIncrementsVal = parseInt(localStorage.getItem("RecommendedIncrements")) || 0;
    //   const SpecialIncrementsVal = parseInt(localStorage.getItem("SpecialIncrements")) || 0;
    //   const totalIncrementsCal = RecommendedIncrementsVal + SpecialIncrementsVal;

    //   setSpecialFormValuesEx((prevValues) => ({
    //     ...prevValues,
    //     totalIncrements: totalIncrementsCal.toString(),
    //   }));
    // }
    else {
      setSpecialFormValuesEx((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const RecommendedIncrementsVal =
    parseInt(localStorage.getItem("RecommendedIncrements")) || 0;
  const SpecialIncrementsVal = parseInt(specialFormValuesEx.numSpecIncre) || 0;

  const totalIncrementsCal = RecommendedIncrementsVal + SpecialIncrementsVal;

  const handleHistoryStatusClick = (status) => {
    if (serviceNo === null && selectedYear === null) {
      alert("Please select an employee first");
    } else {
      setSelectedHistoryStatus(status);
    }
  };

  return (
    <Container fluid>
      <Card>
        <Card.Header className="text-center text-white h5 bg-black bg-primary">
          SPECIAL EVALUATION
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={6} md={9}>
              <Card.Text>
                <span
                  className="text-danger font-weight-bold"
                  style={{ fontSize: 20 }}
                >
                  *{" "}
                </span>
                Do you recommend the above employee to be granted with "Special
                Additional" salary increments with regard to any exceptional
                performance / characteristic demonstrated by him/her?
              </Card.Text>
            </Col>
            <Col xs={6} md={3}>
              <Form style={{ marginLeft: "40%" }} className="mt-2">
                <Form.Check
                  inline
                  label="Yes"
                  name="specAddSalary"
                  id="specAddSalary1"
                  type="radio"
                  value="Y"
                  checked={specialFormValuesEx.specAddSalary === "Y"}
                  onChange={handleSpecialFormChange}
                />
                <Form.Check
                  inline
                  label="No"
                  name="specAddSalary"
                  id="specAddSalary2"
                  type="radio"
                  value="N"
                  checked={specialFormValuesEx.specAddSalary === "N"}
                  onChange={handleSpecialFormChange}
                />
              </Form>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={6} md={9}>
              <Card.Text>
                If yes, Number of special additional increments recommended
                (Please tick)
              </Card.Text>
            </Col>
            <Col xs={6} md={3}>
              <Form style={{ marginLeft: "40%" }}>
                <Form.Check
                  inline
                  label="1"
                  name="numSpecIncre"
                  id="numSpecIncre1"
                  type="radio"
                  value="1"
                  checked={specialFormValuesEx.numSpecIncre === "1"}
                  onChange={handleSpecialFormChange}
                />
                <Form.Check
                  inline
                  label="2"
                  name="numSpecIncre"
                  id="numSpecIncre2"
                  type="radio"
                  value="2"
                  checked={specialFormValuesEx.numSpecIncre === "2"}
                  onChange={handleSpecialFormChange}
                />
              </Form>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Card.Title>Justification</Card.Title>
              <Card.Text>(Please justify your recommendation)</Card.Text>
              <Form.Group
                className="mb-3 "
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  as="textarea"
                  value={specialFormValuesEx.justific}
                  name="justific"
                  rows={3}
                  onChange={handleSpecialFormChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Card className="p-3">
            <Card.Title>You justification mainly falls under</Card.Title>
            <Card.Body>
              <Row>
                <Col>
                  <Form>
                    <Form.Check
                      type="checkbox"
                      label="Great Commitment work"
                      className="mb-3"
                      id="check1"
                      name="gcw"
                      checked={specialFormValuesEx.gcw === "Y"}
                      onChange={handleSpecialFormChange}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Cost saving"
                      id="check2"
                      className="mb-3"
                      name="costSav"
                      checked={specialFormValuesEx.costSav === "Y"}
                      onChange={handleSpecialFormChange}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Important Suggestion"
                      className="mb-3"
                      id="check3"
                      name="is"
                      checked={specialFormValuesEx.is === "Y"}
                      onChange={handleSpecialFormChange}
                    />
                    <Form.Check
                      type="checkbox"
                      id="check4"
                      label="Innovation"
                      name="innov"
                      checked={specialFormValuesEx.innov === "Y"}
                      onChange={handleSpecialFormChange}
                    />
                  </Form>
                </Col>
                <Col>
                  <Form>
                    <Form.Check
                      type="checkbox"
                      id="check5"
                      label="Outstanding contribution"
                      className="mb-3"
                      name="oc"
                      checked={specialFormValuesEx.oc === "Y"}
                      onChange={handleSpecialFormChange}
                    />
                    <Form.Check
                      type="checkbox"
                      id="check6"
                      label="Unique feat"
                      className="mb-3"
                      name="uf"
                      checked={specialFormValuesEx.uf === "Y"}
                      onChange={handleSpecialFormChange}
                    />
                    <Form.Check
                      type="checkbox"
                      id="check7"
                      label="Customer satisfaction"
                      className="mb-3"
                      name="cusSatis"
                      checked={specialFormValuesEx.cusSatis === "Y"}
                      onChange={handleSpecialFormChange}
                    />
                    <Form.Check
                      type="checkbox"
                      id="check8"
                      label="Other(Please specify)"
                      name="other"
                      checked={specialFormValuesEx.other === "Y"}
                      onChange={handleSpecialFormChange}
                    />
                  </Form>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea2"
                  >
                    <Form.Control
                      as="textarea"
                      rows={5}
                      name="otherComment"
                      value={specialFormValuesEx.otherComment}
                      onChange={handleSpecialFormChange}
                      disabled={!specialFormValuesEx.other}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {/* recomendation */}
          <Card className="p-3 mt-3">
            <Row>
              <Col sm="5">
                <Form.Label>
                  <strong> Recommended By : </strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="recBy"
                  value={specialFormValuesEx.recBy}
                  // value={loggedUserSN}
                  onChange={handleSpecialFormChange}
                  disabled
                />
              </Col>
              <Col sm="3">
                <Form.Label>
                  <strong> Total Increments : </strong>
                </Form.Label>

                {/* <Form.Control
                  type="number"
                  name="totalIncrements"
                  onChange={handleSpecialFormChange}
                  value={totalIncrementsCal}
                  disabled
                /> */}
                <Form.Control
                  type="number"
                  name="totalIncrements"
                  onChange={handleSpecialFormChange}
                  value={specialFormValuesEx?.totalIncrements ? totalIncrementsCal : ""}
                  // value={totalIncrementsCal}
                  disabled
                />
              </Col>
            </Row>

            {/* Note */}

            <Row style={{ marginTop: "2%", marginLeft: "10%" }}>
              <Col>
                <Form>
                  <Form.Label className="me-2">
                    <strong>Recommendation of Departmental Head</strong>
                  </Form.Label>
                </Form>
              </Col>
              <Col>
                <Form>
                  <Form.Check
                    type="radio"
                    name="specIncrRecDpH"
                    label="Special Increments Recommended"
                    className="mb-3"
                    value="Y"
                    checked={specialFormValuesEx.specIncrRecDpH === "Y"}
                    onChange={handleSpecialFormChange}
                  />
                  <Form.Check
                    type="radio"
                    name="specIncrRecDpH"
                    value=""
                    label="Special Increments Not Recommended"
                    checked={specialFormValuesEx.specIncrRecDpH === ""}
                    onChange={handleSpecialFormChange}
                  />
                </Form>
              </Col>
              <Col>
                <Form.Group
                  className="mb-3 "
                  controlId="exampleForm.ControlTextarea3"
                >
                  <Form.Control
                    as="textarea"
                    rows={2}
                    name="recDepHead"
                    value={
                      isDeptalHead
                        ? loggedUserSN
                        : "" || specialFormValuesEx.recDepHead
                    }
                    disabled={isDeptalHead}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* other one */}
            <Row style={{ marginTop: "2%", marginLeft: "10%" }}>
              <Col>
                <Form>
                  <Form.Label className="me-2">
                    <strong>Approval of Division Head</strong>
                  </Form.Label>
                </Form>
              </Col>
              <Col>
                <Form>
                  <Form.Check
                    type="radio"
                    name="specIncrApprovDvH"
                    label="Special Increments Approved"
                    className="mb-3"
                    value="Y"
                    checked={specialFormValuesEx?.specIncrApprovDvH === "Y"}
                    onChange={handleSpecialFormChange}
                  />
                  <Form.Check
                    type="radio"
                    name="specIncrApprovDvH"
                    value="N"
                    label="Special Increments Not Approved"
                    checked={specialFormValuesEx?.specIncrApprovDvH === ""}
                    onChange={handleSpecialFormChange}
                  />
                </Form>
              </Col>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea4"
                >
                  <Form.Control
                    as="textarea"
                    rows={2}
                    name="approvDivHead"
                    value={
                      isDivHead
                        ? loggedUserSN
                        : "" || specialFormValuesEx?.approvDivHead
                    }
                    disabled={isDivHead}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Card>
        </Card.Body>
      </Card>
      <EvalHistModal
        showModal={showModal}
        toggleModal={toggleModal}
        serviceNo={serviceNo}
        selectedYear={selectedYear}
        selectedPeriod={selectedPeriod}
      />
    </Container>
  );
}

export default Special_evaluation;
