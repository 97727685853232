import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faTimes,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Nav, Button } from "react-bootstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import "../../App.css";
import { useAuth } from "../../auth/auth";

function SideBar(props) {
  const [activeItem, setActiveItem] = useState(
    localStorage.getItem("activeItem") || null
  );
  const auth = useAuth();

  const Swal = require("sweetalert2");

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
    localStorage.setItem("activeItem", itemName);
  };

  const openLogoutDialog = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.isLoading();
        auth.logout();
      }
    });
  };

  return (
    <div
      className={classNames("sidebar", "sticky-left", {
        "is-open": props.isOpen,
      })}
    >
      <div className="sidebar-header">
        <Button
          variant="link"
          onClick={props.toggle}
          style={{ color: "#fff" }}
          className="mt-4"
        >
          <FontAwesomeIcon icon={faTimes} pull="right" size="xs" />
        </Button>
        <h3 className="text-center">
          <img
            src={require("../../images/newlogo4.webp")}
            style={{ width: "70%" }}
            alt="User"
          />
        </h3>
      </div>
      <Nav className="flex-column pt-3">
        <p className="ml-3">EVALUATION SYSTEMS</p>
        <Nav.Item
          className={classNames("p-2", {
            active: activeItem === "executive",
          })}
          onClick={() => handleItemClick("executive")}
        >
          <Link to="/executive" className="ml-4">
            <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
            Executive employee
          </Link>
        </Nav.Item>

        <Nav.Item
          className={classNames("p-2", {
            active: activeItem === "non-executive",
          })}
          onClick={() => handleItemClick("non-executive")}
        >
          <Link to="/non-executive" className="ml-4">
            <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
            Non-executive employee
          </Link>
        </Nav.Item>
        {props.isOpen && (
          <Nav.Item
            className={classNames("text-center is-open", {
              "fade-in": props.isOpen,
            })}
            style={{
              backgroundColor: "rebeccapurple",
              position: "absolute",
              display: "inline-block",
              left: "70px",
              bottom: "30px",
              borderRadius: "10px",
              padding: "0px",
            }}
          >
            <Nav.Link
              onClick={openLogoutDialog}
              style={{ fontWeight: "bolder" }}
            >
              <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
              Logout
            </Nav.Link>
          </Nav.Item>
        )}
      </Nav>
    </div>
  );
}

export default SideBar;
