import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import "./frm_e.css";

function Frm_f({ totalMarks, onFormValuesChange, selectedEvalStatus,
}) {
  const [AttendanceValue, setAttendance] = useState(null);
  const handleAttendance = (value) => {
    setAttendance(value);
  };

  const [PunctualityValue, setPunctualityValue] = useState(null);
  const handlePunctuality = (value) => {
    setPunctualityValue(value);
  };

  const [diciplineValue, setDiciplineValue] =
    useState(null);
  const handleDiciplineButtonClick = (value) => {
    setDiciplineValue(value);
  };

  const isHRD = selectedEvalStatus === "E5";

  const calculateGrandTotalMarks = () => {
    const scores = [
        AttendanceValue,
        PunctualityValue,
        diciplineValue,
    ];
    const totalMarks1 = scores.reduce((total, score) => total + (score || 0), 0);
    return totalMarks1;
  };

  const totalMarks1 = calculateGrandTotalMarks();

  const grandTotalMarks = totalMarks + totalMarks1;

  useEffect(() => {
    // Call the onFormValuesChange function whenever values are updated
    onFormValuesChange(AttendanceValue, PunctualityValue, diciplineValue, grandTotalMarks);
  }, [AttendanceValue, PunctualityValue, diciplineValue, grandTotalMarks]);

  return (
    <Container fluid>
      <Card className="text-black mt-4 mb-4" >
        {/* style={{ background: "#d9edf7" }} */}
        <Card.Header  >
          (F). EVALUATION - PERFORMANCE CRITERIA - To be evaluated by HR Division
        </Card.Header>

        {/* 8 Attendance */}
        <p className="mt-4 p-3">8. ATTENDANCE</p>
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td>Score</td>
              {Array.from({ length: 10 }, (_, index) => (
                // <td className="text-center" key={index}>
                <td
                    className={`text-center ${
                      AttendanceValue === 10 - index
                        ? "selected"
                        : ""
                    }`}
                    key={index}
                  >
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => handleAttendance(10 - index)}
                    disabled={!isHRD}
                  >
                    {10 - index}
                  </Button>
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
        {/* Display selected value for Attendance */}
        <p className="p-3 text-primary text-right">
          Selected Value for Attendance:{" "}
          {AttendanceValue !== null ? (
            <span>{AttendanceValue}</span>
          ) : (
            <span className="text-danger">No value selected.</span>
          )}
        </p>

        {/* 9 Punctuality */}
        <p className="p-3">9. PUNCTUALITY</p>
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td>Score</td>
              {Array.from({ length: 10 }, (_, index) => (
                // <td className="text-center" key={index}>
                <td
                    className={`text-center ${
                      PunctualityValue === 10 - index
                        ? "selected"
                        : ""
                    }`}
                    key={index}
                  >
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => handlePunctuality(10 - index)}
                    disabled={!isHRD}
                  >
                    {10 - index}
                  </Button>
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
        {/* Display selected value for Punctuality */}
        <p className="p-3 text-primary text-right">
          Selected Value Punctuality:{" "}
          {PunctualityValue !== null ? (
            <span>{PunctualityValue}</span>
          ) : (
            <span className="text-danger">No value selected.</span>
          )}
        </p>

        {/* 10. DICIPLINE */}
        <p className="p-3">10. DICIPLINE</p>
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td>Score</td>
              {Array.from({ length: 10 }, (_, index) => (
                // <td className="text-center" key={index}>
                <td
                    className={`text-center ${
                      diciplineValue === 10 - index
                        ? "selected"
                        : ""
                    }`}
                    key={index}
                  >
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() =>
                      handleDiciplineButtonClick(10 - index)
                    }
                    disabled={!isHRD}
                  >
                    {10 - index}
                  </Button>
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
        {/* Display selected value for situational flexibility */}
        <p className="p-3 text-primary text-right">
          Selected Value for Dicipline:{" "}
          {diciplineValue !== null ? (
            <span>{diciplineValue}</span>
          ) : (
            <span className="text-danger">No value selected.</span>
          )}
        </p>

        {/* grand total */}
        <b>
          <h5 className="text-right text-primary mb-3 pr-3">
            GRAND TOTAL [Out of 100]:{grandTotalMarks}
          </h5>
        </b>
      </Card>
    </Container>
  );
}

export default Frm_f;
