import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider, useAuth } from "./auth/auth";
import { RequireAuth } from "./auth/RequireAuth";
import NonExContent from "./non_ex_emp_evaluation/NonExContent";
import ExContent from "./ex_emp_evaluation/ExContent";
import Login from "./components/login/login";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const updateWidth = () => {
    const width = window.innerWidth;
    const widthLimit = 576;
    const isMobile = width <= widthLimit;

    setIsOpen(!isMobile);
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  const { isAuthenticated } = useAuth();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="App wrapper">
      {/* <BrowserRouter>
        <Routes>
          <Route path="/" element={isAuthenticated ? <Navigate to="/executive" /> : <Login />} />
          <Route path="/executive" element={<RequireAuth><ExContent toggle={toggle} isOpen={isOpen} /></RequireAuth>} />
          <Route path="/non-executive" element={<RequireAuth><NonExContent toggle={toggle} isOpen={isOpen} /></RequireAuth>} />
        </Routes>
      </BrowserRouter> */}

      <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route
                path="/executive"
                element={
                  // <ContentTest toggle={this.toggle} isOpen={this.state.isOpen} />
                  <RequireAuth>
                    <ExContent
                      toggle={toggle} isOpen={isOpen}
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="/non-executive"
                element={
                  <RequireAuth>
                    <NonExContent
                      toggle={toggle} isOpen={isOpen}
                    />
                  </RequireAuth>
                }
              />
            </Routes>
          </BrowserRouter>

    </div>
  );
}

// export default function WrappedApp() {
//   return (
//     <AuthProvider>
//       <App />
//     </AuthProvider>
//   );
// }

export default App;