import React, { useEffect, useState } from "react";
import {
  Col,
  Card,
  Row,
  Button,
  Container,
  Table,
  Form,
  Spinner,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import CoursesModal from "./modals/CoursesModal";

function TrainingAttendEx({
  serviceNo,
  selectedYear,
  onDataUpdate,
  selectedPeriod,
  workCategory,
  workCategoryCode,
  loggedUserSN,
  currentEvalStatus,
}) {
  const [courseData1, setCourseData1] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [prevTrainingAValues, setPrevTrainingAValues] = useState();
  const [isdivHApproval, setIsdivHApproval] = useState();
  const blockbyEvalStatus = currentEvalStatus === "Authorized";

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSelectedCoursesChange = (updatedCourseNames) => {
    setSelectedCourses(updatedCourseNames);

    setTrainingAttendEx((prevValues) => ({
      ...prevValues,
      courses: updatedCourseNames,
    }));
  };

  const [trainingAttendEx, setTrainingAttendEx] = useState({
    // firstName: loggedUserSN,
    trainAttend: "",
    courses: [],
    anyOtherTraining: "",
    divHApproval: "",
  });

  useEffect(() => {
    if (
      JSON.stringify(trainingAttendEx) !== JSON.stringify(prevTrainingAValues)
    ) {
      onDataUpdate(trainingAttendEx);
      setPrevTrainingAValues(trainingAttendEx);
    }
  }, [trainingAttendEx, onDataUpdate, prevTrainingAValues]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `Evaluation/GetTrainingAttendData?UserType=Ex&serviceNo=${serviceNo}`
      );

      const responseOther = await axios.get(
        `Evaluation/GetRecommendationDetails?serviceNo=${serviceNo}&year=${selectedYear}&UserType=Ex&periodType=${selectedPeriod}`
      );

      if (response.status === 200) {
        const responseData = response.data.ResultSet;
        if (responseData !== null) {
          setCourseData1(responseData);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else if (response.status === 404) {
        console.error(`Data null with code ${response.status}`);
      } else {
        console.error(`Request failed with status code ${response.status}`);
        setLoading(false);
        // setTimeout(() => fetchData(), 1000);
      }

      if (responseOther.status === 200) {
        const responseDataOther = responseOther.data.ResultSet;

        setTrainingAttendEx({
          anyOtherTraining: responseDataOther.TrainingRequirment,
          divHApproval:
            responseDataOther.TrainingRecommenedBy +
            " - " +
            responseDataOther.TrainingRecommenedName,
        });
      } else if (response.status === 404) {
        console.error(`Data null with code ${response.status}`);
      } else {
        console.error(
          `Request failed with status code ${responseOther.status}`
        );
        setLoading(false);
        // setTimeout(() => fetchData(), 1000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      // setTimeout(() => fetchData(), 1000);
    }
  };

  useEffect(() => {
    if (serviceNo && selectedYear) {
      setLoading(true);
      fetchData();
    }
  }, [serviceNo, selectedYear, selectedPeriod]);

  const handleTrainingAttendEx = (event) => {
    const { name, checked, value } = event.target;
    if (name === "divHApproval") {
      setTrainingAttendEx((prevValues) => ({
        ...prevValues,
        divHApproval: checked ? loggedUserSN : "",
      }));
      setIsdivHApproval(checked);
    } else {
      setTrainingAttendEx((prevValues) => ({
        ...prevValues,
        [name]: value,
        // courses: selectedCourses,
      }));
    }
  };

  return (
    <Container fluid>
      {/* Training Attended */}
      <br></br>
      <Card className="text-black " style={{ background: "white" }}>
        <Row className="pt-4 pl-3 pb-2">
          <Col xs={12} md={9}>
            <Card.Text style={{ fontWeight: "bold" }}>
              Training Attended
            </Card.Text>
            {/* <Form>
              <div className="mb-3">
                <Form.Check
                  inline
                  label="Required"
                  name="trainAttend"
                  value="Y"
                  type="radio"
                  checked={trainingAttendEx.trainAttend === "Y"}
                  onChange={handleTrainingAttendEx}
                />
                <Form.Check
                  inline
                  label="Not Required"
                  name="trainAttend"
                  value="N"
                  type="radio"
                  checked={trainingAttendEx.trainAttend === "N"}
                  onChange={handleTrainingAttendEx}
                />
              </div>
            </Form> */}
          </Col>
        </Row>
        {loading ? (
          <div className="pt-3 pb-3 pl-4" style={{ color: "darkgray" }}>
            Please select an employee to load data
          </div>
        ) : courseData1 ? (
          <div>
            <Table striped bordered hover>
              <thead
                style={{ position: "sticky", top: 0, background: "#f1f1f1" }}
              >
                <tr>
                  <th style={{ width: "14px" }}>#</th>
                  <th style={{ width: "14px" }}>Year</th>
                  <th>Month</th>
                  <th>Course Name</th>
                  <th>Status</th>
                  <th>Skill</th>
                </tr>
              </thead>
            </Table>
            <div style={{ overflowY: "scroll", maxHeight: "400px" }}>
              <Table striped bordered hover style={{ marginBottom: 0 }}>
                <tbody>
                  {courseData1?.map((course, index) => (
                    <tr
                      key={index}
                      style={
                        course.Status === "Not Completed"
                          ? { backgroundColor: "red", color: "white" }
                          : {}
                      }
                    >
                      <td>{index + 1}</td>
                      <td>{course?.Year || "-"}</td>
                      <td>{course?.Month || "-"}</td>
                      <td>{course?.CourseName || "-"}</td>
                      <td>{course?.Status || "-"}</td>
                      <td>{course?.Skill || "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        ) : (
          <Spinner
            animation="border"
            size={"sm"}
            role="status"
            className="mr-2"
          ></Spinner>
        )}
        <Card.Text className="pt-4 pl-3" style={{ fontWeight: "bold" }}>
          Training needs Identification
        </Card.Text>
        <p className="pl-3">Please click the button to select courses</p>
        <Button
          type=""
          className="btn btn-sm btn-primary ml-3 mb-3"
          onClick={handleOpenModal}
          style={{ width: 130 }}
          disabled={blockbyEvalStatus}
        >
          Course details
        </Button>

        {/* {loading ? ( */}
        <Table bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Course Name</th>
            </tr>
          </thead>
          <tbody>
            {selectedCourses === null || selectedCourses.length === 0 ? (
              <tr>
                <td colSpan="2" style={{ color: "darkgray" }} className="pl-4">
                  Please select courses
                </td>
              </tr>
            ) : (
              selectedCourses.map((courseName, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{courseName}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>

        <Row className="p-3">
          <Col>
            <Form.Group className="mb-1 " controlId="Form.ControlTextarea1">
              <Form.Label className="mr-2">
                Any other training requirement
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                style={{ width: "100%" }}
                name="anyOtherTraining"
                value={trainingAttendEx.anyOtherTraining}
                onChange={handleTrainingAttendEx}
                disabled={blockbyEvalStatus}
              />
            </Form.Group>
          </Col>
          {/* <Col>
            <Form.Group className="mb-1 " controlId="Form.ControlTextarea2">
              <Form.Label className="mr-2">
                Approval of Divisional Head
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                style={{ width: "100%" }}
                name="divHApproval"
                value={trainingAttendEx.divHApproval}
                onChange={handleTrainingAttendEx}
                disabled
              />
            </Form.Group>
          </Col> */}
          <Col>
            <Form.Check
              className="mb-2"
              type="checkbox"
              label="Division Head"
              name="divHApproval"
              checked={trainingAttendEx.divHApproval}
              onChange={handleTrainingAttendEx}
              disabled={blockbyEvalStatus}
            />
            <Form.Control
              type="text"
              name="divHApproval"
              value={isdivHApproval ? loggedUserSN : ""}
              disabled={isdivHApproval || blockbyEvalStatus}
              onChange={handleTrainingAttendEx}
            />
          </Col>
        </Row>
      </Card>
      {isModalOpen && (
        <CoursesModal
          serviceNo={serviceNo}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          selectedCourses={selectedCourses}
          workCategory={workCategory}
          workCategoryCode={workCategoryCode}
          onSelectedCoursesChange={handleSelectedCoursesChange}
        />
      )}
    </Container>
  );
}

export default TrainingAttendEx;
