import React, { useEffect, useState } from "react";
import {
  Col,
  Card,
  Row,
  Button,
  Container,
  Table,
  Form,
  Spinner,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import CoursesModal from "./modals/CoursesModal";

function Increments_frm({
  serviceNo,
  selectedYear,
  onDataUpdate,
  workCategory,
  workCategoryCode,
  selectedEvalStatus,
}) {
  const Swal = require("sweetalert2");

  const [courseData1, setCourseData1] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [prevFormValues, setPrevFormValues] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSelectedCoursesChange = (updatedCourseNames) => {
    setSelectedCourses(updatedCourseNames);

    setIncreFrmValues((prevValues) => ({
      ...prevValues,
      courses: updatedCourseNames,
    }));
  };

  const SpecialIncrement = localStorage.getItem("SpecialIncrement");
  const isHRD = selectedEvalStatus === "E5";

  const [increFrmValues, setIncreFrmValues] = useState({
    byHr: "",
    bySec: 0,
    total: 0,
    incDeducted: 0,
    specialIncre: "",
    retirIncre: 0,
    netIncre: 0,
    remarks: "",
    // firstName: loggedUserSN,
    trainAttend: "",
    anyOtherTraining: "",
    divHApproval: "",
    courses: [],
  });

  // const TotalIncrement = increFrmValues?.total;
  // const netIncrement = increFrmValues?.netIncre;

  // // Calculations
  const safeParseInt = (value) => {
    const parsedValue = parseInt(value);
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  increFrmValues.total =
  safeParseInt(increFrmValues.byHr) +
  safeParseInt(SpecialIncrement) +
  safeParseInt(increFrmValues.bySec) +
  safeParseInt(increFrmValues.retirIncre);

increFrmValues.netIncre =
  safeParseInt(increFrmValues.total) - safeParseInt(increFrmValues.incDeducted);

  const handleIncreFormChange = (event) => {
    const { name, value } = event.target;

    if (name === "bySec" && "remarks" && selectedEvalStatus === null) {
      const Toast = Swal.mixin({
        toast: true,
        showConfirmButton: false,
        timer: 1800,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Please select the evaluation status first!",
      });
      return;
    }

    // Check if "By Section" value is greater than 2
    if (name === "bySec" && parseInt(value) > 2) {
      const Toast = Swal.mixin({
        toast: true,
        // position: 'top',
        showConfirmButton: false,
        timer: 1800,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Value cannot be greater than 2!",
      });
      return;
    }

    setIncreFrmValues((prevValues) => ({
      ...prevValues,
      [name]: value,
      courses: selectedCourses,
    }));
    onDataUpdate(increFrmValues);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `Evaluation/GetTrainingAttendData?UserType=NonEx&serviceNo=${serviceNo}`
      );

      if (response.status === 200) {
        const responseData = response.data.ResultSet;
        if (responseData !== null) {
          setCourseData1(responseData);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else if(response.status === 404){
        console.error(`Data null with code ${response.status}`);
      } 
      else {
        console.error(`Request failed with status code ${response.status}`);
        setLoading(false);
        setTimeout(() => fetchData(), 1000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setTimeout(() => fetchData(), 1000);
    }
  };

  // useEffect(() => {
  //   if (serviceNo && selectedYear) {
  //     setLoading(true);

  //     setIncreFrmValues({
  //       byHr: "",
  //       bySec: 0,
  //       total: 0,
  //       incDeducted: 0,
  //       specialIncre: "",
  //       retirIncre: 0,
  //       netIncre: 0,
  //       remarks: "",
  //       trainAttend: "",
  //       anyOtherTraining: "",
  //       divHApproval: "",
  //       courses: [],
  //     });

  //     fetchData();
  //   }
  // }, [serviceNo, selectedYear]);

  useEffect(() => {
    if (JSON.stringify(increFrmValues) !== JSON.stringify(prevFormValues)) {
      onDataUpdate(increFrmValues);
      setPrevFormValues(increFrmValues);
    }
  }, [increFrmValues, onDataUpdate, prevFormValues]);


  useEffect(() => {
    if (serviceNo && selectedYear) {
      setLoading(true);
      fetchData();
    }
  }, [serviceNo, selectedYear]);

  return (
    <Container fluid>
      <Card className="text-black mt-4 mb-4">
        <Card.Header>
          No of Increments allocated as per the evaluation
        </Card.Header>
        <Card body>
          <Row className="mb-3">
            <Col md="2">
              <Form.Label className="mr-2">By HR Division</Form.Label>
              <Form.Control
                type="text"
                name="byHr"
                value={increFrmValues.byHr}
                onChange={handleIncreFormChange}
                disabled={!isHRD}
              />
            </Col>
            <Col md="2">
              <Form.Label>By Section</Form.Label>
              <Form.Control
                type="number"
                name="bySec"
                value={increFrmValues.bySec}
                onChange={handleIncreFormChange}
                // disabled
              />
            </Col>
            <Col md="2">
              <Form.Label>Total</Form.Label>
              <Form.Control
                type="number"
                name="total"
                value={increFrmValues.total}
                onChange={handleIncreFormChange}
                disabled
              />
            </Col>
            <Col md="4">
              <Form.Label className="mr-4">
                Increments deducted on disciplinary grounds
              </Form.Label>
              <Form.Control
                type="number"
                name="incDeducted"
                value={increFrmValues.incDeducted}
                onChange={handleIncreFormChange}
                disabled={!isHRD}
              />
            </Col>
          </Row>

          {/* 2row */}
          <Row className="mb-3">
            <Col md="2">
              <Form.Label className="mr-2" inline>
                Special Increments
              </Form.Label>
              <Form.Control
                type="number"
                name="specialIncre"
                value={SpecialIncrement}
                onChange={handleIncreFormChange}
                disabled
              />
            </Col>
            <Col md="2">
              <Form.Label inline>Retirement increments</Form.Label>
              <Form.Control
                type="number"
                name="retirIncre"
                value={increFrmValues.retirIncre}
                onChange={handleIncreFormChange}
                disabled={!isHRD}
              />
            </Col>
            <Col md="2">
              <Form.Label></Form.Label>
            </Col>
            <Col md="4">
              <Form.Label className="mr-4">Net Increments</Form.Label>
              <Form.Control
                type="number"
                name="netIncre"
                value={increFrmValues.netIncre}
                onChange={handleIncreFormChange}
                disabled
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group
                className="mb-3 "
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className="mr-4">Remarks (HR Division)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="remarks"
                  value={increFrmValues.remarks}
                  onChange={handleIncreFormChange}
                  // disabled={!isHRD}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card>
      </Card>

      {/* Training Attended */}
      <br></br>
      <Card className="text-black " style={{ background: "white" }}>
        <Row className="pt-4 pl-3">
          <Col xs={12} md={9}>
            <Card.Text style={{ fontWeight: "bold" }}>
              Training Attended
            </Card.Text>
            <Form>
              <div className="mb-3">
                <Form.Check
                  inline
                  label="Yes"
                  name="trainAttend"
                  value="Y"
                  type="radio"
                  checked={increFrmValues.trainAttend === "Y"}
                  onChange={handleIncreFormChange}
                />
                <Form.Check
                  inline
                  label="No"
                  name="trainAttend"
                  value="N"
                  type="radio"
                  checked={increFrmValues.trainAttend === "N"}
                  onChange={handleIncreFormChange}
                />
              </div>
            </Form>
          </Col>
        </Row>
        {loading ? (
          <div className="pt-3 pb-3 pl-4" style={{ color: "darkgray" }}>
            Please select an employee to load data
          </div>
        ) : courseData1 ? (
          <div>
            <Table striped bordered hover>
              <thead
                style={{ position: "sticky", top: 0, background: "#f1f1f1" }}
              >
                <tr>
                  <th style={{ width: "14px" }}>#</th>
                  <th style={{ width: "14px" }}>Year</th>
                  <th>Month</th>
                  <th>Course Name</th>
                  <th>Status</th>
                  <th>Skill</th>
                </tr>
              </thead>
            </Table>
            <div style={{ overflowY: "scroll", maxHeight: "400px" }}>
              <Table striped bordered hover style={{ marginBottom: 0 }}>
                <tbody>
                  {courseData1?.map((course, index) => (
                    <tr
                      key={index}
                      style={
                        course.Status === "Not Completed"
                          ? { backgroundColor: "red", color: "white" }
                          : {}
                      }
                    >
                      <td>{index + 1}</td>
                      <td>{course?.Year || "-"}</td>
                      <td>{course?.Month || "-"}</td>
                      <td>{course?.CourseName || "-"}</td>
                      <td>{course?.Status || "-"}</td>
                      <td>{course?.Skill || "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        ) : (
          <Spinner
            animation="border"
            size={"sm"}
            role="status"
            className="mr-2"
          ></Spinner>
        )}
        <Card.Text className="pt-4 pl-3" style={{ fontWeight: "bold" }}>
          Training needs Identification
        </Card.Text>
        <p className="pl-3">Please click the button to select courses</p>
        <Button
          type=""
          className="btn btn-sm btn-primary ml-3 mb-3"
          onClick={handleOpenModal}
          style={{ width: 130 }}
        >
          Course details
        </Button>

        {/* {loading ? ( */}
        <Table bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Course Name</th>
            </tr>
          </thead>
          <tbody>
            {selectedCourses === null || selectedCourses.length === 0 ? (
              <tr>
                <td colSpan="2" style={{ color: "darkgray" }} className="pl-4">
                  Please select courses
                </td>
              </tr>
            ) : (
              selectedCourses.map((courseName, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{courseName}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>

        <Row className="p-3">
          <Col>
            <Form.Group className="mb-1 " controlId="Form.ControlTextarea1">
              <Form.Label className="mr-2">
                Any other training requirement
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                style={{ width: "100%" }}
                name="anyOtherTraining"
                value={increFrmValues.anyOtherTraining}
                onChange={handleIncreFormChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-1 " controlId="Form.ControlTextarea2">
              <Form.Label className="mr-2">
                Approval of Divisional Head
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                style={{ width: "70%" }}
                name="divHApproval"
                value={increFrmValues.divHApproval}
                onChange={handleIncreFormChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </Card>
      {isModalOpen && (
        <CoursesModal
          serviceNo={serviceNo}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          selectedCourses={selectedCourses}
          workCategory={workCategory}
          workCategoryCode={workCategoryCode}
          onSelectedCoursesChange={handleSelectedCoursesChange}
        />
      )}
    </Container>
  );
}

export default Increments_frm;
