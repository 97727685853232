import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Button, Nav, Card } from "react-bootstrap";

class NavBar extends React.Component {
  render() {
    const loggedusername = localStorage.getItem("logged_user_name");

    return (
      <>
        <Navbar
          bg="light"
          className="navbar shadow-sm mb-2 bg-white sticky-top"
          expand
          style={{ borderRadius: 15, border: "none" }}
        >
          <Button variant="outline-primary" onClick={this.props.toggle}>
            <FontAwesomeIcon icon={faAlignLeft} />
          </Button>
          {loggedusername && (
            <div className="pl-3">
              <h6 className="pt-2 text-primary">HELLO {loggedusername}.</h6>
            </div>
          )}

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto" navbar>
              <img
                className="fade-in d-none d-sm-block"
                src={require("../../images/cdplc_logo.png")}
                style={{ width: 180 }}
                alt="Company Logo"
              />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}

export default NavBar;
