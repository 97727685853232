import React, { useContext, createContext, useState, useEffect } from "react";
import axios from "axios";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Check for the token in localStorage and set it in Axios headers
    const authKey = localStorage.getItem("request_token");
    if (authKey) {
      axios.defaults.headers.common["request_token"] = authKey;
    }
  }, []); // Run this effect on component mount

  const login = (username, token) => {
    setUser({ username });

    // Store the token in localStorage
    // localStorage.setItem("request_token", token);

    localStorage.setItem("loggedUser", username);
    localStorage.setItem("activeItem", "executive");
  };

  const logout = () => {
    setUser(null);

    // Remove the token from localStorage
    localStorage.removeItem("request_token");

    // Clear other user data from localStorage if needed
    localStorage.removeItem("loggedUser");
    localStorage.removeItem("activeItem");
    localStorage.removeItem("default_year");
    localStorage.removeItem("SpecialIncrement");
    localStorage.removeItem("logged_user_name");
    localStorage.removeItem("selectedValue");
    localStorage.removeItem("RecommendedIncrements");
    localStorage.removeItem("SpecialIncrements");

    window.location.href = "/";
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
