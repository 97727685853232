import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import axios from "axios";
import Frm_f from "./Frm_f";
import Form from "react-bootstrap/Form";
import "./frm_e.css";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Table1 from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Frm_e({
  serviceNo,
  workCategory,
  selectedYear,
  selectedPeriod,
  onDataUpdate,
  selectedEvalStatus,
  currentEvalStatus,
}) {
  const Swal = require("sweetalert2");
  const [workKnowledgeValue, setWorkKnowledgeValue] = useState(null);
  // const [selectedWorkKnowledgeScore, setSelectedWorkKnowledgeScore] = useState(null);
  const [qualityOfWorkValue, setQualityOfWorkValue] = useState(null);
  const [applicationEfficiencyValue, setApplicationEfficiencyValue] =
    useState(null);
  const [safetyConsciousnessValue, setSafetyConsciousnessValue] =
    useState(null);
  const [initiativeWillingnessValue, setInitiativeWillingnessValue] =
    useState(null);
  const [cooperationTeamworkValue, setCooperationTeamworkValue] =
    useState(null);
  const [situationalFlexibilityValue, setSituationalFlexibilityValue] =
    useState(null);
  const [open, setOpen] = React.useState(false);
  const [marks, setMarks] = useState(null);
  // const [marksTotal, setMarksTotal] = useState(null);

  const [attendanceValue, setAttendanceValue] = useState(null);
  const [punctualityValue, setPunctualityValue] = useState(null);
  const [diciplineValue, setDiciplineValue] = useState(null);
  const [grandTotalMarks, setGrandTotalMarks] = useState(0);

  const blockbyEvalStatus = currentEvalStatus === "Authorized";

  const handleFormValues = (attendance, punctuality, dicipline, grandTotal) => {
    setAttendanceValue(attendance);
    setPunctualityValue(punctuality);
    setDiciplineValue(dicipline);
    setGrandTotalMarks(grandTotal);

    passValuesToParent();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const performanceMarksDesc = [
    {
      rating: "2 Increments",
      details: "Outstanding performance standard",
      range: "Marks 55 or above",
      description:
        "Clearly superior performance. Frequently exceed most position requirments and objectives of work programmes.",
    },
    {
      rating: "1 Increments",
      details: "Meets performace standards",
      range: "40-54",
      description:
        "Reasonable performance. Meets the minimum position requirments and expectations. Objectives of a normal work programme have not been comletely realized.",
    },
    {
      rating: "No Increments",
      details: "Needs improvement",
      range: "30-39",
      description:
        "Corrective action needed. Does not meet minimum requirments in critical aspects of the job and has numerous functional problems critical to job performance.",
    },
    {
      rating: "Consider for remedial action",
      details: "Unsatisfactory",
      range: "Below 30",
      description:
        "Clearly unacceptable.Very poor performance. Placement to another job may have to be considered.if the performace does not improve quickly, the employee may find if dificult to continue in our service.",
    },
  ];

  // After setting all the values, call onDataUpdate
  // const frmeValues = {
  //   wkValue: workKnowledgeValue,
  //   qwValue: qualityOfWorkValue,
  //   aeValue: applicationEfficiencyValue,
  //   scValue: safetyConsciousnessValue,
  //   iwValue: initiativeWillingnessValue,
  //   sfValue: situationalFlexibilityValue,
  //   ctwValue: cooperationTeamworkValue,
  //   atValue: attendanceValue,
  //   puncValue: punctualityValue,
  //   dicipValue: diciplineValue,
  //   gTotalMarks: grandTotalMarks,
  // };
  // onDataUpdate(frmeValues);

  // const dataUpdate = () => {
  //   onDataUpdate(frmeValues);
  // };

  // if (frmeValues !== null) {
  // console.log(frmeValues);
  // }

  useEffect(() => {
    // if ((!serviceNo && !selectedYear && !selectedPeriod)) {
    axios
      .get(
        `Evaluation/GetEvaluationMarks?UserType=NonEx&serviceNo=${serviceNo}&year=${selectedYear}&periodType=${selectedPeriod}`
      )
      .then((response) => {
        // setData(response.data.ResultSet);
        setMarks(response?.data?.ResultSet?.MarksList);
        // setMarksTotal(response.data.ResultSet.TotalMarks);

        if (response.data.ResultSet.MarksList !== null) {
          const safetyConsciousnessMark =
            response.data.ResultSet.MarksList.find(
              (mark) => mark.EvalutionCode === "Safety Consciousness Marks"
            );
          const initiativeMark = response.data.ResultSet.MarksList.find(
            (mark) => mark.EvalutionCode === "Initiative Marks"
          );
          const cooperationTeamworkMark =
            response.data.ResultSet.MarksList.find(
              (mark) => mark.EvalutionCode === "Cooperation Marks"
            );
          const situationalFlexibilityMark =
            response.data.ResultSet.MarksList.find(
              (mark) => mark.EvalutionCode === "Situational Marks"
            );
          const workKnowledgeMark = response.data.ResultSet.MarksList.find(
            (mark) => mark.EvalutionCode === "Work Knowledge Marks"
          );
          const qualityOfWorkMark = response.data.ResultSet.MarksList.find(
            (mark) => mark.EvalutionCode === "Quality Of Works Marks"
          );
          const applicationEfficiencyMark =
            response.data.ResultSet.MarksList.find(
              (mark) =>
                mark.EvalutionCode === "Application and Efficiency Marks"
            );

          if (safetyConsciousnessMark) {
            setSafetyConsciousnessValue(
              Number(safetyConsciousnessMark.EvalutionMarks)
            );
          }
          else {
            setSafetyConsciousnessValue(null);
          }


          if (initiativeMark) {
            setInitiativeWillingnessValue(
              Number(initiativeMark.EvalutionMarks)
            );
          }
          else {
            setInitiativeWillingnessValue(null);
          }

          if (cooperationTeamworkMark) {
            setCooperationTeamworkValue(
              Number(cooperationTeamworkMark.EvalutionMarks)
            );
          }
          else {
            setCooperationTeamworkValue(null);
          }

          if (situationalFlexibilityMark) {
            setSituationalFlexibilityValue(
              Number(situationalFlexibilityMark.EvalutionMarks)
            );
          }
          else {
            setSituationalFlexibilityValue(null);
          }

          if (workKnowledgeMark) {
            setWorkKnowledgeValue(Number(workKnowledgeMark.EvalutionMarks));
          }
          else {
            setWorkKnowledgeValue(null);
          }

          if (qualityOfWorkMark) {
            setQualityOfWorkValue(Number(qualityOfWorkMark.EvalutionMarks));
          }
          else {
            setQualityOfWorkValue(null);
          }

          if (applicationEfficiencyMark) {
            setApplicationEfficiencyValue(
              Number(applicationEfficiencyMark.EvalutionMarks)
            );
          }
          else {
            setApplicationEfficiencyValue(null);
          }
        }
        // onDataUpdate(frmeValues);
      })
      .catch((error) => {
        console.error(error);
      });
    // }
  }, [serviceNo, selectedYear, selectedPeriod]);

  const passValuesToParent = () => {
    const evaluationMarks = [];

    if (workCategory === "Supervisory") {
      evaluationMarks.push({
        EvalutionCode: 4,
        EvalutionMarks: workKnowledgeValue,
      });
      evaluationMarks.push({
        EvalutionCode: 5,
        EvalutionMarks: qualityOfWorkValue,
      });
      evaluationMarks.push({
        EvalutionCode: 6,
        EvalutionMarks: applicationEfficiencyValue,
      });

      evaluationMarks.push({
        EvalutionCode: 11,
        EvalutionMarks: safetyConsciousnessValue,
      });
      evaluationMarks.push({
        EvalutionCode: 12,
        EvalutionMarks: initiativeWillingnessValue,
      });
      evaluationMarks.push({
        EvalutionCode: 13,
        EvalutionMarks: cooperationTeamworkValue,
      });
      evaluationMarks.push({
        EvalutionCode: 17,
        EvalutionMarks: situationalFlexibilityValue,
      });
      if (attendanceValue !== null) {
        evaluationMarks.push({
          EvalutionCode: 1,
          EvalutionMarks: attendanceValue,
        });
      }
      if (punctualityValue !== null) {
        evaluationMarks.push({
          EvalutionCode: 2,
          EvalutionMarks: punctualityValue,
        });
      }
      if (diciplineValue !== null) {
        evaluationMarks.push({
          EvalutionCode: 3,
          EvalutionMarks: diciplineValue,
        });
      }
    }

    if (workCategory === "Clerical") {
      evaluationMarks.push({
        EvalutionCode: 4,
        EvalutionMarks: workKnowledgeValue,
      });
      evaluationMarks.push({
        EvalutionCode: 5,
        EvalutionMarks: qualityOfWorkValue,
      });
      evaluationMarks.push({
        EvalutionCode: 6,
        EvalutionMarks: applicationEfficiencyValue,
      });
      evaluationMarks.push({
        EvalutionCode: 14,
        EvalutionMarks: safetyConsciousnessValue,
      });
      evaluationMarks.push({
        EvalutionCode: 15,
        EvalutionMarks: initiativeWillingnessValue,
      });
      evaluationMarks.push({
        EvalutionCode: 16,
        EvalutionMarks: cooperationTeamworkValue,
      });
      evaluationMarks.push({
        EvalutionCode: 17,
        EvalutionMarks: situationalFlexibilityValue,
      });
      if (attendanceValue !== null) {
        evaluationMarks.push({
          EvalutionCode: 1,
          EvalutionMarks: attendanceValue,
        });
      }
      if (punctualityValue !== null) {
        evaluationMarks.push({
          EvalutionCode: 2,
          EvalutionMarks: punctualityValue,
        });
      }
      if (diciplineValue !== null) {
        evaluationMarks.push({
          EvalutionCode: 3,
          EvalutionMarks: diciplineValue,
        });
      }
    }

    if (workCategory === "Industrial") {
      evaluationMarks.push({
        EvalutionCode: 4,
        EvalutionMarks: workKnowledgeValue,
      });
      evaluationMarks.push({
        EvalutionCode: 5,
        EvalutionMarks: qualityOfWorkValue,
      });
      evaluationMarks.push({
        EvalutionCode: 6,
        EvalutionMarks: applicationEfficiencyValue,
      });
      evaluationMarks.push({
        EvalutionCode: 7,
        EvalutionMarks: safetyConsciousnessValue,
      });
      evaluationMarks.push({
        EvalutionCode: 8,
        EvalutionMarks: initiativeWillingnessValue,
      });
      evaluationMarks.push({
        EvalutionCode: 9,
        EvalutionMarks: cooperationTeamworkValue,
      });
      evaluationMarks.push({
        EvalutionCode: 17,
        EvalutionMarks: situationalFlexibilityValue,
      });
      if (attendanceValue !== null) {
        evaluationMarks.push({
          EvalutionCode: 1,
          EvalutionMarks: attendanceValue,
        });
      }
      if (punctualityValue !== null) {
        evaluationMarks.push({
          EvalutionCode: 2,
          EvalutionMarks: punctualityValue,
        });
      }
      if (diciplineValue !== null) {
        evaluationMarks.push({
          EvalutionCode: 3,
          EvalutionMarks: diciplineValue,
        });
      }
    }

    const values = {
      evaluationMarks,
      grandTotalMarks,
    };

    onDataUpdate(values);
  };

  function alertpopup() {
    const Toast = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 1800,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "error",
      title: "Please select an Employee first!",
    });
  }

  const handleWorkKnowledgeButtonClick = (value) => {
    if (!workCategory) {
      alertpopup();
    } else {
      setWorkKnowledgeValue(value);
    }

    // setSelectedWorkKnowledgeScore(value);
  };

  const handleQualityOfWorkButtonClick = (value) => {
    if (!workCategory) {
      alertpopup();
    } else {
      setQualityOfWorkValue(value);
    }
  };

  const handleApplicationEfficiencyButtonClick = (value) => {
    if (!workCategory) {
      alertpopup();
    } else {
      setApplicationEfficiencyValue(value);
    }
  };

  const handleSafetyConsciousnessButtonClick = (value) => {
    if (!workCategory) {
      alertpopup();
    } else {
      setSafetyConsciousnessValue(value);
    }
  };

  const handleInitiativeWillingnessButtonClick = (value) => {
    if (!workCategory) {
      alertpopup();
    } else {
      setInitiativeWillingnessValue(value);
    }
  };

  const handleCooperationTeamworkButtonClick = (value) => {
    if (!workCategory) {
      alertpopup();
    } else {
      setCooperationTeamworkValue(value);
    }
  };

  const handleSituationalFlexibilityButtonClick = (value) => {
    if (!workCategory) {
      alertpopup();
    } else {
      setSituationalFlexibilityValue(value);
    }
  };

  const calculateTotalMarks = () => {
    const scores = [
      workKnowledgeValue,
      qualityOfWorkValue,
      applicationEfficiencyValue,
      safetyConsciousnessValue,
      initiativeWillingnessValue,
      cooperationTeamworkValue,
      situationalFlexibilityValue,
    ];
    const totalMarks = scores.reduce((total, score) => total + (score || 0), 0);
    return totalMarks;
  };

  const totalMarks = calculateTotalMarks();

  return (
    <>
      <Container fluid>
        <Card className="text-black mt-4 mb-4">
          <Card.Header>
            <div className="row">
              <div className="col-8 text-left pt-1 ">
                <span
                  className="text-danger font-weight-bold"
                  style={{ fontSize: 20 }}
                >
                  *{" "}
                </span>
                (E). EVALUATION - PERFORMANCE CRITERIA (To be evaluated by the
                Section)
              </div>
              <div className="col-4 text-right mt-2">
                <Button
                  className="btn btn-sm btn-primary"
                  onClick={handleClickOpen}
                >
                  Increment Allocation
                </Button>
              </div>
            </div>
          </Card.Header>

          {/* 1. WORK KNOWLEDGE */}
          <p className="mt-4 p-3">1. WORK KNOWLEDGE</p>
          <Table striped bordered hover>
            <thead>
              <tr style={{ fontWeight: "normal" }}>
                {/* <th>#</th> */}
                {workCategory === "Supervisory" ||
                workCategory === "Clerical" ||
                workCategory === "Industrial" ? (
                  <>
                    <th className="text-center" colSpan={2}>
                      Excellent understanding of his job. Possesses multi
                      skills.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Good knowledge of all phases of work.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Adequately informed on related phases of job.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Has only a limited knowledge on some routine aspects of
                      the job.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Serious gaps in his knowledge on essentials of job.
                    </th>
                  </>
                ) : (
                  // Handle other cases or render default elements if needed
                  <th
                    style={{ color: "darkgray" }}
                    colSpan={12}
                    className="text-center"
                  >
                    Please select an Employee
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* <td>Marks</td> */}
                {Array.from({ length: 10 }, (_, index) => (
                  <td
                    className={`text-center ${workKnowledgeValue === 10 - index ? "selected" : ""}`}
                    key={index}
                  >
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => handleWorkKnowledgeButtonClick(10 - index)}
                      disabled = {blockbyEvalStatus}
                    >
                      {10 - index}
                    </Button>
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
          {/* Display selected value for work knowledge */}
          <p className="p-3 text-primary text-right">
            Selected Value for Work Knowledge:{" "}
            {workKnowledgeValue !== null ? (
              <span>{workKnowledgeValue}</span>
            ) : (
              <span className="text-danger">No value selected.</span>
            )}
          </p>

          {/* 2. QUALITY OF WORK */}
          <p className="p-3">2. QUALITY OF WORK</p>
          <Table striped bordered hover>
            <thead>
              <tr>
                {/* <th>#</th> */}
                {workCategory === "Supervisory" ? (
                  <>
                    <th className="text-center" colSpan={2}>
                      Consistently high quality worker. Imaginative. Very
                      systematic & organized. High commitment for work.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Systematic & organized. Needs few corrections in his
                      handling of independent work.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Average and needs guidance to improve. Shows some
                      commitment at times.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Has only a limited knowledge on some routine aspects of
                      the job.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Work characterized by carelessness. Haphazard worker. No
                      commitment for work at all.
                    </th>
                  </>
                ) : workCategory === "Clerical" ||
                  workCategory === "Industrial" ? (
                  <>
                    <th className="text-center" colSpan={2}>
                      Consistently high quality worker. Imaginative. Very
                      systematic & organized. High commitment for work.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Systematic & organized. Needs few corrections in his
                      handling of independent work.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Average and needs guidance to improve. Shows some
                      commitment at times.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Careless. But trying to improve. Lacks commitment to work.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Work characterized by carelessness. Haphazard worker. No
                      commitment for work at all.
                    </th>
                  </>
                ) : (
                  // Handle other cases or render default elements if needed
                  <th
                    style={{ color: "darkgray" }}
                    colSpan={12}
                    className="text-center"
                  >
                    Please select an Employee
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* <td>Marks</td> */}
                {Array.from({ length: 10 }, (_, index) => (
                  <td
                    className={`text-center ${
                      qualityOfWorkValue === 10 - index ? "selected" : ""
                    }`}
                    key={index}
                  >
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => handleQualityOfWorkButtonClick(10 - index)}
                      disabled = {blockbyEvalStatus}
                    >
                      {10 - index}
                    </Button>
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
          {/* Display selected value for quality of work */}
          <p className="p-3 text-primary text-right">
            Selected Value for Quality of Work:{" "}
            {qualityOfWorkValue !== null ? (
              <span>{qualityOfWorkValue}</span>
            ) : (
              <span className="text-danger">No value selected.</span>
            )}
          </p>

          {/* 3. APPLICATION AND EFFICIENCY */}
          <p className="p-3">3. APPLICATION AND EFFICIENCY</p>
          <Table striped bordered hover>
            <thead>
              <tr>
                {/* <th>#</th> */}
                {/* <th className="text-center" colSpan={2}>
                  Consistently hard worker. Excellent efficiency. Handles more
                  than his share.
                </th>
                <th className="text-center" colSpan={2}>
                  Meets the requirement on time. Generally makes a good effort.
                  Hard worker
                </th>
                <th className="text-center" colSpan={2}>
                  Handles his share of work adequately. Average level of
                  efficiency.
                </th>
                <th className="text-center" colSpan={2}>
                  Makes an average effort. Occasionally needs reminding about
                  time wasting.
                </th>
                <th className="text-center" colSpan={2}>
                  Makes no great effort when working. Inefficient. Tends to be
                  wasteful.
                </th> */}
                {workCategory === "Supervisory" ||
                workCategory === "Clerical" ||
                workCategory === "Industrial" ? (
                  <>
                    <th className="text-center" colSpan={2}>
                      Consistently hard worker. Excellent efficiency. Handles
                      more than his share.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Meets the requirement on time. Generally makes a good
                      effort. Hard worker
                    </th>
                    <th className="text-center" colSpan={2}>
                      Handles his share of work adequately. Average level of
                      efficiency.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Makes an average effort. Occasionally needs reminding
                      about time wasting.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Makes no great effort when working. Inefficient. Tends to
                      be wasteful.
                    </th>
                  </>
                ) : (
                  // Handle other cases or render default elements if needed
                  <th
                    style={{ color: "darkgray" }}
                    colSpan={12}
                    className="text-center"
                  >
                    Please select an Employee
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* <td>Marks</td> */}
                {Array.from({ length: 10 }, (_, index) => (
                  <td
                    className={`text-center ${
                      applicationEfficiencyValue === 10 - index
                        ? "selected"
                        : ""
                    }`}
                    key={index}
                  >
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() =>
                        handleApplicationEfficiencyButtonClick(10 - index)
                      }
                      disabled = {blockbyEvalStatus}
                    >
                      {10 - index}
                    </Button>
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
          {/* Display selected value for application and efficiency */}
          <p className="p-3 text-primary text-right">
            Selected Value for Application and Efficiency:{" "}
            {applicationEfficiencyValue !== null ? (
              <span>{applicationEfficiencyValue}</span>
            ) : (
              <span className="text-danger">No value selected.</span>
            )}
          </p>

          {/* 4. SAFETY CONSCIOUSNESS AND CARE AND USE OF COMPANY PROPERTY */}
          <p className="p-3">
            4. SAFETY CONSCIOUSNESS AND CARE AND USE OF COMPANY PROPERTY
          </p>
          <Table striped bordered hover>
            <thead>
              <tr>
                {/* <th>#</th> */}

                {workCategory === "Clerical" ? (
                  <>
                    <th className="text-center" colSpan={2}>
                      Excellent presentation. High level of speed & accuracy.
                      Exceptional understanding & implementation.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Skillful & effective in written communication. Good
                      presentation. Work is precise & informative.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Expression is generally clear & concise on paper. Average
                      speed and accuracy of work. Generally understands
                      instructions.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Some ability in written communication. Needs to improve.
                      Occasionally fails to understand what is required.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Lack of written communication skills. Work rather obscure.
                      Slow & always make mistakes. Poor understanding &
                      implementation of instructions.
                    </th>
                  </>
                ) : workCategory === "Supervisory" ||
                  workCategory === "Industrial" ? (
                  <>
                    <th className="text-center" colSpan={2}>
                      Consistently adheres to Company safety policies. Very
                      conscious of upkeep of equipment & work Place.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Good knowledge of safety procedures. Maintain his
                      equipment & work place well.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Average knowledge of safety procedures Generally cares for
                      tools & equipment.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Has to be reminded of work safety . Careless about
                      equipment & work place tidiness.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Lack of basic safety knowledge. Want only abuses his
                      equipment . Extremely untidy work place.
                    </th>
                  </>
                ) : (
                  // Handle other cases or render default elements if needed
                  <th
                    style={{ color: "darkgray" }}
                    colSpan={12}
                    className="text-center"
                  >
                    Please select an Employee
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* <td>Marks</td> */}
                {Array.from({ length: 10 }, (_, index) => (
                  <td
                    className={`text-center ${
                      safetyConsciousnessValue === 10 - index ? "selected" : ""
                    }`}
                    key={index}
                  >
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() =>
                        handleSafetyConsciousnessButtonClick(10 - index)
                      }
                      disabled = {blockbyEvalStatus}
                    >
                      {10 - index}
                    </Button>
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
          {/* Display selected value for safety consciousness */}
          <p className="p-3 text-primary text-right">
            Selected Value for Safety Consciousness:{" "}
            {safetyConsciousnessValue !== null ? (
              <span>{safetyConsciousnessValue}</span>
            ) : (
              <span className="text-danger">No value selected.</span>
            )}
          </p>

          {/* 5. INITIATIVE WILLINGNESS */}
          <p className="p-3">5. INITIATIVE WILLINGNESS</p>
          <Table striped bordered hover>
            <thead>
              <tr>
                {/* <th>#</th> */}
                {workCategory === "Supervisory" ? (
                  <>
                    <th className="text-center" colSpan={2}>
                      Extremely successful in maintaining high worker morale.
                      Obtains high output. Inspires & controls his team well.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Maintains good team spirit & morale. Gains good support.
                      Tactful & assertive. Provides direction to his team.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Maintains a normal level of morale. Obtains limited
                      output. Earns an average level of respect from the group.
                      Sometimes lacks the adequate control.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Gets low level of results. Should enlist more support from
                      his group. Always needs help from superiors in
                      controlling.
                    </th>
                    <th className="text-center" colSpan={2}>
                      No real leadership qualities. Poor planning, Organizing &
                      controlling with very poor results.
                    </th>
                  </>
                ) : workCategory === "Clerical" ? (
                  <>
                    <th className="text-center" colSpan={2}>
                      Excellent general intelligence & learning ability. High
                      aptitude towards work. Innovative. Has constructive power
                      & analytical skills.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Good general intelligence. Creative. Quick to learn new
                      facets of the jobs.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Average intelligence & aptitude towards work. Maintains
                      the minimum required standard of competence.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Low interest to update skills. Lacks creativity. Needs to
                      improve on constructive power.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Use obsolete methods. Resents new improvements in work
                      environment. Poor general intelligence. Lacks analytical
                      skills.
                    </th>
                  </>
                ) : workCategory === "Industrial" ? (
                  <>
                    <th className="text-center" colSpan={2}>
                      Enthusiastic keen interest to complete work to the extreme
                      satisfaction of superiors. Perform extra work.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Good interest in job & for extra work. Reliable worker.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Average interest in job. Takes extra work only when
                      insisted.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Low interest in job. Reluctant to perform extra work.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Not interested in hard work or making improvements. Never
                      acts unless instructed.
                    </th>
                  </>
                ) : (
                  // Handle other cases or render default elements if needed
                  <th
                    style={{ color: "darkgray" }}
                    colSpan={12}
                    className="text-center"
                  >
                    Please select an Employee
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* <td>Marks</td> */}
                {Array.from({ length: 10 }, (_, index) => (
                  // <td className="text-center" key={index}>
                  <td
                    className={`text-center ${
                      initiativeWillingnessValue === 10 - index
                        ? "selected"
                        : ""
                    }`}
                    key={index}
                  >
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() =>
                        handleInitiativeWillingnessButtonClick(10 - index)
                      }
                      disabled = {blockbyEvalStatus}
                    >
                      {10 - index}
                    </Button>
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
          {/* Display selected value for initiative and willingness */}
          <p className="p-3 text-primary text-right">
            Selected Value for Initiative and Willingness:{" "}
            {initiativeWillingnessValue !== null ? (
              <span>{initiativeWillingnessValue}</span>
            ) : (
              <span className="text-danger">No value selected.</span>
            )}
          </p>

          {/* 6. CO-OPERATION AND TEAM WORK */}
          <p className="p-3">6. CO-OPERATION AND TEAM WORK</p>
          <Table striped bordered hover>
            <thead>
              <tr>
                {/* <th>#</th> */}
                {workCategory === "Supervisory" ? (
                  <>
                    <th className="text-center" colSpan={2}>
                      Extremely co-operative. Seeks additional responsibility.
                      Performs extra work. Doesn’t make excuses.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Co-operates well. Good interest in job & for extra work.
                      Always reliable.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Generally co-operates. Takes extra work only when
                      requested. At times shows negative attitudes towards work.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Sometimes indifferent. Not always co-operative. Reluctant
                      to perform extra work. Shows low interest in the job.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Un co-operative Needs constant reminding. Always makes an
                      excuse. Never acts unless instructed.
                    </th>
                  </>
                ) : workCategory === "Clerical" ? (
                  <>
                    <th className="text-center" colSpan={2}>
                      Extremely co-operative. Seeks additional responsibility.
                      Performs extra work. Doesn’t make excuses. Very congenial
                      with colleagues.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Co-operates well. Good interest in job & for extra work.
                      Always reliable. Good relations with others.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Generally co-operates but sometimes indifferent. Takes
                      extra work only when requested. Has an average attitude
                      towards work.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Not always co-operative. Reluctant to perform extra work.
                      Low interest in job. Difficulty in dealing with others.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Un-corporative. Needs constant reminding. Always make
                      excuses. Never acts unless instructed. Poor relationship
                      with colleagues.
                    </th>
                  </>
                ) : workCategory === "Industrial" ? (
                  <>
                    <th className="text-center" colSpan={2}>
                      Extremely co-operative. Seeks additional responsibility.
                      Good relations with colleagues.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Good co-operation .Has a well balanced attitude towards
                      fellow workers.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Sometimes indifferent.Not always co-operative. Needs
                      constant instruction & supervision.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Generally confrontational and demonstrates
                      indiscipline.Difficult to deal with others.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Not co-operative at all. Poor relations with others.Very
                      poor discipline.
                    </th>
                  </>
                ) : (
                  // Handle other cases or render default elements if needed
                  <th
                    style={{ color: "darkgray" }}
                    colSpan={12}
                    className="text-center"
                  >
                    Please select an Employee
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* <td>Marks</td> */}
                {Array.from({ length: 10 }, (_, index) => (
                  <td
                    className={`text-center ${
                      cooperationTeamworkValue === 10 - index ? "selected" : ""
                    }`}
                    key={index}
                  >
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() =>
                        handleCooperationTeamworkButtonClick(10 - index)
                      }
                      disabled = {blockbyEvalStatus}
                    >
                      {10 - index}
                    </Button>
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
          {/* Display selected value for cooperation and teamwork */}
          <p className="p-3 text-primary text-right">
            Selected Value for Cooperation and Teamwork:{" "}
            {cooperationTeamworkValue !== null ? (
              <span>{cooperationTeamworkValue}</span>
            ) : (
              <span className="text-danger">No value selected.</span>
            )}
          </p>

          {/* 7. SITUATIONAL FLEXIBILITY */}
          <p className="p-3">7. SITUATIONAL FLEXIBILITY</p>
          <Table striped bordered hover>
            <thead>
              <tr>
                {/* <th>#</th> */}
                {workCategory === "Supervisory" ||
                workCategory === "Clerical" ||
                workCategory === "Industrial" ? (
                  <>
                    <th className="text-center" colSpan={2}>
                      Excellent ability to adapt with the organizational
                      changes. Always has a positive attitude towards new
                      changes & new technology. Encourages change for
                      organizational development.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Good adaptability. Displays well balanced attitudes
                      towards new changes.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Average interest on new changes & new technology. Always
                      prefers to follow routine practices. Sometimes resists
                      change.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Low interest on new changes & technology. Finds very
                      difficult to adjust according to new organization
                      developments.
                    </th>
                    <th className="text-center" colSpan={2}>
                      Negative attitude towards new changes & new technology.
                      Always criticize attempts for innovation. Very high
                      resistance to change.
                    </th>
                  </>
                ) : (
                  // Handle other cases or render default elements if needed
                  <th
                    style={{ color: "darkgray" }}
                    colSpan={12}
                    className="text-center"
                  >
                    please select an Employee
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* <td>Marks</td> */}
                {Array.from({ length: 10 }, (_, index) => (
                  // <td className="text-center" key={index}>
                  <td
                    className={`text-center ${
                      situationalFlexibilityValue === 10 - index
                        ? "selected"
                        : ""
                    }`}
                    key={index}
                  >
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() =>
                        handleSituationalFlexibilityButtonClick(10 - index)
                      }
                      disabled = {blockbyEvalStatus}
                    >
                      {10 - index}
                    </Button>
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
          {/* Display selected value for situational flexibility */}
          <p className="p-3 text-primary text-right">
            Selected Value for Situational Flexibility:{" "}
            {situationalFlexibilityValue !== null ? (
              <span>{situationalFlexibilityValue}</span>
            ) : (
              <span className="text-danger">No value selected.</span>
            )}
          </p>

          {/* Total marks */}
          <div className="p-3">
            <h5 className="text-primary text-right ">
              Subtotal (Out of 70): {totalMarks}
            </h5>
            {/* <h5 className="text-primary text-right ">
              Subtotal (Out of 70): {marksTotal}
            </h5> */}
            {/* <p className="text-right mb-0">{totalMarks}</p> */}
          </div>
        </Card>

        {/* dialog - evaluation marks details */}
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="md"
        >
          <DialogTitle color="primary">
            {"OVERALL PERFORMANCE RATING (E) - NON EXECUTIVE"}
          </DialogTitle>
          <DialogTitle align="right" variant="h6">
            {"Out of 100 marks"}
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  width: "100%",
                },
              }}
            >
              <Paper elevation={0} sx={{ borderRadius: 4 }}>
                <TableContainer>
                  <Table1>
                    <TableHead></TableHead>
                    <TableBody>
                      {performanceMarksDesc.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell
                            sx={{
                              textTransform: "uppercase",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            {row.range}
                          </TableCell>
                          <TableCell>{row.details}</TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            {row.rating}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table1>
                </TableContainer>
              </Paper>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>

      {/* form e */}
      <Frm_f
        totalMarks={totalMarks}
        selectedEvalStatus={selectedEvalStatus}
        onFormValuesChange={handleFormValues}
      />
    </>
  );
}

export default Frm_e;
