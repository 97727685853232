import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../auth/auth";
import "./css/styles.css";
import { CircularProgress } from "@mui/material";

function Login() {
  const navigate = useNavigate();
  const [user, setUser] = useState({ username: "", password: "" });
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const Swal = require("sweetalert2");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.get(
        `Login/UserLogin?userName=${encodeURIComponent(
          user.username
        )}&password=${encodeURIComponent(user.password)}`
      );

      if (response.data.StatusCode === 200) {
        const requestToken = response.data.Result;
        localStorage.setItem("request_token", requestToken);
        axios.defaults.headers.common["request_token"] = requestToken;

        const responseYear = await axios.get("Evaluation/LoadEvaYear?UserType=NonEx");

        if (responseYear.data.StatusCode === 200) {
          const apiYear = parseInt(responseYear.data.ResultSet[0]);
          localStorage.setItem("default_year", apiYear);
        } else {
          console.log("Year cannot be retrieved");
        }

        const responseLogUserName = await axios.get(
          `Login/GetUserDetails?userName=${encodeURIComponent(
            user.username
          )}&password=${encodeURIComponent(user.password)}`
        );

        if (responseLogUserName.data.StatusCode === 200) {
          const loggedUserName = responseLogUserName.data.ResultSet.ReportName;
          localStorage.setItem("logged_user_name", loggedUserName);

        } else {
          console.log("Year cannot be retrieved");
        }

        auth.login(user.username);
        // navigate(`/executive?sn=${encodeURIComponent(user.username)}`);
        navigate(`/executive`);
        // localStorage.setItem("loggedUser", user.username);
        setUser({ username: "", password: "" });
      } else if (response.data.StatusCode === 404) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Invalid Username or Password! Try again!",
        });
        setUser({ username: "", password: "" });
      } else {
        console.log("Unexpected status code:", response.data.StatusCode);
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="login">
        <img
          src={require("../../images/loginbackground.webp")}
          width={{}}
          alt="image"
          className="login__bg"
        />

        <div className="row">
          <div className="col-6"></div>
          <div className="col-6"></div>
        </div>

        <form action="" className="login__form">
          <img
            src={require("../../images/cdplc_logo.png")}
            alt="image"
            className="loginform_img text-center"
            style={{ width: "100%" }}
          />
          <h1 className="login__title pt-2 font-weight-bold">SIGN-IN</h1>
          <div className="login__inputs">
            <div className="login__box">
              <input
                type="text"
                placeholder="Username"
                required
                className="login__input"
                value={user.username}
                onChange={(e) => setUser({ ...user, username: e.target.value })}
              />
              <i className="ri-mail-fill"></i>
            </div>

            <div className="login__box">
              <input
                type="password"
                placeholder="Password"
                required
                className="login__input"
                value={user.password}
                onChange={(e) => setUser({ ...user, password: e.target.value })}
              />
              <i className="ri-lock-2-fill"></i>
            </div>
          </div>

          <button
            type="submit"
            className="login__button"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} color="info" /> : "Login"}
          </button>

          <div className="login__register pt-4 text-center">
            <p style={{ fontSize: "12px" }}>
              Powered by Dockyard Total Solutions. Copyright © 2023{" "}
            </p>
          </div>
        </form>
      </div>
    </>
  );
}

export default Login;
