import { Navigate } from "react-router";
import { useAuth } from "./auth";

export const RequireAuth = ({ children }) => {
  const auth = useAuth();

  const requestToken = localStorage.getItem("request_token");

  if ( !requestToken ) {
  // if ( !auth.user && !requestToken ) {
    return <Navigate to="/" />;
  }

  return children;
};


// import React from "react";
// import { Navigate } from "react-router-dom";
// import { useAuth } from "./auth";

// export const RequireAuth = ({ children }) => {
//   const { user } = useAuth();

//   if (user) {
//     return <Navigate to="/" />;
//   }

//   return children;
// };