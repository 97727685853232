import React, { useEffect, useState } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";

function RecForGrade_frm({
  loggedUserSN,
  onDataUpdate,
  selectedPeriod,
  serviceNo,
  selectedYear,
  currentEvalStatus,
}) {
  // const serviceNumber = "0002014 - P.H.S. Daminda";
  const [isEngEx, setIsEngEx] = useState(false);
  const [isDepartmentalHead, setIsDepartmentalHead] = useState(false);
  const [isDivisionHead, setIsDivisionHead] = useState(false);
  const [isEngineer, setIsEngineer] = useState(false);

  const [prevFormValues, setPrevFormValues] = useState();

  const [formValues, setFormValues] = useState({
    engex: "",
    deph: "",
    divh: "",
    evalBy: "",
    checkedBy: "",
    approvedBy: "",
    discWithEmp: "",
    commentsEngex: "",
    specialCommentsDepHead: "",
    recommendationsDivHead: "",
    // firstName: loggedUserSN,
  });

  const blockbyEvalStatus = currentEvalStatus === "Authorized";

  useEffect(() => {
    axios
      .get(
        `Evaluation/GetRecommendationDetails?serviceNo=${serviceNo}&year=${selectedYear}&UserType=NonEx&periodType=${selectedPeriod}`
        // `Evaluation/GetRecommendationDetails?serviceNo=0002410&year=2020&UserType=NonEx&periodType=2`
      )
      .then((response) => {
        const data = response.data.ResultSet;

        setFormValues({
          engex: data.DphpromRecommened === "Y" ? "Y" : "",
          deph: data.SpecialCommentsBy,
          divh: data.DihpromotionRecommened === "Y" ? "Y" : "",
          evalBy: data.Status === "E2" || data.Status === "E1"
          ? data.StatusUpdatedBy
          : "",
          checkedBy: data.Status === "E3" ? data.StatusUpdatedBy : "",
          approvedBy: data.Status === "E4" ? data.StatusUpdatedBy : "",
          discWithEmp: data.ComunicationStatus === "Y" ? "Y" : "N",
          commentsEngex: data.Remarks,
          specialCommentsDepHead: data.SpecialComments,
          recommendationsDivHead: data.Recommendations,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [serviceNo, selectedYear, selectedPeriod]);

  useEffect(() => {
    if (JSON.stringify(formValues) !== JSON.stringify(prevFormValues)) {
      onDataUpdate(formValues);
      setPrevFormValues(formValues);
    }
  }, [formValues, onDataUpdate, prevFormValues]);

  // useEffect(() => {
  //   if (serviceNo && selectedYear) {
  //     setFormValues({
  //       engex: "",
  //       deph: "",
  //       divh: "",
  //       evalBy: "",
  //       checkedBy: "",
  //       approvedBy: "",
  //       discWithEmp: "",
  //       commentsEngex: "",
  //       specialCommentsDepHead: "",
  //       recommendationsDivHead: "",
  //     });
  //   }
  // }, [serviceNo, selectedYear]);

  const handleFormChange = (event) => {
    const { name, checked, value, type } = event.target;

    if (name === "engex") {
      setFormValues((prevValues) => ({
        ...prevValues,
        engex: checked ? "Y" : "",
      }));
      setIsEngineer(checked);
    } else if (name === "deph") {
      setFormValues((prevValues) => ({
        ...prevValues,
        deph: checked ? "Y" : "",
      }));
      setIsDepartmentalHead(checked);
    } else if (name === "divh") {
      setFormValues((prevValues) => ({
        ...prevValues,
        divh: checked ? "Y" : "",
      }));
      setIsDivisionHead(checked);
    } else if (name === "group1") {
      setFormValues((prevValues) => ({ ...prevValues, discWithEmp: value }));
    } else {
      setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    }
    // onDataUpdate(formValues);

    onDataUpdate({
      ...formValues,
      [name]: value,
    });

    // setFormValues((prevValues) => {
    //   const updatedValues = { ...prevValues, [name]: value };
    //   onDataUpdate(updatedValues);
    //   return updatedValues;
    // });
  };

  return (
    <Container fluid>
      <Card className="text-black mt-4 mb-4" style={{ background: "#d9edf7" }}>
        <Card.Header>
          RECOMMENDATION FOR GRADE PROMOTION [Please tick if recommended]
        </Card.Header>
        <Card body>
          <Row className="mb-3">
            <Col md="3">
              <Form.Check
                className="mb-2"
                type="checkbox"
                label="Engineer / Executive In-charge"
                name="engex"
                checked={formValues.engex === "Y"}
                onChange={handleFormChange}
                disabled={blockbyEvalStatus}
              />

              <Form.Control
                type="text"
                name="engex"
                value={isEngineer ? loggedUserSN : ""}
                disabled={isEngineer || blockbyEvalStatus}
              />
            </Col>
            <Col md="3">
              {/* <Form.Label>Departmental Head</Form.Label> */}
              {/* <Form.Check
                className="mb-2"
                type="checkbox"
                label="Departmental Head"
                onChange={() => setIsDepartmentalHead(!isDepartmentalHead)}
              /> */}
              <Form.Check
                className="mb-2"
                type="checkbox"
                label="Departmental Head"
                name="deph"
                checked={formValues.deph === "Y"}
                onChange={handleFormChange}
                disabled={blockbyEvalStatus}
              />
              <Form.Control
                type="text"
                name="deph"
                // value={isDepartmentalHead ? loggedUserSN : ""}
                // disabled={isDepartmentalHead}
                value={isDepartmentalHead ? loggedUserSN : ""}
                disabled={isDepartmentalHead || blockbyEvalStatus}
              />
            </Col>
            <Col md="3">
              {/* <Form.Label>Division Head</Form.Label>
              <Form.Control type="text" name="divh" /> */}

              {/* <Form.Check
                className="mb-2"
                type="checkbox"
                label="Division Head"
                onChange={() => setIsDivisionHead(!isDivisionHead)}
              /> */}
              <Form.Check
                className="mb-2"
                type="checkbox"
                label="Division Head"
                name="divh"
                checked={formValues.divh === "Y"}
                onChange={handleFormChange}
                disabled={blockbyEvalStatus}
              />
              <Form.Control
                type="text"
                name="divh"
                // value={isDivisionHead ? loggedUserSN : ""}
                // disabled={isDivisionHead}
                value={isDivisionHead ? loggedUserSN : ""}
                disabled={isDivisionHead || blockbyEvalStatus}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md="3">
              <Form.Label className="mr-2">Evaluated by</Form.Label>
              <Form.Control
                type="text"
                name="evalBy"
                // value={loggedUserSN}
                value={formValues.evalBy}
                onChange={handleFormChange}
                disabled
              />
            </Col>
            <Col md="3">
              <Form.Label>Checked by</Form.Label>
              <Form.Control
                type="text"
                name="checkedBy"
                value={formValues.checkedBy}
                onChange={handleFormChange}
                disabled
              />
            </Col>
            <Col md="3">
              <Form.Label>Approved by</Form.Label>
              <Form.Control type="text" name="approvedBy"
              value={formValues.approvedBy} disabled />
            </Col>
          </Row>
          <br />
          <Row className="mb-3 mt-3">
            <Col md="4">
              <Form.Label>
                <span
                  className="text-danger font-weight-bold"
                  style={{ fontSize: 20 }}
                >
                  *{" "}
                </span>
                Evaluation discussed with the Employee:{" "}
              </Form.Label>
            </Col>
            <Col md="2" className="mt-2">
              <Form.Check
                inline
                label="Yes"
                name="group1"
                type="radio"
                value="Y"
                checked={formValues.discWithEmp === "Y"}
                // onChange={handleFormChange}
                onChange={(e) => {
                  handleFormChange(e);
                }}
                disabled={blockbyEvalStatus}
              />
              <Form.Check
                inline
                label="No"
                name="group1"
                type="radio"
                value="N"
                checked={formValues.discWithEmp === "N"}
                // onChange={handleFormChange}
                onChange={(e) => {
                  handleFormChange(e);
                }}
                disabled={blockbyEvalStatus}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group
                className="mb-1 "
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className="mr-2">
                  Comments by Engineer / Executive in-charge
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  style={{ width: "100%" }}
                  name="commentsEngex"
                  value={formValues.commentsEngex}
                  onChange={handleFormChange}
                  disabled={blockbyEvalStatus}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="mb-1 "
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className="mr-2">
                  Special Comments by Departmental Head
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  style={{ width: "100%" }}
                  name="specialCommentsDepHead"
                  value={formValues.specialCommentsDepHead}
                  onChange={handleFormChange}
                  disabled={blockbyEvalStatus}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group
                className="mb-1"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className="mr-2">
                  Recommendations by Divisional Head
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  style={{ width: "100%" }}
                  name="recommendationsDivHead"
                  value={formValues.recommendationsDivHead}
                  onChange={handleFormChange}
                  disabled={blockbyEvalStatus}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card>
      </Card>
    </Container>
  );
}

export default RecForGrade_frm;
