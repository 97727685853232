import React, { useEffect, useState } from "react";
import { Card, Container, Spinner } from "react-bootstrap";
import axios from "axios";

function Frm_a({ serviceNo }) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   if (serviceNo === null || serviceNo === undefined) {
  //     setIsLoading(false);
  //     return;
  //   }
  //   axios
  //     .get(
  //       `Evaluation/GetPersonalData?serviceNo=${serviceNo}`
  //     )
  //     .then((response) => {
  //       setData(response?.data?.ResultSet);
  //       setIsLoading(false);

  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setIsLoading(false);

  //     });
  // }, [serviceNo]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `Evaluation/GetPersonalData?UserType=Ex&serviceNo=${serviceNo}`
      );

      if (response.status === 200) {
        const responseData = response.data.ResultSet;
        if (responseData !== null) {
          setData(responseData);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        console.error(`Request failed with status code ${response.status}`);
        setIsLoading(false);
        setTimeout(() => fetchData(), 1000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
      setTimeout(() => fetchData(), 1000); // Retry after 1 second (adjust as needed)
    }
  };

  useEffect(() => {
    if (serviceNo) {
      fetchData();
    }
  }, [serviceNo]);

  return (
    <>
      <Container className="p-0 m-0" >
        <Card className=" p-0 bg-white text-dark text-start" style={{ borderRadius:18 }}>
          <Card.Header>(A). PERSONAL DATA</Card.Header>
          <Card.Body>
            {isLoading ? (
              <p style={{ color: "darkgray" }}>Please select an employee.</p>
            ) : data ? (
              <>
                <p>1. Name : {data?.ReportName}</p>
                <p>2. Designation : {data?.Category}</p>
                <p>3. Division : {data?.DivisionCode}</p>
                <p>4. Department : {data?.DepartmentCode}</p>
                <p>5. Recruitment Date : {data?.RecruitmentDate}</p>
                <p>6. Basic Salary : </p>
                <p>7. Permanent Date : {data?.PermanentDate}</p>
                <p>8. Present Grade : {data?.PresentGrade}</p>
                <p>9. Retirement Date : {data?.RetirementDate}</p>
                <p>10. Basis Of Employment : {data?.FirstEmployer}</p>
              </>
            ) : (
              <Spinner animation="border" size={3} role="status"></Spinner>
            )}
          </Card.Body>
        </Card>
        {/* <Frm_e workCategory={workCategory} style={{display: 'none'}} /> */}
      </Container>
    </>
  );
}

export default Frm_a;
