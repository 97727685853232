import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Dropdown } from "react-bootstrap";

const EvalHistModal = ({
  showModal,
  toggleModal,
  serviceNo,
  selectedYear,
  selectedPeriod,
}) => {
  const [evalData, setEvalData] = useState(null);
  const [selectedHistoryStatus, setSelectedHistoryStatus] = useState(null);

  // const [specialFormHistoryValues, setSpecialFormHistoryValues] = useState({
  //   specAddSalary: "",
  //   numSpecIncre: "",
  //   justific: "",
  //   gcw: false,
  //   costSav: false,
  //   is: false,
  //   innov: false,
  //   oc: false,
  //   uf: false,
  //   cusSatis: false,
  //   other: false,
  //   recBy: "",
  // });

  // console.log(specialFormHistoryValues);

  // const handleSpecialFormHistoryChange = (event) => {
  //   const { name, checked, value } = event.target;

  //   if (name === "engex") {
  //     setSpecialFormHistoryValues((prevValues) => ({
  //       ...prevValues,
  //       engex: checked,
  //     }));
  //   } else if (name === "deph") {
  //     setSpecialFormHistoryValues((prevValues) => ({
  //       ...prevValues,
  //       deph: checked,
  //     }));
  //   } else if (name === "divh") {
  //     setSpecialFormHistoryValues((prevValues) => ({
  //       ...prevValues,
  //       divh: checked,
  //     }));
  //   } else if (name === "group1") {
  //     setSpecialFormHistoryValues((prevValues) => ({
  //       ...prevValues,
  //       discWithEmp: value,
  //     }));
  //   } else {
  //     setSpecialFormHistoryValues((prevValues) => ({
  //       ...prevValues,
  //       [name]: value,
  //     }));
  //   }
  // };
  // axios
  //   .get(
  //     `https://esystems.cdl.lk/backend-Test/PerformanceEvaluation/Evaluation/GetSpecialEvaluationDetails?serviceNo=${serviceNo}&year=${selectedYear}&periodType=${selectedPeriod}&historyStatus=${selectedHistoryStatus}`
  //   )
  //   .then((response) => {
  //     setEvalData(response.data.ResultSet);
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //   });

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `Evaluation/GetSpecialEvaluationDetails?UserType=NonEx&serviceNo=${serviceNo}&year=${selectedYear}&periodType=${selectedPeriod}&historyStatus=${selectedHistoryStatus}`
      );

      if (response.status === 200) {
        const responseData = response.data.ResultSet;
        if (responseData !== null) {
          setEvalData(responseData);
        }
      } else {
        console.error(`Request failed with status code ${response.status}`);
        setTimeout(() => fetchData(), 1000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setTimeout(() => fetchData(), 1000); // Retry after 1 second (adjust as needed)
    }
  };

  useEffect(() => {
    if (
      serviceNo !== null &&
      selectedYear !== null &&
      selectedHistoryStatus !== null
    ) {
      fetchData();
    }
  }, [serviceNo, selectedYear, selectedHistoryStatus]);

  // Function to fetch evaluation data
  // const fetchEvaluationData = async () => {
  //   if (
  //     serviceNo !== null &&
  //     selectedYear !== null &&
  //     selectedHistoryStatus !== null
  //   ) {
  //     try {
  //       const response = await axios.get(
  //         `https://esystems.cdl.lk/backend/PerformanceEvaluation/Evaluation/GetSpecialEvaluationDetails?serviceNo=${serviceNo}&year=${selectedYear}&periodType=${selectedPeriod}&historyStatus=${selectedHistoryStatus}`
  //       );
  //       setEvalData(response.data.ResultSet);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // };

  // // Call fetchEvaluationData when all three variables are not null
  // if (
  //   serviceNo !== null &&
  //   selectedYear !== null &&
  //   selectedHistoryStatus !== null
  // ) {
  //   fetchEvaluationData();
  // }

  const handleHistoryStatusClick = (status) => {
    if (serviceNo === null && selectedYear === null) {
      alert("Please select an employee first");
    } else {
      setSelectedHistoryStatus(status);
    }
  };

  return (
    <Modal show={showModal} onHide={toggleModal} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Evaluation History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          {/* <Card.Header className="text-center text-white h5 bg-black bg-primary">
          SPECIAL EVALUATION
        </Card.Header> */}
          <Card.Body aria-disabled="true">
            <Row>
              <Col xs={12}>
                <Card.Text style={{ fontWeight: "bold" }}>
                  Please select the Evaluation History:
                  <Dropdown
                    style={{ display: "inline-block", marginLeft: "10px" }}
                  >
                    <Dropdown.Toggle
                      variant="primary"
                      id="dropdown-basic"
                      size="sm"
                    >
                      {selectedHistoryStatus === null
                        ? "Please select the Evaluation History"
                        : selectedHistoryStatus === 0
                        ? "Engineer/Reporting officer"
                        : selectedHistoryStatus === 1
                        ? "Department head"
                        : "Division head"}
                      {/* Please select the Evaluation History */}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ minWidth: "auto" }}>
                      <Dropdown.Item
                        onClick={() => handleHistoryStatusClick(0)}
                      >
                        Engineer/Reporting officer
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleHistoryStatusClick(1)}
                      >
                        Department head
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleHistoryStatusClick(2)}
                      >
                        Division head
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Card.Text>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={6} md={9}>
                <Card.Text>
                  Do you recommend the above employee to be granted with
                  "Special Additional" salary increments with regard to any
                  exceptional performance / characteristic demonstrated by
                  him/her?
                </Card.Text>
              </Col>
              <Col xs={6} md={3}>
                <Form style={{ marginLeft: "40%" }}>
                  {["radio"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="Yes"
                        name="group1"
                        type={type}
                        id={`inline-${type}-Yes`}
                        defaultChecked={evalData?.Sa_si_y_check}
                        disabled
                      />
                      <Form.Check
                        inline
                        label="No"
                        name="group1"
                        type={type}
                        id={`inline-${type}-No`}
                        defaultChecked={evalData?.Sa_si_n_check}
                        disabled
                      />
                    </div>
                  ))}
                </Form>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col xs={6} md={9}>
                <Card.Text>
                  If yes, Number of special additional increments recommended
                  (Please tick)
                </Card.Text>
              </Col>
              <Col xs={6} md={3}>
                <Form style={{ marginLeft: "40%" }}>
                  {/* {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3"> */}
                  <Form.Check
                    inline
                    label=" 1"
                    name="group1"
                    type="radio"
                    id={`radio3`}
                    defaultChecked={evalData?.Sa_ni_1_check}
                    disabled
                  />

                  <Form.Check
                    inline
                    label="2"
                    name="group1"
                    type="radio"
                    id={`radio4`}
                    defaultChecked={evalData?.Sa_ni_2_check}
                    disabled
                  />
                  {/* </div>
                ))} */}
                </Form>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Card.Title>Justification</Card.Title>
                <Card.Text>(Please justify your recommendation)</Card.Text>
                <Form.Group
                  className="mb-3 "
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    value={evalData?.Achievements}
                    rows={3}
                    // onChange={handleSpecialFormHistoryChange}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>

            <Card className="p-3">
              <Card.Title>You justification mainly falls under</Card.Title>
              <Card.Body>
                <Row>
                  <Col>
                    <Form>
                      {/* {["checkbox"].map((type) => (
                      <div key={`default-${type}`} className="mb-3"> */}
                      <Form.Check
                        type="checkbox"
                        // id={`default-${type}`}
                        id="check1"
                        label="Great Commitment work"
                        className="mb-3"
                        defaultChecked={evalData?.Yjmfu_gctw_check}
                        disabled
                      />
                      <Form.Check
                        type="checkbox"
                        // id={`default-$"checkbox"`}
                        id="check2"
                        label="Cost saving"
                        className="mb-3"
                        defaultChecked={evalData?.Yjmfu_cs_check}
                        disabled
                      />
                      <Form.Check
                        type="checkbox"
                        // id={`default-$"checkbox"`}
                        id="check3"
                        label="Important Suggestion"
                        className="mb-3"
                        defaultChecked={evalData?.Yjmfu_is_check}
                        disabled
                      />
                      <Form.Check
                        type="checkbox"
                        // id={`default-$"checkbox"`}
                        id="check4"
                        label="Innovation"
                        defaultChecked={evalData?.Yjmfu_i_check}
                        disabled
                      />
                      {/* </div> */}
                      {/* ))} */}
                    </Form>
                  </Col>
                  <Col>
                    <Form>
                      {/* {["checkbox"].map((type) => (
                      <div key={`default-$"checkbox"`} className="mb-3"> */}
                      <Form.Check
                        type="checkbox"
                        id="check5"
                        label="Outstanding contribution"
                        className="mb-3"
                        defaultChecked={evalData?.Yjmfu_oc_check}
                        disabled
                      />
                      <Form.Check
                        type="checkbox"
                        id="check6"
                        label="Unique feat"
                        className="mb-3"
                        defaultChecked={evalData?.Yjmfu_uf_check}
                        disabled
                      />
                      <Form.Check
                        type="checkbox"
                        id="check7"
                        label="Customer satisfaction"
                        className="mb-3"
                        defaultChecked={evalData?.Yjmfu_csn_check}
                        disabled
                      />
                      <Form.Check
                        type="checkbox"
                        id="check8"
                        label="Other(Please specify)"
                        defaultChecked={evalData?.Yjmfu_o_check}
                        disabled
                      />
                      {/* </div> */}
                      {/* ))} */}
                    </Form>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea2"
                    >
                      <Form.Control
                        as="textarea"
                        rows={5}
                        value={evalData?.AchievementsRemarks}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* recomendation */}
            <Card className="p-2 mt-3">
              <Form.Group as={Row} style={{ marginTop: "2%" }}>
                <Form.Label column sm={2}>
                  <strong> Recommended By : </strong>
                </Form.Label>
                <Col sm={3}>
                  <Form.Control
                    type="text"
                    name="Name"
                    value={evalData?.RecommenedBy}
                    disabled
                  />
                </Col>
              </Form.Group>

              {/* Note */}

              <Row style={{ marginTop: "2%", marginLeft: "10%" }}>
                <Col>
                  <Form>
                    <Form.Label className="me-2">
                      <strong>Recommendation of Departmental Head</strong>
                    </Form.Label>
                  </Form>
                </Col>
                <Col>
                  <Form>
                    {/* {["radio"].map((type) => (
                    <div key={`default-${type}`}> */}
                    <Form.Check
                      type="radio"
                      // id="rdh_sir_check1"
                      name="rdh_sir_check"
                      label="Special Increments Recommended"
                      className="mb-3"
                      defaultChecked={evalData?.Rdh_sir_check}
                      disabled
                    />
                    <Form.Check
                      type="radio"
                      // id="rdh_sir_check2"
                      name="rdh_sir_check"
                      label="Special Incrementsm Not Recommended"
                      defaultChecked={evalData?.Rdh_sinr_check}
                      disabled
                    />
                    {/* </div>
                  ))} */}
                  </Form>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3 "
                    controlId="exampleForm.ControlTextarea3"
                  >
                    <Form.Control
                      as="textarea"
                      rows={2}
                      value={evalData?.RecommenedName}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* other one */}
              <Row style={{ marginTop: "2%", marginLeft: "10%" }}>
                <Col>
                  <Form>
                    <Form.Label className="me-2">
                      <strong>Approval of Division Head</strong>
                    </Form.Label>
                  </Form>
                </Col>
                <Col>
                  <Form>
                    {/* {["radio"].map((type) => (
                    <div key={`default-${type}`}> */}
                    <Form.Check
                      type="radio"
                      name="adh_sia_check"
                      label="Special Increments Approved"
                      className="mb-3"
                      value="yes"
                      defaultChecked={evalData?.Adh_sia_check}
                      disabled
                    />
                    <Form.Check
                      type="radio"
                      name="adh_sia_check"
                      value="no"
                      label="Special Incrementsm Not Approved"
                      defaultChecked={evalData?.Adh_sina_check}
                      disabled
                    />
                    {/* </div>
                  ))} */}
                  </Form>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea4"
                  >
                    <Form.Control
                      as="textarea"
                      rows={2}
                      value={evalData?.RecommenedName}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggleModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EvalHistModal;
